import React from "react";

const Grid = React.memo(function(props) {
  const { gridShow, gridSize, gridOpacity } = props;

  const opacityStyle = {
    strokeOpacity: gridOpacity / 100
  };
  let grid = [];

  for (let i = 1; i < props.w / gridSize; i++) {
    grid.push(
      <line
        key={`vert_${i}`}
        className={i % gridSize === gridSize / 2 ? "major" : ""}
        style={opacityStyle}
        x1={i * gridSize}
        y1={0}
        x2={i * gridSize}
        y2={props.h}
      />
    );
  }

  for (let i = 1; i < props.h / gridSize; i++) {
    grid.push(
      <line
        key={`horz_${i}`}
        className={i % gridSize === gridSize / 2 ? "major" : ""}
        style={opacityStyle}
        x1={0}
        y1={i * gridSize}
        x2={props.w}
        y2={i * gridSize}
      />
    );
  }

  return <g className={"grid" + (!gridShow ? "  is-hidden" : "")}>{grid}</g>;
});

export default Grid;
