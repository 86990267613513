import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import Tour from "reactour";
import Button from "../components/Button";
import BeforeAfterSlider from "../components/BeforeAfterSlider";
import ShareControls from "../components/ShareControls";
import HelpButton from "../components/HelpButton";
import { disableBody, enableBody, debounce } from "../utils";
import { saveAs } from 'file-saver';
import axios from 'axios';
import "../styles/products.scss";

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function(target, replacement) {
    return this.split(target).join(replacement);
};

const Share = ({
	image,
	currentShapes,
	w,
	h,
	updateTexture,
	updateTextureColor,
	saveProjects,
	currentProject,
	projectName,
	setProjectName,
	userToken,
	groups,
	currentGroupIndex,
	defaultTexture,
	shareHash,
	productId,
	colorId,
	rasterizeProject,
	projectImage,
	goToURI,
    setPreview,
    setLoading,
    setProgress,
    isLoading,
    isSaving,
	...props
}) => {
	const ref = useRef();

    const [dims, setDims] = useState(false);
    const [downloadError, setDownloadError] = useState(false);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
	useLayoutEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			if (ref.current) {
				let box = ref.current.getBoundingClientRect();
				if (!dims || (box.height !== dims.height || box.width !== dims.width)) {
					setDims(box);
				}
			}
		}, 300);
		if (ref.current) {
			let box = ref.current.getBoundingClientRect();
			if (!dims || (box.height !== dims.height || box.width !== dims.width)) {
				setDims(box);
			}
		}
		window.addEventListener("resize", debouncedHandleResize);
		return () => window.removeEventListener("resize", debouncedHandleResize);
		
    });

	useEffect(() => {
		if (!projectImage) {
			goToURI("/draw");
		}
    }, [projectImage, goToURI]);

    let scale = 1;
    let scaleX = 1;
    let scaleY = 1;

    if (dims) {
        scaleX = dims.width / w;
        scaleY = dims.height / h;
        scale = Math.min(scaleX, scaleY);
    }

    const getImages = () => {
        const start = "url(&quot;data:image/jpeg;base64,";
        const end = "&quot;)";
        const result = [];
        const r = projectImage.split(start);
        for (let i = 1; i < r.length; i++) {
            const match = r[i].split(end)[0];
            if (!result.includes(match)) {
                result.push(r[i].split(end)[0]);
            }
        }
        return result;
    }

    const getSvgs = () => {
        const start = "url(&quot;data:image/svg+xml;base64,";
        const end = "&quot;)";
        const result = [];
        const r = projectImage.split(start);
        for (let i = 1; i < r.length; i++) {
            const match = r[i].split(end)[0];
            if (!result.includes(match)) {
                result.push(r[i].split(end)[0]);
            }
        }
        return result;
    }
    
	const handleDownload = async (callback) => {
		if (projectImage) {
            setLoading(true);
            const images = getImages();
            const svgs = getSvgs();
            let svg = projectImage.toString('utf8');
            images.forEach((image,i) => {
                const placeholder = `[base64-jpg-${i}]`;
                svg = svg.replaceAll(image,placeholder);
            })
            svgs.forEach((image,i) => {
                const placeholder = `[base64-svg-${i}]`;
                svg = svg.replaceAll(image,placeholder);
            })
            const handleDownloadProgress = (e) => {
                const complete = e.loaded / e.total * 100;
                setProgress("Processing Project..." + Math.ceil(complete/5)*5 + "%")
            }
            const handleUploadProgress = (e) => {
                const complete = e.loaded / e.total * 100;
                setProgress("Generating Project..." + Math.ceil(complete/5)*5 + "%")
            }

            const config = {
                responseType: 'blob',
                headers: { 'Content-type': 'application/json' },
                onDownloadProgress: handleDownloadProgress,
                onUploadProgress: handleUploadProgress
            };

            const data = {
                svg,
                images,
                svgs
            };
    
            axios.post('https://hmc-convert.theyinteractive.com/svg2png', data, config)
            .then(response => {
                setDownloadError(false);
                setLoading(false);
                setProgress(0);
                const downloadURL = URL.createObjectURL(response.data);
                saveAs(downloadURL, 'my-project.png');
            })
            .catch(ex => {
                setLoading(false);
                setProgress(0);
                setDownloadError(true);
                console.error(ex);
            });
        }
	};

	const shareHelp = [
		{
			selector: ".after",
			content: () => (
				<div>
					<h5>Tutorial / Share</h5>
					<h3>Before & After</h3>
					<hr />
					<p>This is your completed project. On the right side is your before image.</p>
				</div>
			),
			position: "top",
			action: node => {
				node.focus();
            },
            
            

			stepInteraction: false
		},
		{
			selector: ".scroller",
			content: () => (
				<div>
					<h5>Tutorial / Share</h5>
					<h3>Before Image</h3>
					<hr />
					<p>Sliding this handle allows you to quickly compare your project to your original before image.</p>
				</div>
			),
			position: "top",
			action: node => {
				node.focus();
			},

			stepInteraction: false
		},
		{
			selector: ".product-details",
			content: () => (
				<div>
					<h5>Tutorial / Share</h5>
					<h3>Product Selection</h3>
					<hr />
					<p>This is a summary of the products you've selected for each of your shape groups.</p>
				</div>
			),
			position: "top",
			action: node => {
				node.focus();
			},

			stepInteraction: false
		},
		{
			selector: ".download",
			content: () => (
				<div>
					<h5>Tutorial / Share</h5>
					<h3>Download</h3>
					<hr />
					<p>
						Click <b>Download</b> to download an image of your completed project.
					</p>
				</div>
			),
			position: "top",
			action: node => {
				node.focus();
			},

			stepInteraction: false
		},
		{
			selector: ".cta",
			content: c => (
				<div>
					<h5>Tutorial / Share</h5>
					<h3>Before & After</h3>
					<hr />
					<p>
						To save, create multiple projects and/or share your projects click <b>Sign In / Sign Up</b>.
					</p>
					<div className="center">
						<Button className="green small" label="View Project" onClick={c.close} />
					</div>
				</div>
			),
			position: "top",
			action: node => {
				node.focus();
			},

			stepInteraction: false
		}
	];
	return (
<>
			<section id="draw">
				<div className="artboard-wrapper" ref={ref}>
					<div
						className="artboard"
						style={{
                            transform: `translateX(-50%) translateY(-50%) scale(${scale})`
						}}
					>
						<div id="shape-wrapper">
							{dims && (
								<BeforeAfterSlider before={image} after={projectImage} width={w} height={h} beforeImageLabel="Before" afterImageLabel="After" scale={scale} />
							)}
						</div>
					</div>
				</div>
				<div className="sidebar">
					<ShareControls
						w={w}
						h={h}
						image={image}
						shape={currentShapes}
						updateTextureColor={updateTextureColor}
						updateTexture={updateTexture}
						handleDownload={handleDownload}
						saveProjects={saveProjects}
						artboardRef={ref}
						currentProject={currentProject}
						projectName={projectName}
						setProjectName={setProjectName}
						loggedIn={userToken && userToken.username}
						groups={groups}
						currentGroupIndex={currentGroupIndex}
						defaultTexture={defaultTexture}
						shareHash={shareHash}
						rasterizeProject={rasterizeProject}
						goToURI={goToURI}
						setPreview={setPreview}
                        isLoading={isLoading}
                        downloadError={downloadError}
                        setDownloadError={setDownloadError}
                        isSaving={isSaving}
					/>
				</div>
				<Tour
					key="scale"
					closeWithMask={true}
					steps={shareHelp}
					disableInteraction={true}
					isOpen={props.isHelpOpen}
					onRequestClose={props.toggleHelp}
					onAfterOpen={disableBody}
					onBeforeClose={enableBody}
					badgeContent={(curr, tot) => `${curr} of ${tot}`}
					accentColor="#0a4436"
					highlightedMaskClassName="tour-active"
					showNavigation={false}
					showNavigationNumber={false}
				/>
				<HelpButton helpOnly={true} toggleHelp={props.toggleHelp} setIsHelpOpen={props.setIsHelpOpen} defaultHelpState={props.defaultHelpState} setDefaultHelpState={props.setDefaultHelpState} />
			</section>
       </>
	);
};

export default Share;
