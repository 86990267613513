import React, { useState } from "react";
import Input from "./Input";
import Dropdown from "react-dropdown";
import useDeepCompareEffect from "use-deep-compare-effect";
//import Triangle from "../icons/Triangle";
//import Polygon from "../icons/Polygon";
//import CurveDemoShape from "../icons/CurveDemoShape";

const ChooseGroup = props => {
	const [disabled, setDisabled] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState(props.groups[props.currentGroupIndex]);
	const [newGroupName, setNewGroupName] = useState("");
	const [addNewGroup, setAddNewGroup] = useState(false);

	useDeepCompareEffect(() => {
		if (props) {
			props.onChange({
				selectedGroup,
				newGroupName
			});
			setDisabled(newGroupName !== "");
		}
	}, [newGroupName, props, selectedGroup]);

	let activeGroups = [];
	props.groups.forEach(g => {
		if (g !== null) {
			activeGroups.push(g);
		}
	});
	return (
		<>
			<p>Choose an exisiting group...</p>
			<Dropdown
				defaultValue={props.defaultOption}
				options={activeGroups}
				value={selectedGroup}
				onChange={value => setSelectedGroup(value)}
				disabled={disabled}
				placeholder="Select a Group"
			/>
			<h3
				className="center"
				style={{ margin: "1em", cursor: "pointer" }}
				onClick={() => {
					setAddNewGroup(true);
				}}
			>
				<i className="fa fa-plus" /> New Group
			</h3>

			{addNewGroup && (
				<Input
					label="New Group"
					onChange={e => {
						setNewGroupName(e.target.value);
					}}
				/>
			)}
		</>
	);
};

export default ChooseGroup;
