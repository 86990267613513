import React from "react";
import { generateStaticPath } from "../utils";

const CutMask = ({ cutShapes, currentCutShapeIndex, zoom }) => {
	let width = Math.round(zoom.scaleX > 1 ? zoom.scaleX * 100 : (1 - zoom.scaleX + 1.05) * 100);
	let height = Math.round(zoom.scaleY > 1 ? zoom.scaleY * 100 : (1 - zoom.scaleY + 1.05) * 100);
	return (
		<mask id="mask">
			<rect x="0" y="0" width={width + "%"} height={height + "%"} fill="white" />
			{cutShapes.map(({ points }, i) => {
				if (i === currentCutShapeIndex) return null;
				return <path d={generateStaticPath(points)} key={`mask_${i}`} />;
			})}
		</mask>
	);
};

export default CutMask;
