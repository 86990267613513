import React, { useState } from "react";
import "../styles/controls.scss";
import Button from "./Button";
import Perspective from "../icons/Perspective";
import ChooseGroup from "./ChooseGroup";
import Popup from "react-popup";
import GroupList from "./GroupList";
import SweetAlert from "sweetalert-react";
import Fade from "react-reveal/Fade";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Tour from "reactour";
import { disableBody, enableBody } from "../utils";

import "react-dropdown/style.css";

import "../styles/popup.css";

const Controls = ({
	groups,
	currentGroupIndex,
	addShape,
	resetShape,
	currentShapes,
	currentShapeIndex,
	activePoint,
	addNewCutShape,
	cutMode,
	currentCutShapeIndex,
	shapeCount,
	setChangingPerspective,
	setMasking,
	isFirstPoint,
	goToURI,
	hasMaskShape,
	setCutMode,
	...props
}) => {
	const [missingTransfromError, setMissingTransfromError] = useState(false);
	const [emptyGroupError, setEmptyGroupError] = useState(false);
	const [emptyGroupNotice, setEmptyGroupNotice] = useState(false);
	const [emptyGroupIds, setEmptyGroupIds] = useState([]);
	const [perspectiveTips, setPerspectiveTips] = useState(false);

	const resetErrors = () => {
		setEmptyGroupError(false);
		setEmptyGroupNotice(false);
		setMissingTransfromError(false);
		setPerspectiveTips(false);
	};

	const arrayToSentence = arr => {
		const len = arr.length;
		return arr.reduce(function(a, b, c) {
			return a + (c - 1 === len ? ", " : " or ") + b;
		});
	};

	let shapeSelected = true;
	let pointSelected = false;
	let missingTransfrom = false;
	let missingTransfromShape = false;

	currentShapes.forEach(s => {
		if (s !== null && s.hasPersective === false) {
			missingTransfromShape = s;
			missingTransfrom = true;
		}
	});

	if (!currentShapes[currentShapeIndex] || currentShapes[currentShapeIndex].points.length === 0) {
		shapeSelected = false;
	}
	let isQuadPoint;
	let pointCount = 0;
	if (shapeSelected && currentShapes[currentShapeIndex].points[activePoint]) {
		pointSelected = true;
	}
	if (!isFirstPoint && shapeSelected) {
		if (cutMode && hasMaskShape) {
			pointCount = currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex].points.length || 0;
		} else {
			pointCount = currentShapes[currentShapeIndex].points.length;
		}
	}
	if (pointSelected) {
		if (cutMode && currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex].points[activePoint]) {
			isQuadPoint = currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex].points[activePoint].q !== undefined;
		} else if (currentShapes[currentShapeIndex].points[activePoint]) {
			isQuadPoint = currentShapes[currentShapeIndex].points[activePoint].q !== undefined;
		}
	} else {
		isQuadPoint = false;
	}

	let buttons = [];

	if (cutMode) {
		buttons.push(<Button label="Done Masking" key="doneMasking" className="outline-green done-masking" icon="fa-check" onClick={() => setMasking(false)} />);
		buttons.push(
			<Button
				label="Add Mask"
				key="addMaskCutMode"
				className="green mask"
				icon="fa-scissors"
				onClick={addNewCutShape}
				onMouseDown={e => {
					e.preventDefault();
				}}
			/>
		);
		if (pointSelected && hasMaskShape && pointCount > 1) {
			buttons.push(<Button label="Delete Point" onClick={() => props.removeActivePoint()} className="red delete-point" key="deletePoint" icon="fa-trash" />);
			if (activePoint !== 0) {
				buttons.push(
					<Button
						label={isQuadPoint ? "Normal Point" : "Curve Point"}
						onClick={() => props.setPointType(isQuadPoint ? "l" : "q")}
						className="outline-green curve"
						key="curve"
						icon={isQuadPoint ? "fa-square-o" : "fa-circle-o"}
					/>
				);
			}
		}
		if (shapeSelected && hasMaskShape) {
			buttons.push(<Button label="Delete Mask" icon="fa-trash" key="delMask" className="red delete-shape" onClick={props.deleteShape} />);
			buttons.push(
				<Button
					label="Copy Mask"
					key="copyMask"
					icon="fa-copy"
					className="outline-green"
					onClick={props.copyShape}
					onMouseDown={e => {
						e.preventDefault();
					}}
				/>
			);
		}
	} else {
		buttons.push(
			<Button
				label="Add New Shape"
				icon="fa-plus"
				key="newShape"
				style={{ width: "360px" }}
				onClick={() => {
					Popup.plugins().chooseGroup(
						groups,
						groups[currentGroupIndex],
						val => {
							addShape(val);
						},
						resetShape
					);
				}}
				className="button add-shape green"
			/>
		);

		if (shapeSelected) {
			if (!isFirstPoint) {
				buttons.push(<Button label="Copy Shape" icon="fa-copy" key="copy" className="outline-green copy-shape" onClick={props.copyShape} />);
				buttons.push(<Button label="Delete Shape" icon="fa-trash" key="delShape" className="red" onClick={props.deleteShape} />);

				if (pointCount > 2) {
					buttons.push(
						<Button
							label={hasMaskShape ? "Edit Mask" : "Add Mask"}
							key="addMask"
							className="outline-green mask"
							icon="fa-scissors"
							onClick={() => (hasMaskShape ? setCutMode(true) : addNewCutShape())}
						/>
					);
				}
				if (pointSelected) {
					if (pointCount > 1) {
						buttons.push(<Button label="Delete Point" onClick={props.removeActivePoint} className="red delete-point" key="deletePoint" icon="fa-trash" />);
						if (activePoint !== 0) {
							buttons.push(
								<Button
									label={isQuadPoint ? "Normal Point" : "Curve Point"}
									onClick={() => props.setPointType(isQuadPoint ? "l" : "q")}
									className="outline-green curve"
									key="curve"
									icon={isQuadPoint ? "fa-square-o" : "fa-circle-o"}
								/>
							);
						}
					}
				}
				if (pointCount > 2) {
					buttons.push(
						<Button label="Perspective" key="setPerspective" className="green perspective" SVGIcon={Perspective} onClick={() => setChangingPerspective(true)} />
					);
				}
			}
		}
	}

	const showMissingPerspectiveTips = () => {
		{
			if (!missingTransfromError) return;
			resetErrors();
			const { id } = missingTransfromShape;
			setPerspectiveTips([
				{
					selector: ".shape-group#" + id,
					content: () => (
						<div>
							<h5>Tutorial / Perspective</h5>
							<h3>Impoving the Look</h3>
							<hr />
							<p>Setting up the perspective for each shape will help improve the look of your products.</p>
							<p>First select this shape.</p>
						</div>
					),
					action: node => {
						node.focus();
					}
				},
				{
					selector: ".perspective",
					content: c => (
						<div>
							<h5>Tutorial / Perspective</h5>
							<h3>Impoving the Look</h3>
							<hr />
							<p>
								Then click <b>Set Perspective</b>.
							</p>
							<div className="center">
								<Button className="green small" label="Let's Start!" onClick={c.close} />
							</div>
						</div>
					),
					action: node => {
						node.focus();
					}
				}
			]);
		}
	};
	return (
		<Fade delay={300} duration={1200}>
			<section id="controls">
				<h1 className="center">Draw</h1>
				<p className="center">
					<kbd>Shift</kbd> + Click to add a point
				</p>
				<ReactCSSTransitionGroup
					component="div"
					className="button-area"
					key="buttons"
					transitionName="fade"
					transitionEnter={true}
					transitionLeave={true}
					transitionEnterTimeout={150}
					transitionLeaveTimeout={150}
				>
					{buttons}
				</ReactCSSTransitionGroup>

				<GroupList
					groups={groups}
					currentGroupIndex={currentGroupIndex}
					selectGroup={props.selectGroup}
					renameGroup={props.renameGroup}
					deleteGroup={props.deleteGroup}
				/>

				<div className="nav">
					<Button
						label="Back"
						icon="fa-chevron-left"
						className="outline-green"
						onClick={() => {
							goToURI("/scale");
						}}
					/>
					<Button
						label="Next"
						icon="fa-chevron-right"
						className="green done"
						onClick={() => {
							const groupCheck = props.getEmptyGroupNames(currentGroupIndex);

							if (isFirstPoint) {
								setEmptyGroupError(true);
							} else if (missingTransfrom) {
								setMissingTransfromError(true);
							} else if (groupCheck.length) {
								const groupIds = [];
								const groupNames = [];
								groupCheck.forEach(group => {
									groupIds.push(group.index);
									groupNames.push(group.name);
								});
								setEmptyGroupIds(groupIds);
								setEmptyGroupNotice(arrayToSentence(groupNames));
							} else {
								goToURI("/products");
							}
						}}
					/>
				</div>
				{!missingTransfromError && !emptyGroupError && (
					<SweetAlert
						show={emptyGroupNotice !== false}
						title="Remove Empty Groups"
						type="warning"
						showCancel
						showCancelButton
						onConfirm={() => {
							resetErrors();
							props.deleteGroups(emptyGroupIds, () => {
								goToURI("/products");
							});
						}}
						onCancel={resetErrors}
						onEscapeKey={resetErrors}
						//onOutsideClick={resetErrors}
						closeOnClickOutside={false}
						text={`Would like to continue without the ${emptyGroupNotice} Group?`}
						confirmButtonColor="#0A4438"
					/>
				)}
				{!emptyGroupNotice && !emptyGroupError && (
					<SweetAlert
						show={missingTransfromError}
						title="Missing Perspective"
						type="warning"
						text={`You must set perspective for each of your shapes before you can continue.`}
						confirmButtonColor="#0A4438"
						onCancel={showMissingPerspectiveTips}
						onEscapeKey={showMissingPerspectiveTips}
						//	onOutsideClick={showMissingPerspectiveTips}
						closeOnClickOutside={false}
						onConfirm={showMissingPerspectiveTips}
					/>
				)}
				{!emptyGroupNotice && !missingTransfromError && (
					<SweetAlert
						show={emptyGroupError}
						title={groups[currentGroupIndex].label + " Has No Shape"}
						type="warning"
						text={`You must draw at least one shape or delete the ${groups[currentGroupIndex].label} group before continuing.`}
						confirmButtonColor="#0A4438"
						onConfirm={resetErrors}
					/>
				)}
			</section>

			<Tour
				key="scale"
				closeWithMask={false}
				steps={perspectiveTips ? perspectiveTips : []}
				disableInteraction={true}
				isOpen={perspectiveTips !== false}
				onRequestClose={resetErrors}
				accentColor="#0a4436"
				highlightedMaskClassName="tour-active"
				showNavigation={false}
				showNavigationNumber={false}
				onAfterOpen={disableBody}
				onAfterClose={enableBody}
				onMaskClick={() => {
					debugger;
				}}
			/>
		</Fade>
	);
};

export default Controls;

/** Prompt plugin */
Popup.registerPlugin("chooseGroup", function(groups, selectedGroup, callback, cancelAction) {
	let selection;
	let handleChange = function(value) {
		selection = value;
	};
	this.create({
		title: "Add New Shape",
		content: <ChooseGroup onChange={handleChange} currentGroupIndex={selectedGroup.value} groups={groups} value={selectedGroup} />,
		buttons: {
			left: [
				{
					text: "Cancel",
					className: "cancel",
					action: function() {
						//cancelAction();
						Popup.close();
					}
				}
			],
			right: [
				{
					text: "Add",
					key: "⌘+s",
					className: "success",
					action: function() {
						callback(selection);
						Popup.close();
					}
				}
			]
		}
	});
});
