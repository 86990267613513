import React, { useState, useLayoutEffect, useEffect } from "react";
import { clone, useVisualizerAPI } from "./utils";
import Cookies from "universal-cookie";
import domtoimage from "dom-to-image-more";
import { Route, Switch, withRouter } from "react-router-dom";
import SweetAlert from "sweetalert-react";
import ReactModal from "react-modal";
import Header from "./components/Header";
import ErrorBoundary from "./components/ErrorBoundary";
import BeforeUnload from "./components/BeforeUnload";
import LoadingOverlay from "react-loading-overlay";

//pages
import Home from "./pages/Home";
import Draw from "./pages/Draw";
import Crop from "./pages/Crop";
import Login from "./pages/Login";
import Products from "./pages/Products";
import Project from "./pages/Project";
import ForgotPassword from "./pages/ForgotPassword";
import Share from "./pages/Share";

import Popup from "react-popup";
import "sweetalert/dist/sweetalert.css";

function useForceUpdate() {
    const [value, set] = useState(true); //boolean state
    return () => set(!value); // toggle the state to force render
}

const App = ({ cache, location, history, updateCache }) => {
    ReactModal.setAppElement("#root");
    const cookies = new Cookies();

    const forceUpdate = useForceUpdate();
    const w = 1024;
    const h = 768;
    const userTokenCookie = cookies.get("userToken");
    const defaultWorkingImage = cookies.get("image") || false;

    const [workingImage, setWorkingImage] = useState(defaultWorkingImage);
    const [defaultHelpState, updateDefaultHelpState] = useState(null);
    const [showWelcome, setShowWelcome] = useState(null);
    const [shownWelcome, setShownWelcome] = useState(false);
    const [isHelpOpen, setIsHelpOpen] = useState(
        defaultHelpState ? true : false
    );
    const [showHelpButton, updateShowHelpButton] = useState(null);
    const [userAddedFirstPoint, setUserAddedFirstPoint] = useState(null);
    const [userAddedFirstShape, setUserAddedFirstShape] = useState(null);
    const [userToken, setUserToken] = useState(
        typeof userTokenCookie === "undefined" ? false : null
    );
    const [isSaving, setSaving] = useState(false);
    const [isCopying, setCopying] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [projects, setProjects] = useState([]);
    const [projectListLoading, setProjectListLoading] = useState(true);
    const [projectImage, setProjectImage] = useState(false);
    const [currentProject, setCurrentProject] = useState(0);
    const [preview, setPreview] = useState(false);
    const [helplog, setHelpLog] = useState([]);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);

    const generateUUID = () => {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-';
        let charactersLength = characters.length;
        for ( let i = 0; i < 8; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const resetTutorialState = () => {
        setShownWelcome(false);
        setShowWelcome(true);
        setUserAddedFirstPoint(false);
        setUserAddedFirstShape(false);
        updateDefaultHelpState(null);
    };

    const setShowHelpButtonFalse = () => {
        updateShowHelpButton(false);
    };

    const setDefaultHelpState = val => {
        updateDefaultHelpState(val);
    };

    useEffect(() => {
        const addPageToHelpLog = pathname => {
            const newHelpLog = clone(helplog);
            newHelpLog.push(pathname);
            setHelpLog(newHelpLog);
        };

        const tutorialWatcher = (event, action) => {
            if (action !== "PUSH") return;
            const { pathname } = event;
            const isHelpPage = !helplog.includes(pathname);
            if (!shownWelcome && defaultHelpState === null) {
                setShowWelcome(true);
            } else if (defaultHelpState && !isHelpOpen && isHelpPage) {
                addPageToHelpLog(pathname);
                setIsHelpOpen(true);
            }
        };

        const unlisten = history.listen(tutorialWatcher);
        return () => {
            unlisten();
        };
    });

    useEffect(() => {
        if (cache.size) {
            updateCache();
        }
    }, [cache.size, updateCache]);

    const updateProjectData = res => {
        setProjectListLoading(false);
        setProjects(res.data);
    };
    const updateWorkingImage = image => {
        setWorkingImage(image);
        if (image) cookies.set("image", image, { path: "/" });
        else cookies.remove("image");
    };

    const apiBase = window.location.href.includes("local")
        ? "http://local.havelockmetal.com/"
        : "https://hmc.theyinteractive.com/";

    const saveProject = useVisualizerAPI({
        url: "visualizer-api?action=saveProject",
        method: "POST",
        onSuccess: res => {
            setSaving(false);
            if (res.data) {
                const { sharehash } = res;
                setShareHash(sharehash);
                updateProjectData(res);
                setTimeout(() => {
                    history.push("/open");
                }, 150);
            } else {
                console.error(res);
            }
        }
    });

    const forgotPassword = useVisualizerAPI({
        url: "visualizer-api?action=forgotPassword",
        method: "POST",
        onSuccess: res => {
            setSentPassword(true);
        }
    });

    const handleForgotPassword = (event, username) => {
        event.preventDefault();
        forgotPassword.doFetch({
            body: {
                username: username
            }
        });
    };

    const deleteProject = useVisualizerAPI({
        url: "visualizer-api?action=deleteProject",
        method: "POST",
        onSuccess: updateProjectData
    });

    const listProjects = useVisualizerAPI({
        url: "visualizer-api?action=listProjects",
        method: "POST",
        onSuccess: updateProjectData
    });

    const copyProject = useVisualizerAPI({
        url: "visualizer-api?action=copyProject",
        method: "POST",
        onSuccess: res => {
            setCopying(false);
            if (res.data) {
                updateProjectData(res);
            } else {
                console.error(res);
            }
        }
    });

    const updateUserData = res => {
        const { user } = res.data;
        if (user && user.token) {
            setUserToken(user);
            setProjectListLoading(true);
            listProjects.doFetch({
                body: {
                    token: user.token
                }
            });
        } else {
            setUserToken(false);
        }
    };

    const checkToken = useVisualizerAPI({
        url: "visualizer-api?action=checkToken",
        method: "POST",
        onSuccess: updateUserData
    });

    useLayoutEffect(() => {
        if (userTokenCookie && userTokenCookie.token) {
            setProjectListLoading(true);
            checkToken.doFetch({
                body: {
                    token: userTokenCookie.token
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    const defaultTextureGroup = {
        url:
            "https://www.havelockmetal.com/uploads/2018/10/HMC_VisualizerProfilesV2-DELTA-1.svg",
        size: "36",
        textureColor: "#9fa1a4",
        productId: 147,
        colorId: 15,
        productName: "Delta",
        colorName: "Regent Grey",
        categoryId: 105,
        isProductSet: false
    };

    const defaultGroups = [
        {
            value: 0,
            label: "Roofing",
            texture: {
                ...defaultTextureGroup
            }
        },
        {
            value: 1,
            label: "Siding",
            texture: {
                ...defaultTextureGroup
            }
        }
    ];
    const defaultScaleShapes = [
        {
            points: [
                { x: 600, y: 460 },
                { x: 600, y: 610 }
            ],
            cutShapes: []
        }
    ];
    const defaultShape = {
        id: generateUUID(),
        points: [],
        transformMatrix: "matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1)",
        rotate: 0,
        scale: 1,
        cutShapes: [],
        hasPersective: false
    };
    const defaultShapes = [];

    const [unsavedWarning, setUnsavedWarning] = useState(false);
    const [shapes, updateShapes] = useState(defaultShapes);
    const [scaleShapes, setScaleShapes] = useState(defaultScaleShapes);
    const [currentShapeIndex, updateCurrentShapeIndex] = useState(0);
    const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
    const [groups, setGroups] = useState(defaultGroups);
    const [scaleInches, setScaleInches] = useState(8);
    const [scaleFeet, setScaleFeet] = useState(6);
    const [scaleTotal, setScaleTotal] = useState(scaleFeet * 12 + scaleInches);
    const [scaleShapeHeight, setScaleShapeHeight] = useState(
        Math.abs(scaleShapes[0].points[0].y - scaleShapes[0].points[1].y)
    );

    const [isLoginOpen, setLoginOpen] = useState(false);
    const [sentPassword, setSentPassword] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [shareHash, setShareHash] = useState(false);

    const [gridSnap, setGridSnap] = useState(false);
    const [gridShow, setGridShow] = useState(true);

    const startNew = () => {
        let warn = false;
        shapes.forEach((s, i) => {
            if (s !== null && s.points.length) {
                warn = true;
            }
        });

        if (warn) {
            setUnsavedWarning(true);
        } else {
            resetTutorialState();
            setWorkingImage(false);
            setShapes(defaultShapes);
            setGroups(defaultGroups);
            updateCurrentShapeIndex(-1);
            setCurrentGroupIndex(0);
            goToURI("/new");
        }
    };

    const hasIndex = (arr, index) => {
        let result = false;
        for (let el of arr) {
            if (el.index === index) result = true;
        }
        return result;
    };

    const getEmptyGroupNames = ignoreGroupIndex => {
        let groupCheck = [];
        let hasShape = false;
        let groupShapes = [];

        groups.forEach(g => {
            if (g === null) return;
            if (g.value === currentGroupIndex) return;
            hasShape = false;
            groupShapes = shapes.filter(function(obj) {
                return obj !== null && obj.group === g.value;
            });
            groupShapes.forEach(s => {
                if (
                    s !== null &&
                    s.group !== currentGroupIndex &&
                    s.points.length > 0
                ) {
                    hasShape = true;
                }
            });
            if (!hasShape) {
                if (!hasIndex(groupCheck, g.value)) {
                    groupCheck.push({
                        index: g.value,
                        name: g.label
                    });
                }
            }
        });

        return groupCheck;
    };

    const updateCurrentGroupIndex = groupIndex => {
        let newShapeIndex = null;
        shapes.forEach((s, index) => {
            if (typeof shape === "undefined" || s === null) return;
            if (s.group === groupIndex) newShapeIndex = index;
        });
        setCurrentGroupIndex(groupIndex);
        if (newShapeIndex !== null) updateCurrentShapeIndex(newShapeIndex);
    };

    const updateScaleInches = val => {
        setScaleInches(parseInt(val));
        setScaleTotal(parseInt(scaleFeet) * 12 + parseInt(val));
    };

    const updateScaleFeet = val => {
        setScaleFeet(parseInt(val));
        setScaleTotal(parseInt(scaleInches) + parseInt(val) * 12);
    };

    const setShapes = newShapes => {
        updateShapes(newShapes);
        if (currentPath === "/scale" || currentPath === "/") return;
        let hasActiveShape =
            shapes[currentShapeIndex] &&
            shapes[currentShapeIndex].hasOwnProperty("points") &&
            newShapes[currentShapeIndex] &&
            newShapes[currentShapeIndex].hasOwnProperty("points");
        if (!userAddedFirstPoint && newShapes.length) {
            setUserAddedFirstPoint(true);
        } else if (hasActiveShape && !userAddedFirstShape) {
            if (newShapes[currentShapeIndex].points.length > 2) {
                setUserAddedFirstShape(true);
            }
        }

        forceUpdate();
    };

    const toggleHelp = () => {
        setIsHelpOpen(!isHelpOpen);
    };

    const currentPath = location.pathname;
    const [zoom, setZoom] = useState({
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        skewX: 0,
        skewY: 0,
        translateX: 0,
        translateY: 0
    });

    const cancelWelcome = () => {
        setShowWelcome(false);
        setShownWelcome(true);
        setDefaultHelpState(false);
        setIsHelpOpen(false);
        if (showHelpButton === null) updateShowHelpButton(true);
    };

    const goToURI = (url, push = true) => {
        if (push) {
            history.push(url);
        } else {
            history.replace(url);
        }
    };

    const logout = () => {
        cookies.remove("userToken");
        cookies.remove("image");
        setUserToken(false);
        setWorkingImage(null);
        history.push("/");
    };

    const updateTexture = texture => {
        const newGroups = clone(groups);
        newGroups[currentGroupIndex].texture = {
            ...texture
        };
        setGroups(newGroups);
    };

    const updateProductCategory = termId => {
        const newGroups = clone(groups);
        newGroups[currentGroupIndex].texture = {
            ...groups[currentGroupIndex].texture,
            categoryId: termId
        };
        setGroups(newGroups);
    };

    const handleSaveProject = (artboardRef, currentProject, projectName) => {
        setSaving(true);
        rasterizeProject(artboardRef).then(data => {
            const { projectThumb, rasterizedProject } = data;
            const saveData = {
                id: currentProject,
                projectThumb,
                rasterizedProject,
                shapes,
                groups,
                image: workingImage
            };
            saveProject.doFetch({
                body: {
                    token: userTokenCookie.token,
                    projectName: projectName,
                    projectData: JSON.stringify(saveData)
                }
            });
        });
    };

    const addShape = shape => {
        shape.rotate = 0;
        shape.transformMatrix = "matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1)";
        shape.id = generateUUID();
        let newShapes = [...shapes];
        let oldShapeIndex = false;
        let newShapeIndex = newShapes.length;
        
        if (oldShapeIndex) {
            newShapes[oldShapeIndex] = shape;
            newShapeIndex = oldShapeIndex;
        } else {
            newShapes.push(shape);
        }

        if (!userAddedFirstPoint) {
            updateShapes(newShapes);
            setUserAddedFirstPoint(true);
        } else {
            setShapes(newShapes);
        }

        if (shape.group !== currentGroupIndex) {
            updateCurrentGroupIndex(shape.group);
        }

        if (currentShapeIndex !== newShapeIndex) {
            updateCurrentShapeIndex(newShapeIndex);
        }
    };

    const copyShape = () => {
        const newShape = clone(shapes[currentShapeIndex]);
        newShape.points.forEach((p, i) => {
            newShape.points[i].x = p.x + 25;
            newShape.points[i].y = p.y + 25;
        });
        newShape.cutShapes.forEach((cutShape, cutShapeIndex) => {
            cutShape.points.forEach((p, i) => {
                newShape.cutShapes[cutShapeIndex].points[i].x = p.x + 25;
                newShape.cutShapes[cutShapeIndex].points[i].y = p.y + 25;
            });
        });
        addShape(newShape);
    };

    const deleteShape = () => {
        let newShapes = [];
        if (currentShapes.length === 1) {
            newShapes = [...shapes];
            newShapes[currentShapeIndex] = {
                ...defaultShape,
                id: generateUUID(),
                group: currentGroupIndex
            };
        } else {
            shapes.forEach((shape, index) => {
                if (index !== currentShapeIndex) {
                    newShapes[index] = shape;
                } else {
                    newShapes[index] = null;
                }
            });
        }
        let newShapeIndex = 0;
        let resetTutorial = true;
        newShapes.forEach((shape, index) => {
            if (typeof shape === "undefined") return;
            if (shape !== null && shape.group === currentGroupIndex) {
                newShapeIndex = index;
                resetTutorial = false;
            }
        });

        if (resetTutorial) {
            setUserAddedFirstPoint(false);
            setUserAddedFirstShape(false);
        }

        updateCurrentShapeIndex(newShapeIndex);
        setShapes(newShapes);
    };

    let currentShapes = [];
    let isFirstPoint = true;
    let hasMaskShape = false;
    const { pathname } = location;

    if (currentPath === "/scale") {
        if (currentShapeIndex !== 0) {
            updateCurrentShapeIndex(0);
        }
        currentShapes = scaleShapes;
    } else if (pathname === "/share") {
        currentShapes = shapes;
    } else {
        currentShapes = [...shapes];
        shapes.forEach((shape, index) => {
            if (typeof shape === "undefined") return;
            if (shape !== null && shape.group === parseInt(currentGroupIndex)) {
                currentShapes[index] = shape;
                isFirstPoint = !shape.points.length;
            } else {
                currentShapes[index] = null;
            }
        });
    }

    if (!isFirstPoint) {
        if (
            typeof currentShapes[currentShapeIndex] !== "undefined" &&
            currentShapes[currentShapeIndex] !== null &&
            typeof currentShapes[currentShapeIndex].cutShapes !== "undefined" &&
            currentShapes[currentShapeIndex].cutShapes.length > 0
        ) {
            currentShapes[currentShapeIndex].cutShapes.forEach(s => {
                if (s !== null && s.points.length > 0) {
                    hasMaskShape = true;
                }
            });
        }
    }

    const updateCurrentShape = shape => {
        const newShapes = clone(shapes);
        if (currentPath === "/scale") {
            scaleShapes[0] = shape;
            const y1 = shape.points[0].y;
            const y2 = shape.points[1].y;
            setScaleShapeHeight(Math.abs(y1 - y2));
            setScaleShapes(scaleShapes);
            forceUpdate();
        } else {
            if (newShapes[currentShapeIndex] && newShapes[currentShapeIndex].id === shape.id) {
                newShapes[currentShapeIndex] = shape;
                newShapes[currentShapeIndex].center = getCenterCoords(
                    newShapes[currentShapeIndex]
                );
                setShapes(newShapes);
            } else {
                addShape(shape);
            }
        }
    };

    const getCenterCoords = shape => {
        if (!shape.points) return { x: 50, y: 50 };
        let minX, maxX, minY, maxY;
        shape.points.forEach(p => {
            minX = p.x < minX || minX == null ? p.x : minX;
            maxX = p.x > maxX || maxX == null ? p.x : maxX;
            minY = p.y < minY || minY == null ? p.y : minY;
            maxY = p.y > maxY || maxY == null ? p.y : maxY;
        });
        const x = (minX + maxX) / 2;
        const y = (minY + maxY) / 2;
        const percentX = (x / 1024) * 100;
        const percentY = (y / 1024) * 100;

        return { x: percentX, y: percentY };
    };

    const addGroup = group => {
        setGroups([...groups, { ...group }]);
        updateCurrentGroupIndex(group.value);
    };

    const deleteGroups = (groupIds, cb) => {
        let newGroups = [];
        groups.forEach((group, index) => {
            if (group !== null && !groupIds.includes(group.value)) {
                newGroups[index] = group;
            } else {
                newGroups[index] = null;
            }
        });
        let newShapes = [...shapes];
        shapes.forEach((shape, index) => {
            if (shape === null) return;
            if (!groupIds.includes(shape.group)) {
                newShapes[index] = shape;
            } else {
                newShapes[index] = null;
            }
        });
        updateShapes(newShapes);
        setGroups(newGroups);
        cb();
    };

    const deleteGroup = groupId => {
        if (groups.length < 1) return;

        let newGroups = [];
        groups.forEach((group, index) => {
            if (group !== null && group.value !== groupId) {
                newGroups[index] = group;
            } else {
                newGroups[index] = null;
            }
        });
        if (currentGroupIndex === groupId) {
            let newGroupIndex = 0;
            newGroups.forEach((g, i) => {
                if (g !== null) newGroupIndex = i;
            });

            let newCurrentShapes = [];
            shapes.forEach((shape, index) => {
                if (shape === null) return;
                if (shape.group === parseInt(newGroupIndex)) {
                    newCurrentShapes[index] = shape;
                }
            });
            if (!newCurrentShapes[currentGroupIndex]) {
                let newShapeIndex = 0;
                newCurrentShapes.forEach((s, index) => {
                    if (s.group === newGroupIndex) {
                        newShapeIndex = index;
                    }
                });
                updateCurrentShapeIndex(newShapeIndex);
            }
            updateCurrentGroupIndex(parseInt(newGroupIndex));
        }
        console.log(newGroups);
        setGroups(newGroups);

        let newShapes = [...shapes];
        shapes.forEach((shape, index) => {
            if (shape === null) return;
            if (shape.group !== groupId) {
                newShapes[index] = shape;
            } else {
                newShapes[index] = null;
            }
        });
        setShapes(newShapes);
    };

    const renameGroup = (groupId, groupName) => {
        groups[groupId].label = groupName;
        setGroups(groups);
    };

    const updateCurrentGroup = group => {
        groups[currentGroupIndex] = group;
        setGroups(groups);
    };

    const loadProject = (id, data, shareHash) => {
        const currentProject = projects.filter(function(el) {
            return el.id === id;
        });

        const { shapes, groups, image } = data;
        cancelWelcome();
        setGroups(groups);
        setShapes(shapes);
        setShareHash(shareHash);
        setProjectName(currentProject[0].project_name);
        updateWorkingImage(image);
        setCurrentProject(id);
        setUserAddedFirstPoint(true);
        setUserAddedFirstShape(true);

        let newCurrentGroupIndex = 0;
        let newCurrentShapeIndex = -1;
        groups.forEach((g, i) => {
            if (g !== null) newCurrentGroupIndex = i;
        });
        shapes.forEach((s, i) => {
            if (s !== null && s.group === newCurrentGroupIndex) {
                newCurrentShapeIndex = i;
            }
        });
        updateCurrentShapeIndex(newCurrentShapeIndex);
        setCurrentGroupIndex(newCurrentGroupIndex);
        history.push(`/draw`);
    };

    const rasterizeProject = ref => {
        return new Promise((resolve, reject) => {
            let projectThumb;
            let rasterizedProject;
            if (!ref) reject();
            const maxWidth = 400;
            const maxHeight = 300;
            const reader = new FileReader();
            domtoimage
                .toBlob(ref.firstElementChild, {
                    quality: 0.95,
                    style: {
                        height: h,
                        width: w,
                        transform: "none",
                        top: "0",
                        left: "0"
                    }
                })
                .then(blob => {
                    const { type } = blob;
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        const img = document.createElement("img");
                        img.src = base64data;

                        img.onload = function() {
                            const el = document.createElement("canvas");
                            const ratio = Math.min(
                                maxWidth / img.naturalWidth,
                                maxHeight / img.naturalHeight
                            );
                            const ctx = el.getContext("2d");
                            ctx.canvas.width = maxWidth;
                            ctx.canvas.height = maxHeight;
                            const x =
                                (ctx.canvas.width - img.naturalWidth * ratio) /
                                2;
                            const y =
                                (ctx.canvas.height -
                                    img.naturalHeight * ratio) /
                                2;
                            ctx.drawImage(
                                img,
                                0,
                                0,
                                img.naturalWidth,
                                img.naturalHeight,
                                x,
                                y,
                                img.naturalWidth * ratio,
                                img.naturalHeight * ratio
                            );
                            const dataUrl = ctx.canvas.toDataURL(
                                "image/jpeg",
                                0.75
                            );
                            const body = {
                                token: userTokenCookie.token,
                                image: dataUrl,
                                mimeType: "image/jpeg"
                            };
                            let endPoint = `${apiBase}visualizer-api?action=uploadImage`;
                            if (projectThumb) {
                                endPoint +=
                                    "&filename=" +
                                    encodeURIComponent(projectThumb);
                            }

                            fetch(endPoint, {
                                method: "post",
                                body: JSON.stringify(body)
                            })
                                .then(response => response.json())
                                .then(data => {
                                    projectThumb = data.url;
                                });
                        };
                        const body = {
                            token: userTokenCookie.token,
                            image: base64data,
                            mimeType: type
                        };
                        let endPoint = `${apiBase}visualizer-api?action=uploadImage`;
                        if (rasterizedProject) {
                            endPoint +=
                                "&filename=" +
                                encodeURIComponent(rasterizedProject);
                        }
                        fetch(endPoint, {
                            method: "post",
                            body: JSON.stringify(body)
                        })
                            .then(response => response.json())
                            .then(data => {
                                rasterizedProject = data.url;
                                resolve({
                                    rasterizedProject,
                                    projectThumb
                                });
                            });
                    };
                });
        });
    };

    const loggedIn = userToken !== null;
    let shapeCount = 0;

    if (!groups[currentGroupIndex]) {
        let newCurrentGroupIndex = 0;
        groups.forEach((g, i) => {
            if (g !== null) {
                newCurrentGroupIndex = i;
            }
        });
        updateCurrentGroupIndex(newCurrentGroupIndex);
    }

    currentShapes.forEach(shape => {
        if (typeof shape === "undefined") return;
        if (shape !== null) shapeCount++;
    });

    const modalStyles = {
        display: "flex",
        content: {
            position: "relative",
            top: "25vh",
            border: "1px solid rgb(204, 204, 204)",
            background: "rgb(255, 255, 255)",
            overflow: "auto",
            borderRadius: 0,
            outline: "none",
            padding: "20px",
            animation: "showModal 0.3s",
            alignSelf: "center",
            margin: "0 auto",
            width: "457px"
        }
    };

    if (
        currentPath &&
        currentPath.startsWith("/forgot-password") &&
        isLoginOpen
    ) {
        setLoginOpen(false);
    }

    return (
        <ErrorBoundary>
            <LoadingOverlay
                active={isLoading}
                spinner
                text={progress ? progress : "Generating Your Project..."}
                onClick={e => e.preventDefault()}
            >
                <Route
                    exact
                    path="*"
                    render={({ location }) => (
                        <>
                            <Header
                                location={location}
                                currentPath={currentPath}
                                isShapeActive={currentShapeIndex !== -1}
                                setLoginOpen={setLoginOpen}
                                isLoginOpen={isLoginOpen}
                                userToken={userToken}
                                workingImage={workingImage}
                                logout={logout}
                                goToURI={goToURI}
                                userTokenCookie={userTokenCookie}
                            />
                        </>
                    )}
                />

                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => (
                            <Home
                                userToken={userToken}
                                startNew={startNew}
                                setLoginOpen={setLoginOpen}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/new"
                        render={() => (
                            <Crop
                                w={w}
                                h={h}
                                croppedImageUrl={croppedImageUrl}
                                setCroppedImageUrl={setCroppedImageUrl}
                                setCurrentProject={setCurrentProject}
                                userToken={userToken}
                                workingImage={workingImage}
                                updateWorkingImage={updateWorkingImage}
                                isHelpOpen={isHelpOpen}
                                toggleHelp={toggleHelp}
                                showHelpButton={showHelpButton}
                                setShowHelpButtonFalse={setShowHelpButtonFalse}
                                goToURI={goToURI}
                                setIsHelpOpen={setIsHelpOpen}
                                defaultHelpState={defaultHelpState}
                                setDefaultHelpState={setDefaultHelpState}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="(/draw||/scale)"
                        render={({ location }) => (
                            <>
                                <BeforeUnload
                                    onBeforeunload={e =>
                                        `If you leave this page, you will lose any unsaved changes.`
                                    }
                                >
                                    <Draw
                                        w={w}
                                        h={h}
                                        location={location}
                                        addShape={addShape}
                                        deleteShape={deleteShape}
                                        currentShapes={currentShapes}
                                        currentShapeIndex={parseInt(
                                            currentShapeIndex
                                        )}
                                        updateCurrentShape={updateCurrentShape}
                                        updateCurrentShapeIndex={
                                            updateCurrentShapeIndex
                                        }
                                        groups={groups}
                                        addGroup={addGroup}
                                        scaleFeet={scaleFeet}
                                        scaleInches={scaleInches}
                                        updateScaleInches={updateScaleInches}
                                        updateScaleFeet={updateScaleFeet}
                                        currentGroupIndex={currentGroupIndex}
                                        updateCurrentGroup={updateCurrentGroup}
                                        updateCurrentGroupIndex={
                                            updateCurrentGroupIndex
                                        }
                                        image={workingImage}
                                        updateTexture={updateTexture}
                                        renameGroup={renameGroup}
                                        deleteGroup={deleteGroup}
                                        shapeCount={shapeCount}
                                        setZoom={setZoom}
                                        zoom={zoom}
                                        copyShape={copyShape}
                                        isHelpOpen={isHelpOpen}
                                        toggleHelp={toggleHelp}
                                        scaleTotal={scaleTotal}
                                        scaleShapeHeight={scaleShapeHeight}
                                        isFirstPoint={isFirstPoint}
                                        goToURI={goToURI}
                                        userAddedFirstPoint={
                                            userAddedFirstPoint
                                        }
                                        userAddedFirstShape={
                                            userAddedFirstShape
                                        }
                                        gridSnap={gridSnap}
                                        setGridSnap={setGridSnap}
                                        gridShow={gridShow}
                                        setGridShow={setGridShow}
                                        getEmptyGroupNames={getEmptyGroupNames}
                                        deleteGroups={deleteGroups}
                                        hasMaskShape={hasMaskShape}
                                        defaultShape={defaultShape}
                                        setIsHelpOpen={setIsHelpOpen}
                                        defaultHelpState={defaultHelpState}
                                        setDefaultHelpState={
                                            setDefaultHelpState
                                        }
                                    />
                                </BeforeUnload>
                            </>
                        )}
                    />
                    <Route
                        exact
                        path="(/products||/preview)"
                        render={() => (
                            <Products
                                w={w}
                                h={h}
                                isSaving={isSaving}
                                image={workingImage}
                                currentShapes={preview ? shapes : currentShapes}
                                updateTexture={updateTexture}
                                //  updateTextureColor={updateTextureColor}
                                saveProjects={handleSaveProject}
                                currentProject={currentProject}
                                setProjectName={setProjectName}
                                projectName={projectName}
                                loggedIn={loggedIn}
                                renameGroup={renameGroup}
                                deleteGroup={deleteGroup}
                                groups={groups}
                                currentGroupIndex={currentGroupIndex}
                                updateCurrentGroup={updateCurrentGroup}
                                updateCurrentGroupIndex={
                                    updateCurrentGroupIndex
                                }
                                preview={preview}
                                setPreview={setPreview}
                                shareHash={shareHash}
                                rasterizeProject={rasterizeProject}
                                isHelpOpen={isHelpOpen}
                                toggleHelp={toggleHelp}
                                setProjectImage={setProjectImage}
                                projectImage={projectImage}
                                scaleTotal={scaleTotal}
                                scaleShapeHeight={scaleShapeHeight}
                                updateProductCategory={updateProductCategory}
                                goToURI={goToURI}
                                setIsHelpOpen={setIsHelpOpen}
                                defaultHelpState={defaultHelpState}
                                setDefaultHelpState={setDefaultHelpState}
                                setLoading={setLoading}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/open"
                        render={() => (
                            <Project
                                isSaving={isSaving}
                                loadProject={loadProject}
                                copyProject={copyProject}
                                setCopying={setCopying}
                                isCopying={isCopying}
                                projects={projects}
                                deleteProject={deleteProject}
                                userToken={userToken}
                                renameGroup={renameGroup}
                                deleteGroup={deleteGroup}
                                setShareHash={setShareHash}
                                setProjectListLoading={setProjectListLoading}
                                projectListLoading={projectListLoading}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/forgot-password/:resetToken"
                        render={() => <ForgotPassword />}
                    />
                    <Route
                        exact
                        path="/share/"
                        render={() => {
                            return (
                                <Share
                                    w={w}
                                    h={h}
                                    isSaving={isSaving}
                                    image={workingImage}
                                    currentShapes={currentShapes}
                                    updateTexture={updateTexture}
                                    saveProjects={handleSaveProject}
                                    currentProject={currentProject}
                                    setProjectName={setProjectName}
                                    projectName={projectName}
                                    userToken={userToken}
                                    renameGroup={renameGroup}
                                    deleteGroup={deleteGroup}
                                    groups={groups}
                                    currentGroupIndex={currentGroupIndex}
                                    updateCurrentGroup={updateCurrentGroup}
                                    updateCurrentGroupIndex={
                                        updateCurrentGroupIndex
                                    }
                                    preview={preview}
                                    setPreview={setPreview}
                                    shareHash={shareHash}
                                    rasterizeProject={rasterizeProject}
                                    isHelpOpen={isHelpOpen}
                                    toggleHelp={toggleHelp}
                                    projectImage={projectImage}
                                    goToURI={goToURI}
                                    setIsHelpOpen={setIsHelpOpen}
                                    defaultHelpState={defaultHelpState}
                                    setDefaultHelpState={setDefaultHelpState}
                                    setLoading={setLoading}
                                    setProgress={setProgress}
                                    isLoading={isLoading}
                                />
                            );
                        }}
                    />
                </Switch>

                <Popup />
                <SweetAlert
                    show={unsavedWarning}
                    title="Are you Sure?"
                    type="info"
                    text="Any unsaved changes will be lost if you start a new project"
                    confirmButtonColor="#0A4438"
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton
                    onEscapeKey={() => {
                        setUnsavedWarning(false);
                    }}
                    onCancel={() => {
                        setUnsavedWarning(false);
                    }}
                    onConfirm={() => {
                        resetTutorialState();
                        setUnsavedWarning(false);
                        setWorkingImage(false);
                        updateCurrentShapeIndex(-1);
                        setGroups(defaultGroups);
                        setShapes(defaultShapes);
                        goToURI("/new");
                    }}
                />
                {pathname === "/new" && !isHelpOpen && (
                    <SweetAlert
                        show={showWelcome && !shownWelcome}
                        title="First Time Here?"
                        type="info"
                        text="Would you like to turn on Help Notifications for step-by-step instructions for using our visualizer?"
                        confirmButtonColor="#0A4438"
                        confirmButtonText="Yes"
                        cancelButtonText="No"
                        showCancelButton
                        onEscapeKey={cancelWelcome}
                        onCancel={cancelWelcome}
                        onConfirm={() => {
                            setShowWelcome(false);
                            setDefaultHelpState(true);
                            setShownWelcome(true);
                            setIsHelpOpen(true);
                        }}
                    />
                )}
                {!userToken && (
                    <ReactModal
                        onRequestClose={() => {
                            if (sentPassword) {
                                setSentPassword(false);
                            }
                            setLoginOpen(false);
                        }}
                        isOpen={isLoginOpen}
                        style={modalStyles}
                    >
                        <Login
                            setUserToken={setUserToken}
                            userToken={userToken}
                            handleForgotPassword={handleForgotPassword}
                            forgotPasswordSent={sentPassword}
                        />
                    </ReactModal>
                )}
            </LoadingOverlay>
        </ErrorBoundary>
    );
};

export default withRouter(App);
