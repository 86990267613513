import React, { useState } from "react";
import { RadioGroup, Radio } from "react-radio-group";
import SweetAlert from "sweetalert-react";
import ReactTooltip from "react-tooltip";

const GroupList = ({ groups, selectGroup, currentGroup, renameGroup, deleteGroup, currentGroupIndex }) => {
	const [rename, setRename] = useState(false);
	const [editing, setEditing] = useState(-1);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const handleDoubleClick = groupId => {
		setRename(true);
		setEditing(groupId);
	};
	const cancelRename = () => {
		setRename(false);
	};
	const cancelDeleteGroup = () => {
		setShowConfirmDelete(false);
	};
	const groupValue = groups[editing] ? groups[editing].label : "";
	let groupCount = 0;
	groups.forEach(g => {
		if (g !== null) groupCount++;
	});
	return (
		<>
			<h2 className="big-margin-top">Groups</h2>
			<RadioGroup name="group" className="group-list" selectedValue={currentGroup} onChange={value => selectGroup(value)}>
				{groups.map(group => {
					if (!group) {
						return null;
					}

					return (
						<label className="group-list-item" htmlFor={group.value} key={`group_${group.value}`} onDoubleClick={() => handleDoubleClick(group.value)}>
							<Radio value={group.value} id={group.value} checked={group.value === currentGroupIndex} />
							<span>{group.label}</span>
							{groupCount > 1 && (
								<>
									<ReactTooltip effect="solid" className="small-tip" id={`del-group-${group.value}`} place="left" />
									<button
										data-tip="Delete Group"
										data-for={`del-group-${group.value}`}
										className="red"
										style={{ float: "right", margin: "0" }}
										onClick={e => {
											e.preventDefault();
											setEditing(group.value);
											setShowConfirmDelete(true);
										}}
									>
										<i className="fa fa-trash" />
									</button>
								</>
							)}
						</label>
					);
				})}
			</RadioGroup>
			<SweetAlert
				show={showConfirmDelete}
				title="Delete Group?"
				type="warning"
				text="Are you sure you want to delete this group and all shapes?"
				confirmButtonColor="#0A4438"
				showCancelButton
				onEscapeKey={cancelDeleteGroup}
				onOutsideClick={cancelDeleteGroup}
				onCancel={cancelDeleteGroup}
				onConfirm={() => {
					deleteGroup(editing);
					setShowConfirmDelete(false);
				}}
			/>
			<SweetAlert
				show={rename}
				title="Rename Group"
				type="input"
				inputType="text"
				confirmButtonColor="#0A4438"
				inputPlaceholder="Group Name"
				showCancelButton
				onEscapeKey={cancelRename}
				onOutsideClick={cancelRename}
				onCancel={cancelRename}
				inputValue={groupValue}
				onConfirm={groupName => {
					renameGroup(editing, groupName);
					setRename(false);
				}}
			/>
		</>
	);
};

export default GroupList;
