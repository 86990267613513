import React, { useState } from "react";
import "../styles/controls.scss";
import "react-dropdown/style.css";
import Rotate from "../icons/Rotate";
import Skew from "../icons/Skew";
import Button from "./Button";
import Range from "./Range";
import Tour from "reactour";
import { disableBody, enableBody } from "../utils";
import Joystick from "./Joystick";
import TransformPreview from "./TransformPreview";

const TransformControls = props => {
    const [initalRotation, setInitalRotation] = useState(false);
    const [advancedMode, setAdvancedMode] = useState(false);
    const updateInitalRotation = (val) => {
        if (defaultHelpState) {
            setInitalRotation(val);
            toggleHelp();
        } else {
            setInitalRotation(val);
        }
    }

    const toggleAdvancedMode = () => {
        if (defaultHelpState) {
            setAdvancedMode(!advancedMode);
            toggleHelp();
        } else {
            setAdvancedMode(!advancedMode);
        }
    }

    const {
        resetTransformMatrix,
        setChangingPerspective,
        currentShape,
        updateShapeTransform,
        updateProductRotate,
        currentGroup,
        updateCurrentShapeProp,
        isHelpOpen,
        canvasLoaded,
        toggleHelp,
        defaultHelpState
    } = props;
    const { texture } = currentGroup;
    const {
        rotateZ = 0,
        rotateX = 0,
        rotateY = 0,
        perspective = 512,
        skewX = 0,
        skewY = 0,
        transformCoords = false
    } = currentShape;
    const rotations = [0, 90, 180, 270];

    const isRoofing = texture.categoryId && texture.categoryId === 76;
    const showInitialRotation =  initalRotation === false && !isRoofing;
    const rotationExamples = rotations.map(deg => (
        <div
            key={deg}
            className="rotation-example"
            onClick={() => {
                updateInitalRotation(true);
                updateProductRotate(deg);
            }}
        >
            <div
                className="example"
                style={{
                    backgroundImage: `url(${texture.url})`,
                    transform: `rotateZ(${deg}deg)`,
                    backgroundColor: texture.textureColor
                }}
            />
        </div>
    ));

    const formatDegrees = value => value + " °";
    const formatPerspectiveDistance = value =>
        Math.round((value / 1200) * 100) + " %";
    const degreeLabels = {
        "90": <i className="fa fa-rotate-right"></i>,
        "-90": <i className="fa fa-rotate-left"></i>
    };
    const perspectiveLabels = {
        "1200": <i className="fa fa-plus"></i>,
        "120": <i className="fa fa-minus"></i>
    };

    const rotateXLabels = {
        "-90": <Rotate style={{ transform: "rotate(90deg)" }} />,
        "90": <Rotate style={{ transform: "rotate(-90deg)" }} />
    };
    const rotateYLabels = {
        "-90": <Rotate style={{ transform: "scale(-1)" }} />,
        "90": <Rotate />
    };
    const skewYLabels = {
        "-90": <Skew style={{ transform: "rotate(90deg)" }} />,
        "90": <Skew style={{ transform: "rotate(-90deg) scaleX(-1)" }} />
    };
    const skewXLabels = {
        "-90": <Skew style={{ transform: "scale(-1)" }} />,
        "90": <Skew style={{ transform: "scaleY(-1)" }} />
    };

    const advancedModeHelp = [
        {
            selector: ".sidebar",
            content: () => (
                <div>
                    <h5>Tutorial / Setting Perspective</h5>
                    <h3>Set Perspective</h3>
                    <hr />
                    <p>
                        Using the sliders you can fine tune your shapes appearance.
                    </p>
                </div>
            ),
            action: node => {
                node.focus();
            },
            stepInteraction: false
        },
        {
            selector: "h3.center",
            content: () => (
                <div>
                    <h5>Tutorial / Setting Perspective</h5>
                    <h3>Set Perspective</h3>
                    <hr />
                    <p>
                        Too much control? You can toggle <b>Basic</b> for less options.
                    </p>
                </div>
            ),
            action: node => {
                node.focus();
            },
            stepInteraction: false
        },
        {
            selector: ".done",
            content: c => (
                <div>
                    <h5>Tutorial / Setting Perspective</h5>
                    <h3>Set Perspective</h3>
                    <hr />
                    <p>
                        Click <b>Done</b> when you have the perspective
                        complete.
                    </p>
                    <div className="center">
                        <Button
                            className="green small"
                            label="Let's Start!"
                            onClick={c.close}
                        />
                    </div>
                </div>
            ),
            action: node => {
                node.focus();
            },
            stepInteraction: false
        }
    ]
    const transformHelp = [
        {
            selector: "#shape-wrapper",
            content: () => (
                <div>
                    <h5>Tutorial / Setting Perspective</h5>
                    <h3>Set Perspective</h3>
                    <hr />
                    <p>
                        To provide a more accurate depiction of our profiles you
                        can edit the perspective for each shape as it appears on
                        the canvas.
                    </p>
                    <p>
                        This will help to articulate the angle of the profiles
                        as they appear on the roof or siding.
                    </p>
                </div>
            ),
            position: "top",
            action: node => {
                node.focus();
            },
            stepInteraction: false
        },
        {
            selector: ".joystick-base",
            content: () => (
                <div>
                    <h5>Tutorial / Setting Perspective</h5>
                    <h3>Set Perspective</h3>
                    <hr />
                    <p>
                        By moving the Joystick you can adjust how the product tilts relative to how it will be installed.
                    </p>
                </div>
            ),
            action: node => {
                node.focus();
            },
            stepInteraction: false
        },
        {
            selector: ".transform-preview-wrapper",
            content: () => (
                <div>
                    <h5>Tutorial / Setting Perspective</h5>
                    <h3>Set Perspective</h3>
                    <hr />
                    <p>
                        As you adjust the tilt you will see an approximate preview of the transformation being applied.
                    </p>
                </div>
            ),
            action: node => {
                node.focus();
            },
            stepInteraction: false
        },
        {
            selector: ".rotate",
            content: () => (
                <div>
                    <h5>Tutorial / Setting Perspective</h5>
                    <h3>Set Perspective</h3>
                    <hr />
                    <p>
                        Using this slider you can rotate the angle the product is installed.
                    </p>
                </div>
            ),
            action: node => {
                node.focus();
            },
            stepInteraction: false
        },
        {
            selector: ".distance",
            content: () => (
                <div>
                    <h5>Tutorial / Setting Perspective</h5>
                    <h3>Set Perspective</h3>
                    <hr />
                    <p>
                        Using this slider you can adjust the object's distance.
                    </p>
                </div>
            ),
            action: node => {
                node.focus();
            },
            stepInteraction: false
        },
        {
            selector: "h3.center",
            content: () => (
                <div>
                    <h5>Tutorial / Setting Perspective</h5>
                    <h3>Set Perspective</h3>
                    <hr />
                    <p>
                        You can fine tune your transformations by toggling <b>Advanced</b>.
                    </p>
                </div>
            ),
            action: node => {
                node.focus();
            },
            stepInteraction: false
        },
        {
            selector: ".done",
            content: c => (
                <div>
                    <h5>Tutorial / Setting Perspective</h5>
                    <h3>Set Perspective</h3>
                    <hr />
                    <p>
                        Click <b>Done</b> when you have the perspective
                        complete.
                    </p>
                    <div className="center">
                        <Button
                            className="green small"
                            label="Let's Start!"
                            onClick={c.close}
                        />
                    </div>
                </div>
            ),
            action: node => {
                node.focus();
            },
            stepInteraction: false
        }
    ];
    return (
        <section id="controls">
            <h1 className="center">Perspective</h1>
            {!showInitialRotation && !advancedMode && (
                <>
                    <TransformPreview currentShape={currentShape} />
                    <span className="center">Tilt</span>
                    <Joystick
                        move={updateShapeTransform}
                        coordinates={transformCoords}
                    />
                    <Range
                        title="Rotate"
                        className="rotate"
                        min={-90}
                        max={90}
                        value={rotateZ}
                        format={formatDegrees}
                        labels={degreeLabels}
                        onChange={val => {
                            updateCurrentShapeProp("rotateZ", val);
                        }}
                    />
                    <Range
                        title="Object Distance"
                        min={120}
                        max={1200}
                        className="distance"
                        value={perspective}
                        labels={perspectiveLabels}
                        format={formatPerspectiveDistance}
                        onChange={val => {
                            updateCurrentShapeProp("perspective", val);
                        }}
                    />
                    <h3
                        className="center"
                        style={{ margin: "1em", cursor: "pointer" }}
                        onClick={toggleAdvancedMode}
                    >
                        <i className="fa fa-gear"></i> Advanced
                    </h3>
                </>
            )}

            {!showInitialRotation && advancedMode && (
                <>
                    <TransformPreview
                        rotateX={rotateX}
                        rotateY={rotateY}
                        rotateZ={rotateZ}
                        perspective={perspective}
                        currentShape={currentShape}
                    />
                    <Range
                        title="Y-Axis"
                        min={-70}
                        max={70}
                        labels={rotateXLabels}
                        format={formatDegrees}
                        value={rotateX}
                        onChange={val => {
                            updateCurrentShapeProp("rotateX", val);
                        }}
                    />
                    <Range
                        title="X-Axis"
                        min={-70}
                        max={70}
                        labels={rotateYLabels}
                        format={formatDegrees}
                        value={rotateY}
                        onChange={val => {
                            updateCurrentShapeProp("rotateY", val);
                        }}
                    />
                    <Range
                        title="Z-Axis"
                        min={-90}
                        max={90}
                        labels={degreeLabels}
                        format={formatDegrees}
                        value={rotateZ}
                        onChange={val => {
                            updateCurrentShapeProp("rotateZ", val);
                        }}
                    />
                    <Range
                        title="Object Distance"
                        min={124}
                        max={1024}
                        value={perspective}
                        labels={perspectiveLabels}
                        format={formatPerspectiveDistance}
                        onChange={val => {
                            updateCurrentShapeProp("perspective", val);
                        }}
                    />
                    <Range
                        title="Skew X-Axis"
                        min={-90}
                        max={90}
                        labels={skewXLabels}
                        format={formatDegrees}
                        value={skewX}
                        onChange={val => {
                            updateCurrentShapeProp("skewX", val);
                        }}
                    />
                    <Range
                        title="Skew Y-Axis"
                        min={-90}
                        max={90}
                        labels={skewYLabels}
                        format={formatDegrees}
                        value={skewY}
                        onChange={val => {
                            updateCurrentShapeProp("skewY", val);
                        }}
                    />
                    <h3
                        className="center"
                        style={{ margin: "1em", cursor: "pointer" }}
                        onClick={toggleAdvancedMode}
                    >
                        <i className="fa fa-gear"></i> Basic
                    </h3>
                </>
            )}

            {showInitialRotation && (
                <div>
                    <p className="center">
                        Choose the image below that best matches the direction
                        of this shape or click <b>Next</b> to set Perspective
                        manually.
                    </p>
                    {rotationExamples}
                </div>
            )}

            <div className="nav">
                <Button
                    label={showInitialRotation ? "Cancel" : "Back"}
                    icon="fa-chevron-left"
                    className="outline-green"
                    onClick={() => {
                        if (initalRotation) {
                            updateInitalRotation(false);
                        } else {
                            resetTransformMatrix();
                            setChangingPerspective(false);
                        }
                    }}
                />
                <Button
                    label={showInitialRotation ? "Next" : "Done"}
                    icon="fa-chevron-right"
                    onClick={() => {
                        if (!initalRotation && !isRoofing) {
                            updateInitalRotation(true);
                        } else {
                            setChangingPerspective(false);
                        }
                    }}
                    className="green done"
                />
                {/* 		<Button
					label="Cancel"
					onClick={() => {
						resetTransformMatrix();
						setChangingPerspective(false);
					}}
					className="outline-green cancel"
				/> */}
            </div>
            {canvasLoaded && !showInitialRotation && advancedMode && (
                <Tour
                    key="changingPerspective2"
                    closeWithMask={true}
                    steps={advancedModeHelp}
                    disableInteraction={true}
                    showNavigation={false}
                    showNavigationNumber={false}
                    isOpen={isHelpOpen}
                    onRequestClose={toggleHelp}
                    onAfterOpen={disableBody}
                    onBeforeClose={enableBody}
                    badgeContent={(curr, tot) => `${curr} of ${tot}`}
                    accentColor="#0a4436"
                    highlightedMaskClassName="tour-active"
                />
            )}
            {canvasLoaded && !showInitialRotation && !advancedMode && (
                <Tour
                    key="changingPerspective1"
                    closeWithMask={true}
                    steps={transformHelp}
                    disableInteraction={true}
                    showNavigation={false}
                    showNavigationNumber={false}
                    isOpen={isHelpOpen}
                    onRequestClose={toggleHelp}
                    onAfterOpen={disableBody}
                    onBeforeClose={enableBody}
                    badgeContent={(curr, tot) => `${curr} of ${tot}`}
                    accentColor="#0a4436"
                    highlightedMaskClassName="tour-active"
                />
            )}
        </section>
    );
};

export default TransformControls;
