import React from "react";
import Point from "./Point";
import Quadratic from "./Quadratic";

const Handles = ({ activePoint, currentShapeIndex, currentShape, setDraggedPoint, setDraggedQuadratic, zoom, isScaleStep, isAddingPoint }) => {
	if (!currentShape) return null;
	const shapeId = currentShape.id;
	return (
		<g className={isScaleStep ? "scale-path-group" : "path-group"}>
			{currentShape.points.map((p, i, a) => {
				let anchors = [];
				const active = activePoint === i;
				if (p.q) {
					anchors.push(
						<Quadratic
							key={`quad-${shapeId}-${i}`}
							index={i}
							shapeIndex={currentShapeIndex}
							active={active}
							p1x={a[i - 1].x}
							p1y={a[i - 1].y}
							p2x={p.x}
							p2y={p.y}
							x={p.q.x}
							y={p.q.y}
							setDraggedQuadratic={setDraggedQuadratic}
						/>
					);
				}
				return (
					<g key={`point-group-${shapeId}-${i}`} className={"point-group" + (active ? "  active" : "")}>
						<Point
							key={`point-${shapeId}-${i}`}
							index={i}
							shapeIndex={currentShapeIndex}
							active={active}
							x={p.x}
							y={p.y}
							setDraggedPoint={setDraggedPoint}
							zoom={zoom}
							isScaleStep={isScaleStep}
							isAddingPoint={isAddingPoint}
						/>
						{anchors}
					</g>
				);
			})}
		</g>
	);
};

export default Handles;
