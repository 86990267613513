import React, { useState, memo } from "react";
import "../styles/controls.scss";
import ProfileChoices from "./ProfileChoices";
import GroupList from "./GroupList";
import Button from "../components/Button";
import SweetAlert from "sweetalert-react";
import Fade from "react-reveal/Fade";

import "react-dropdown/style.css";

const ProductControls = memo(
	({
		updateTexture,
		selectGroup,
		renameGroup,
		deleteGroup,
		groups,
		currentGroupIndex,
		goToURI,
		defaultTexture,
		rasterize,
		setProjectImage,
		artBoardRef,
		timestamp,
		groupWithError,
		productSelectionCheck,
		setPreview,
        updateProductCategory,
        setLoading,
		...props
	}) => {
		const [error, setError] = useState(false);
		//const [isLoading, setLoading] = useState(false);

		const handleNext = () => {
			if (productSelectionCheck) {
				setPreview(true);
                setLoading(true);
                const el = artBoardRef.current.querySelector(".artboard-wrapper");
				rasterize(el).then(dataURL => {
					setProjectImage(dataURL);
					setLoading(false);
					goToURI("/share");
				});
			} else {
				setError(true);
			}
		};
		return (
			<Fade duration={1200}>
				<section id="controls">
					<h1 className="center">Products</h1>
					<div className="products">
					
                    <ProfileChoices
                        updateTexture={updateTexture}
                        currentTexture={groups[currentGroupIndex].texture}
                        updateProductCategory={updateProductCategory}
                        setProductsLoaded={props.setProductsLoaded}
                    />

						<GroupList groups={groups} currentGroup={currentGroupIndex} selectGroup={selectGroup} renameGroup={renameGroup} deleteGroup={deleteGroup} />
					</div>
					<div className="nav">
						<Button label="Back" icon="fa-chevron-left" className="outline-green back" onClick={() => goToURI("/draw")} />
						<Button label="Next" icon="fa-chevron-right" className="green next" onClick={handleNext} />
					</div>
					<SweetAlert
						show={error}
						title="No Product Selected"
						type="warning"
						text={`You must select a product for the ${groupWithError} group before continuing.`}
						confirmButtonColor="#0A4438"
						onConfirm={() => setError(false)}
					/>
				</section>
			</Fade>
		);
	}
);

export default ProductControls;
