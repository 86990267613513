import React from "react";
import ReactTooltip from "react-tooltip";

const HelpButton = ({ isHelpOpen, setIsHelpOpen, setDefaultHelpState, defaultHelpState }) => {
	const styles = {
		active: {
			backgroundColor: "#0a4436"
		}
	};
	return (
		<div className="fab">
			<ReactTooltip effect="solid" id="fab-icon" />
			<button
				data-for="fab-icon"
				className="fab-icon"
				data-tip={defaultHelpState ? "Stop Tutorial" : "Start Tutorial"}
				onClick={() => {
                    if (isHelpOpen) {
                        setDefaultHelpState(false);
                        setIsHelpOpen(false);
                    } else {
                        setDefaultHelpState(!defaultHelpState);
                        setIsHelpOpen(!defaultHelpState);
                    }
                }}
				style={defaultHelpState ? styles.active : {}}
			>
				<i className="fa fa-question" />
			</button>
		</div>
	);
};

export default HelpButton;