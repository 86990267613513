import React from "react";

const TransformPreview = ({ currentShape }) => {
    const { rotateZ = 0, rotateX = 0, rotateY = 0, perspective = 512, skewX = 0, skewY = 0 } = currentShape;
	return (
		<div className="transform-preview-wrapper">
			<div
				className="transform-preview after"
				style={{
					transform: `perspective(${perspective}px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg) skewX(${skewX}deg) skewY(${skewY}deg)`,
					borderColor: "#ff4136"
				}}
			/>
		</div>
	);
};

export default TransformPreview;
