import React from "react";

export default function Logo(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M8.474 21.557v1.416h.209v.418h-.962v-.418H8V21.48h-.279v-.416h1.082c.097 0 .13.022.15.125l.332 1.477h.04l.329-1.477c.02-.103.053-.125.149-.125h1.065v.416h-.278v1.492h.278v.418H9.865v-.418h.207v-1.416h-.047l-.429 1.714c-.023.097-.049.121-.139.121h-.369c-.086 0-.116-.024-.14-.121l-.428-1.713h-.046zm2.839-.077v-.416h1.945v.776h-.464v-.361h-.684v.525h.554v.391h-.554v.58h.7v-.405h.465v.821h-1.962v-.418h.278V21.48zm4.073 1.493v.418h-1.115v-.418h.299v-1.498h-.379v.441h-.439v-.852h2.152v.852h-.438v-.441h-.379v1.498zm.667.418v-.418h.226l.573-1.75c.043-.129.09-.166.217-.166h.377c.13 0 .177.037.22.166l.569 1.75h.223v.418h-1.049v-.412h.267l-.09-.307h-.701l-.086.307h.267v.412h-1.013zm.94-1.114h.481l-.22-.758h-.044l-.217.758zm2.121-.797h-.278v-.416h1.092v.416h-.295v1.495h.631v-.422h.465v.838h-1.893v-.418h.278zm2.607.653c-.074-.074-.104-.162-.104-.494 0-.336.03-.424.104-.498.045-.053.107-.07.223-.07h.371c.121 0 .166.033.201.066.055.053.074.15.074.35h-.291c-.001-.106-.008-.137-.02-.151-.016-.015-.029-.021-.075-.021h-.17c-.054 0-.071.006-.084.023-.019.014-.025.057-.025.301 0 .24.009.281.025.299.013.015.03.021.084.021h.173c.045 0 .062-.006.075-.021.016-.016.022-.053.022-.166h.287c0 .222-.02.312-.074.365-.033.033-.084.066-.201.066h-.373c-.115 0-.173-.017-.222-.07m1.172 0c-.074-.074-.104-.176-.104-.494 0-.324.029-.424.104-.498.047-.053.108-.07.225-.07h.367c.115 0 .178.019.229.07.073.074.1.174.1.498 0 .318-.025.42-.1.494-.051.053-.111.07-.229.07h-.367c-.117 0-.177-.017-.225-.07m.231-.195c.013.015.03.021.084.021h.188c.054 0 .069-.006.082-.021.016-.018.025-.059.025-.299 0-.244-.011-.287-.025-.301-.013-.018-.028-.023-.082-.023h-.188c-.054 0-.071.006-.084.023-.019.014-.026.057-.026.301 0 .24.007.281.026.299m.837-.063h.316v.328h-.316zm-2.318.508h2.19v.266h-2.19zM8.417 17.23h.811l-.402-3.264zm12.167-3.795h-.205c-.261 0-.383.121-.383.382v4.123c0 .26.122.381.383.381h.205c.252 0 .372-.121.372-.381v-4.123c0-.261-.12-.382-.372-.382" />
      <path d="M30.055 15.877l1.475-4.278H3.153V8.082L16 4.614l12.846 3.468v2.869h1.168V7.189l-.864-.232-12.846-3.47L16 3.406l-.303.082L2.85 6.957l-.862.232V11.6H.47l1.474 4.278L.47 20.156h28.376v3.762L16 27.385 3.153 23.918v-3.113H1.988v4.004l.862.232 12.847 3.469.303.084.304-.084 12.844-3.469.863-.232v-4.653h1.518l-1.474-4.279zM6.85 19.141h-.998v-2.872h-.96v2.872h-.998v-6.526h.998v2.751h.96v-2.751h.998v6.526zm2.62 0l-.131-1.024H8.304l-.121 1.024H7.26l.933-6.536h1.324l.941 6.536H9.47zm2.927.009h-1.221l-.96-6.538h1.007l.597 4.812.597-4.812h.942l-.962 6.538zm3.609-.009h-2.237v-6.526h2.219v.905h-1.222v1.846h1.175v.904h-1.175v1.967h1.24v.904zm2.63 0h-2.097v-6.526h.997v5.622h1.1v.904zm3.318-1.155c0 .811-.399 1.211-1.211 1.211h-.532c-.811 0-1.211-.4-1.211-1.211V13.77c0-.811.4-1.212 1.211-1.212h.532c.812 0 1.211.401 1.211 1.212v4.216zm3.48 0c0 .811-.399 1.211-1.213 1.211h-.494c-.811 0-1.211-.4-1.211-1.211V13.77c0-.811.4-1.212 1.211-1.212h.484c.812 0 1.203.391 1.203 1.202v1.334h-.979v-1.277c0-.262-.121-.382-.373-.382h-.168c-.261 0-.383.121-.383.382v4.123c0 .26.122.381.383.381h.187c.252 0 .374-.121.374-.381v-1.474h.979v1.52zm2.339 1.155l-.971-3.198v3.198h-.996v-6.526h.996v3.152l1.034-3.152h1.009v.107l-1.082 3.101 1.082 3.319h-1.072z" />
      <path d="M22.732 9.303l-6.459-1.745L16 7.485l-.273.073-6.46 1.745v.342h2.199v1.184h1.099V9.497L16 8.569l3.434.928v1.332h1.101V9.645h2.197z" />
      <path fill="none" d="M.47 3.406h31.059v25.188H.47z" />
    </svg>
  );
}
