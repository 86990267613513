import React from "react";

export default function Hammers(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M20.836 19.529l-2.551 2.604 7.678 7.842a.247.247 0 0 0 .068.055c.01.008.057-.025.084-.055l1.248-1.244c.023-.027.07-.07.096-.098l1.246-1.244a.367.367 0 0 0 .055-.068c.006-.012-.029-.061-.057-.086l-7.867-7.706z" />
      <path d="M25.516 12.854a.07.07 0 0 1 .098 0l5.471 5.47a.282.282 0 0 0 .07.055c.01.006.033-.037.035-.076L29.518 8.85a.142.142 0 0 1 .035-.114l.287-.295c.027-.025.053-.057.053-.068.008-.012-.025-.059-.053-.084l-2.156-2.155a.257.257 0 0 0-.07-.057c-.01-.005-.055.029-.082.056l-.295.286a.067.067 0 0 1-.096 0l-4.393-4.393a.356.356 0 0 0-.07-.056c-.01-.005-.059.03-.084.055l-3.828 3.829a.431.431 0 0 0-.057.068c-.004.012.029.058.057.084l4.352 4.353a.068.068 0 0 1 0 .097L17.4 16.061a.074.074 0 0 1-.1 0l-5.719-5.604a.068.068 0 0 1 0-.097l4.241-4.24a.369.369 0 0 0 .056-.069c.004-.01-.03-.057-.056-.084l-3.829-3.828a.257.257 0 0 0-.068-.056c-.012-.006-.059.029-.086.056L7.56 6.419a.07.07 0 0 1-.098 0l-.292-.287a.46.46 0 0 0-.067-.055c-.015-.005-.06.029-.087.057L4.861 8.289a.335.335 0 0 0-.056.067c-.003.011.03.059.057.084l.285.294c.027.026.025.07 0 .097l-4.281 4.28a.34.34 0 0 0-.055.07c-.006.01.028.058.055.083l3.83 3.83a.29.29 0 0 0 .067.055c.011.006.06-.027.087-.055l4.24-4.24a.065.065 0 0 1 .094 0l5.58 5.691a.072.072 0 0 1-.002.1l-8.764 8.59a.247.247 0 0 0-.057.07c-.007.008.027.057.054.084l1.247 1.244.098.098 1.244 1.244a.22.22 0 0 0 .068.055c.013.008.06-.025.086-.055l8.562-8.738 8.216-8.383z" />
    </svg>
  );
}
