import React from "react";
import ReactTooltip from "react-tooltip";

const Button = props => {
	const { loading, loadingText, label, tip, icon, SVGIcon, ..._props } = props;
	if (loading) {
		return (
			<button {..._props}>
				<i className="fa fa-circle-o-notch fa-spin" /> {loadingText || "Saving..."}
			</button>
		);
	} else if (tip) {
		let id = generateUID();
		return (
			<>
				<button data-tip={tip} data-for={id} {..._props}>
					{SVGIcon && (
						<div className="svgicon">
							<SVGIcon />
						</div>
					)}
					{icon && !icon.includes("fa-chevron-right") && <i className={`fa ${icon}`} />} {label}{" "}
					{icon && icon.includes("fa-chevron-right") && <i className={`fa ${icon}`} />}
				</button>
				<ReactTooltip id={id} effect="solid" />
			</>
		);
	} else {
		return (
			<button {..._props}>
				{SVGIcon && (
					<div className="svgicon">
						<SVGIcon />
					</div>
				)}
				{icon && !icon.includes("fa-chevron-right") && <i className={`fa ${icon}`} />} {label}{" "}
				{icon && icon.includes("fa-chevron-right") && <i className={`fa ${icon}`} />}
			</button>
		);
	}
};

export default Button;

function generateUID() {
	var firstPart = (Math.random() * 46656) | 0;
	var secondPart = (Math.random() * 46656) | 0;
	firstPart = ("000" + firstPart.toString(36)).slice(-3);
	secondPart = ("000" + secondPart.toString(36)).slice(-3);
	return firstPart + secondPart;
}
