import React, { Component } from "react";
import Tour from "reactour";
import ReactCrop from "react-image-crop";
import SweetAlert from "sweetalert-react";
import Fade from "react-reveal/Fade";
import Button from "../components/Button";
import HelpButton from "../components/HelpButton";
import VideoPlayer from "../components/VideoPlayer";
import "react-image-crop/dist/ReactCrop.css";
import "../styles/crop.scss";
import imageDemo from "../img/home.jpg";
import cropDemo from "../videos/crop-demo2.mp4";
import defaultTexture from "../textures/bg.svg";
import { disableBody, enableBody } from "../utils";

class Crop extends Component {
	state = {
		src: this.props.workingImage || null,
		loading: false,
		isChanged: false,
		usingDemo: false,
		tooSmallError: false,
		crop: {
			aspect: 4 / 3,
			height: 768,
			x: 0,
			y: 0
		}
	};

	componentWillReceiveProps(props) {
		if (props.isHelpOpen) {
			const { src } = this.state;
			if (!src) {
				this.setState({
					src: imageDemo,
					usingDemo: true
				});
			}
		}
	}

	handleUpload() {
		const apiBase = "https://www.havelockmetal.com/";
		const setState = this.setState.bind(this);
		const { state, props } = this;

		setState({
			loading: true
		});
		const xhr = new XMLHttpRequest();

		xhr.open("GET", state.croppedImageUrl, true);
		xhr.responseType = "blob";
		xhr.onload = e => {
			const reader = new FileReader();
			reader.readAsDataURL(e.target.response);
			reader.onloadend = () => {
				const imageData = reader.result;
				const body = {
					token: props.userToken.token,
					image: imageData,
					mimeType: "image/jpeg"
				};
				fetch(apiBase + "visualizer-api?action=uploadImage", {
					method: "post",
					body: JSON.stringify(body)
				})
					.then(response => response.json())
					.then(data => {
						setState(
							{
								loading: false
							},
							() => {
								props.updateWorkingImage(data.url);
								props.goToURI("/scale");
							}
						);
					});
			};
		};
		xhr.send();
	}

	onSelectFile = e => {
		const setState = this.setState.bind(this);
		const makeClientCrop = this.makeClientCrop.bind(this);
		const { props, state } = this;

		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const img = new Image();
				img.src = reader.result;
				img.onload = function() {
					// access image size here
					if (this.width < 1024 || this.height < 768) {
						setState({ ...state, tooSmallError: true });
					} else {
						setState({ ...state, src: reader.result, isChanged: true }, () => {
							makeClientCrop(state.crop);
							props.setCurrentProject(0);
						});
					}
				};
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	loadDemoContent = cb => {
		this.setState(
			{
				src: imageDemo,
				croppedImageUrl: null,
				usingDemo: true
			},
			cb
		);
	};
	toggleHelp = () => {
		const { isHelpOpen, workingImage } = this.props;
		const { croppedImageUrl, usingDemo } = this.state;
		if (isHelpOpen) {
			this.props.toggleHelp();
			if (this.state.usingDemo) {
				this.setState({
					src: this.props.workingImage,
					croppedImageUrl: null
				});
			}
		} else if (!croppedImageUrl) {
			this.loadDemoContent(() => {
				this.props.toggleHelp();
			});
		} else if (usingDemo) {
			this.setState({
				src: workingImage || null,
				croppedImageUrl: null
			});
		} else {
			this.props.toggleHelp();
		}
	};

	onCropChange = crop => {
		this.setState({ crop });
	};
	onImageLoaded = image => {
		const { width } = image;
		let { crop } = this.state;
		this.setState(
			{
				crop: {
					...crop,
					y: width
				},
				imageRef: image
			},
			() => {
				this.makeClientCrop(crop);
			}
		);
	};

	onCropComplete = crop => {
		this.makeClientCrop(crop);
	};

	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;

		canvas.width = 1024;
		canvas.height = 768;

		const dScaleX = canvas.width / crop.width;
		const dScaleY = canvas.height / crop.height;

		const ctx = canvas.getContext("2d");

		ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width * dScaleX, crop.height * dScaleY);

		return new Promise(resolve => {
			canvas.toBlob(blob => {
				blob.name = fileName; // eslint-disable-line no-param-reassign
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, "image/jpeg");
		});
	}

	makeClientCrop() {
		const { crop, imageRef } = this.state;
		if (imageRef) {
			this.getCroppedImg(imageRef, crop, "newFile.jpeg").then(croppedImageUrl => {
				return this.setState({ croppedImageUrl });
			});
		}
	}

	render() {
		const { crop, croppedImageUrl, src, loading, isChanged } = this.state;

		const helpStep = [
			{
				selector: ".fab",
				content: () => (
					<div>
						<h5>Tutorial / Getting Help</h5>
						<h3>Finding Help</h3>
						<hr />
						<p>
							Use the <b>?</b> Button to toggle tutorial at anytime.
						</p>
					</div>
				),
				position: "top",
				action: node => {
					node.focus();
				},

				stepInteraction: false
			}
		];
		const cropSteps = [
			{
				selector: ".file",
				content: () => (
					<div>
						<h5>Tutorial / Select Your Image</h5>
						<h3>Upload Image</h3>
						<hr />
						<p>Use the file upload field and choose an image that you would like to use.</p>
					</div>
				),
				position: "top",
				action: node => {
					node.focus();
				},
				stepInteraction: false
			},
			{
				selector: ".ReactCrop",
				content: () => (
					<div>
						<h5>Tutorial / Select Your Image</h5>
						<h3>Crop Your Image</h3>
						<hr />
						<p>Once the file has uploaded you will need to crop your image so that the roofing and siding is clearly visible on the building.</p>
						<p>Click and drag the guide so that the highlighted area is within the centre area.</p>
					</div>
				),
				action: node => {
					node.focus();
				}
			},
			{
				selector: ".ReactCrop__crop-selection",
				content: () => (
					<div>
						<h5>Tutorial / Select Your Image</h5>
						<h3>Crop Your Image</h3>
						<hr />
						<p>Click and drag each corner point to so that the entire building is within view of the highlighted area.</p>
						<VideoPlayer src={cropDemo} />
					</div>
				),
				action: node => {
					node.focus();
				}
			},
			{
				selector: "button.next",
				content: c => (
					<div>
						<h5>Tutorial / Select Your Image</h5>
						<h3>Finish</h3>
						<hr />
						<p>
							Once you are happy with your selection you can click the <b>Next</b> button to move to the next step.
						</p>
						<div className="center">
							<Button className="green small" label="Let's Start!" onClick={c.close} />
						</div>
					</div>
				),
				action: node => {
					if (node) {
						node.focus();
					}
				}
			}
        ];

		return (
			<section id="crop">
				<div className="cropper" style={{ backgroundImage: `url(${defaultTexture}` }}>
					{src && (
						<Fade delay={300} duration={1200}>
							<ReactCrop
								src={src}
								crop={crop}
								onImageLoaded={this.onImageLoaded}
								onComplete={this.onCropComplete}
								onChange={this.onCropChange}
								crossorigin="anonymous"
							/>
						</Fade>
					)}
				</div>

				<div className="sidebar">
					<Fade duration={1200}>
						<section id="controls">
							<h1 className="center">Crop</h1>

							<br />
							<div className="actions">
								<label className="file">
									<input type="file" id="file" onChange={this.onSelectFile.bind(this)} aria-label="Select an Image" />
									<span className="file-custom" />
								</label>
								<p style={{ color: "rgba(0, 0, 0, 0.43)", marginTop: 0 }}>Image must be at least 1024x768 px</p>
								{croppedImageUrl && <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />}
							</div>
							<div className="nav">
								<Button className="outline-green" label="Back" icon="fa-chevron-left" onClick={() => this.props.goToURI("/")} />
								{croppedImageUrl && isChanged && (
									<Button loading={loading} className="green" label="Next" icon="fa-chevron-right" onClick={this.handleUpload.bind(this)} />
								)}
								{croppedImageUrl && !isChanged && (
									<Button
										className="green next"
										label="Next"
										icon="fa-chevron-right"
										onClick={() => {
											this.props.goToURI("/scale");
										}}
									/>
								)}
							</div>
						</section>
					</Fade>
				</div>

				<Tour
					closeWithMask={true}
					steps={cropSteps}
					isOpen={this.props.isHelpOpen}
					onRequestClose={this.toggleHelp}
					onAfterOpen={() => {
						if (!this.state.croppedImageUrl) {
							this.loadDemoContent(disableBody);
						} else {
							disableBody();
						}
					}}
					onBeforeClose={enableBody}
					badgeContent={(curr, tot) => `${curr} of ${tot}`}
					accentColor="#0a4436"
					disableInteraction={true}
					showNavigation={false}
					showNavigationNumber={false}
				/>
				<Tour
					closeWithMask={true}
					steps={helpStep}
					isOpen={this.props.showHelpButton && !this.props.isHelpOpen}
					onRequestClose={this.props.setShowHelpButtonFalse}
					showNavigation={false}
					showNavigationNumber={false}
					showButtons={false}
					onAfterOpen={disableBody}
					onBeforeClose={enableBody}
					accentColor="#0a4436"
					disableInteraction={true}
					badgeContent={() => "HELP"}
				/>
				<HelpButton helpOnly={true} isHelpOpen={this.props.isHelpOpen} toggleHelp={this.toggleHelp} setIsHelpOpen={this.props.setIsHelpOpen} defaultHelpState={this.props.defaultHelpState} setDefaultHelpState={this.props.setDefaultHelpState} />
				<SweetAlert
					show={this.state.tooSmallError}
					title="Image too Small"
					type="warning"
					text="You must select an image at least 1024x768."
					confirmButtonColor="#0A4438"
					onConfirm={() => this.setState({ ...this.state, tooSmallError: false })}
				/>
			</section>
		);
	}
}

export default Crop;
