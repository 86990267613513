import React from "react";

import "./styles/notsupported.scss";
import Caution from "./icons/Caution";

function DeviceNotSupported() {
	return (
		<div className="aligner">
			<div className="aligner-item">
				<Caution width="72px" />
				<h1>Device Not Supported</h1>
				<p>
					Sorry, the HMC Visualizer doesn't yet support this device. Give it a try on a desktop computer with a modern browser like{" "}
					<a href="https://www.google.com/chrome/">Google Chrome</a>, <a href="https://www.mozilla.org/en-CA/firefox/">Firefox</a> or{" "}
					<a href="https://www.microsoftedgeinsider.com/en-us/">Edge</a> instead.
				</p>
			</div>
		</div>
	);
}

export default DeviceNotSupported;
