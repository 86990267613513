import React from "react";
import Img from "react-image";
import "../styles/products.scss";
import ColorChoices from "./ColorChoices.jsx";
import ProductImageSlider from "./ProductImageSlider";
import Loader from "./Loader";
import ReactTooltip from "react-tooltip";

const ProductChoices = ({ product_taxonomy, updateTexture, productId, colorId, products, categoryName, currentTexture }) => {
	function handleProductSelection(productId, url, size, productName) {
		const currentProduct = products.filter(el => el.post_id === productId);

		let { colorId, textureColor } = currentTexture;

		if (!currentProduct[0].post_fields.product_colours.includes(currentTexture.colorId)) {
			colorId = currentProduct[0].post_fields.product_colours[0];
			const currentColor = hmc_product_colour.filter(el => el.term_id === colorId);
			textureColor = currentColor[0].term_fields.colour_code;
		}

		updateTexture({
			...currentTexture,
			colorId: colorId,
			textureColor: textureColor,
			url: url,
			size: size,
			productName: productName,
			productId: productId,
			isProductSet: true
		});
	}

	//const [activeProduct, updateActiveProduct] = useState(productId);
	const { hmc_product_colour } = product_taxonomy;

	const findIndex = term_id => {
		for (let i = 0; i < hmc_product_colour.length; i++) {
			if (parseInt(hmc_product_colour[i].term_id) === parseInt(term_id)) {
				return i;
			}
		}
	};
	const updateTextureColor = (colour_code, term_id, term_name) => {
		//setCurrentColorId(term_id);
		updateTexture({
			...currentTexture,
			textureColor: colour_code,
			colorId: term_id,
			colorName: term_name
		});
	};

	const productsChoices = products.map(post => {
		const { post_title, post_id, post_fields, post_terms } = post;
		const permalink = `https://www.havelockmetal.com/index.php?page_id=${post_id}`;
		const { product_main_image, product_pattern_image, product_pattern_size, product_colour_images } = post_fields;
		const image = product_main_image.sizes["card-600"];
		let images = [{ src: image, term_id: 0 }];
		let selectedProduct = productId === post_id;
		if (selectedProduct) {
			const { hmc_product_colour: availableColors } = post_terms;
			images = availableColors.map(c => {
				const colorIndex = findIndex(c);
				const colorImage = product_colour_images["product_colour_term_" + c];
				if (colorIndex && colorImage) {
					const colorData = hmc_product_colour[colorIndex];
					if (colorData && colorData.term_fields.colour_code) {
						return {
							src: colorImage.sizes["card-600"],
							term_id: colorData.term_id,
							colour_code: colorData.term_fields.colour_code,
							term_name: colorData.term_name
						};
					}
				}
				return undefined;
			});
		}
		images = images.filter(img => img !== undefined);
		return (
			<li
				key={`product-choices-${post_id}`}
				className={`product-wrapper ${selectedProduct ? "selected-product" : ""}`}
				onClick={
					!selectedProduct
						? () => {
								handleProductSelection(post_id, product_pattern_image.url, product_pattern_size, post_title);
						  }
						: null
				}
			>
				<div className="product-thumb" style={{ maxWidth: "171px" }}>
                    {selectedProduct && <ProductImageSlider images={images} updateTextureColor={updateTextureColor} colorId={colorId} /> }
					{!selectedProduct && <Img src={product_main_image.sizes["card-600"]} style={{ width: "100%" }} loader={<Loader />} />}
				</div>
				<ColorChoices
					key={`color-choices-${post_id}`}
					post_id={post_id}
					updateTextureColor={updateTextureColor}
					colorId={colorId}
					post_terms={post_terms}
					hmc_product_colour={hmc_product_colour}
					open={selectedProduct}
				/>
				<i
					data-tip="View Product Details"
					data-for={`product-${post_id}-details`}
					className="fa fa-external-link"
					onClick={e => {
						e.preventDefault();
						const win = window.open(permalink, "_blank");
						win.focus();
					}}
				/>
				<ReactTooltip effect="solid" id={`product-${post_id}-details`} />
				<h3 className="product-title">
					{post_title}
					<br />
					<span>Profiles / {categoryName}</span>
				</h3>
			</li>
		);
	});
	return (
		<>
			<h5 style={{ margin: "14px 0" }}>Products</h5>
			<ul className="products-wrapper">{productsChoices}</ul>
		</>
	);
};
export default ProductChoices;
