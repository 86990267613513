import React, { useState } from "react";
import "../styles/controls.scss";
import Button from "./Button";
import Input from "./Input";
import Fade from "react-reveal/Fade";

const ScaleControls = ({ updateScaleFeet, updateScaleInches, scaleInches, scaleFeet, goToURI }) => {
	const [scaleFeetVal, setScaleFeetVal] = useState(scaleFeet);
	const [scaleInchesVal, setScaleInchesVal] = useState(scaleInches);

	const [feetError, setFeetError] = useState(false);
	const [inchesError, setInchesError] = useState(false);

	return (
		<Fade duration={1200}>
			<section id="controls">
				<h1 className="center">Scale</h1>
				<p>
					Next, we need to know the size of your project. Use the shape that appears on the image to set the scale of your project. Keep in mind you only need
					to know the <b>HEIGHT</b> of the object you are using as a reference.
				</p>
				<br />
				<div className="fields">
					<Input label="Feet" onChange={e => setScaleFeetVal(e.target.value)} value={scaleFeetVal} style={feetError ? { borderColor: "red" } : null} />
					<Input label="Inches" onChange={e => setScaleInchesVal(e.target.value)} value={scaleInchesVal} style={inchesError ? { borderColor: "red" } : null} />
				</div>
				<div className="nav">
					<Button
						label="Back"
						className="outline-green"
						icon="fa-chevron-left"
						onClick={() => {
							goToURI("/new");
						}}
					/>
					<Button
						label="Next"
						icon="fa-chevron-right"
						className="green"
						onClick={() => {
							let error = false;
							if (scaleFeetVal === "" || isNaN(scaleFeetVal)) {
								error = true;
								setFeetError(true);
							}
							if (scaleInchesVal === "" || isNaN(scaleInchesVal)) {
								error = true;
								setInchesError(true);
							}
							if (!error) {
								updateScaleFeet(scaleFeetVal);
								updateScaleInches(scaleInchesVal);
								goToURI("/draw");
							}
						}}
					/>
				</div>
			</section>
		</Fade>
	);
};

export default ScaleControls;
