import React, { useEffect, useRef } from "react";
import Loader from "./Loader";
import Img from "react-image";
//import VisibilitySensor from "react-visibility-sensor";
import Slider from "react-slick";

const ProductImageSlider = ({ images, colorId, updateTextureColor }) => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		accessibility: true,
		afterChange: current => {
			if (current === 0) return;
			const nextColorId = searchForColorId(current);
			if (nextColorId === colorId) return;
			const nextSlideIndex = searchForImageIndex(nextColorId);
			const { colour_code, term_id, term_name } = images[nextSlideIndex];
			updateTextureColor(colour_code, term_id, term_name);
		}
	};
	const sliderRef = useRef(null);
	useEffect(() => {
		if (sliderRef.current && colorId > 0) {
            const { slickGoTo } = sliderRef.current;
			slickGoTo(searchForImageIndex(colorId), true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sliderRef.current, colorId, images]);

	const searchForImageIndex = term_id => {
		for (let i = 0; i < images.length; i++) {
			if (images[i].term_id === term_id) {
				return i;
			}
		}
		return 0;
	};
	const searchForColorId = index => {
		return images[index].term_id;
	};
	return (
		<Slider ref={sliderRef} {...settings}>
			{images.map(({ src, term_name }) => (
				<Img key={src} src={src} alt={term_name ? term_name : ""} crossorigin="anonymous" loader={<Loader />} />
			))}
		</Slider>
	);
};

export default ProductImageSlider;