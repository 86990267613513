import React from "react";
import { generateStaticPath } from "../utils";
import { pathDataToPolys } from "../utils/path-to-polygon";

const RenderedShape = ({ shape, textureColor, textureURL, textureSize, categoryId, rotate, image, w, h, scaleTotal, scaleShapeHeight, setDims, dims }) => {


	let polygon = "";
	const cutElements = shape.hasOwnProperty("cutShapes")
		? shape.cutShapes.map((shape, i) => {
                let polygon = "";
                if (!shape.points.length) return null;
				const pathData = generateStaticPath(shape.points);
				const points = pathDataToPolys(pathData);
				for (let point of points[0]) {
					polygon += ` ${(point[0] / w) * 100}% ${(point[1] / h) * 100}%, `;
				}
				polygon = polygon.replace(/,\s*$/, "");
				return (
					<div
						className="cut-path"
						key={`cut_path_${i}`}
						style={{
							clipPath: `polygon(${polygon})`,
							backgroundImage: "url(" + image + ")",
							perspective: "800px",
							backgroundPosition: "center",
							backgroundSize: "contain",
							backgroundRepeat: "no-repeat",
							top: 0,
							left: "auto"
						}}
					/>
				);
		  })
		: null;

	const pathData = generateStaticPath(shape.points);

	const points = pathDataToPolys(pathData);

	for (let point of points[0]) {
		polygon += ` ${(point[0] / w) * 100}% ${(point[1] / h) * 100}%, `;
	}
	polygon = polygon.replace(/,\s*$/, "");
	const ppi = scaleShapeHeight / scaleTotal;
	const texturePPI = 400 / textureSize;
	const scaleTexture = ppi / texturePPI;
	const { center = { x: 50, y: 50 }, rotateX = 0, rotateY = 0, rotateZ = 0, perspective = 512, skewX = 0, skewY = 0 } = shape;

    const isRoofing = categoryId && categoryId === 76;

	return (
		<>
			<div
				className="shape-path"
				style={{
					clipPath: `polygon(${polygon})`,
					WebkitClipPath: `polygon(${polygon})`,
					opacity: 1
				}}
			>
				<div
					className="texture-wrapper"
					style={{
						transform: `translate(-50%, -50%) perspective(${perspective}px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg) skewX(${skewX}deg) skewY(${skewY}deg)`,
						left: `${center.x}%`,
						top: `${center.y}%`
					}}
				>
					<div
						className="product-texture"
						style={{
							transform: !isRoofing ? `rotate(${rotate}deg)` : ``,
							backgroundColor: textureColor,
							backgroundImage: `url("${textureURL}")`,
							backgroundSize: `${parseInt(scaleTexture * 400)}px`
						}}
					/>
				</div>
			</div>
			{cutElements}
           
		</>
	);
};

export default RenderedShape;
