import React from "react";

export default function Rotate(props) {
	return (
		<svg viewBox="0 0 24 24" {...props}>
			<path d="M10 14l4 4-4 4zm1-4h2v2h-2zm0-4h2v2h-2zm0-4h2v2h-2z" />
			<path d="M12 17c-4.411 0-8-2.468-8-5.5 0-2.303 2.072-4.277 5-5.095V4.346c-4.052.958-7 3.802-7 7.154C2 15.636 6.486 19 12 19v-2zm3-12.654v2.059c2.928.818 5 2.792 5 5.095 0 1.781-1.284 3.465-3.436 4.505l.871 1.801C20.294 16.424 22 14.066 22 11.5c0-3.352-2.948-6.196-7-7.154z" />
		</svg>
	);
}
