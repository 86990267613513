import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
//import { Switch, Route } from "react-router-dom";
import Tour from "reactour";
import Button from "../components/Button";
import Logo from "../icons/Logo";
import domtoimage from "dom-to-image-more";
import Fade from "react-reveal/Fade";
import RenderedShape from "../components/RenderedShape";
import ProductControls from "../components/ProductControls";
import HelpButton from "../components/HelpButton";
import { disableBody, enableBody, debounce } from "../utils";
import "../styles/products.scss";

const Products = ({
    image,
    currentShapes,
    w,
    h,
    updateTexture,
    updateTextureColor,
    ppi,
    saveProjects,
    isSaving,
    currentProject,
    projectName,
    setProjectName,
    loggedIn,
    groups,
    currentGroupIndex,
    updateCurrentGroupIndex,
    deleteGroup,
    renameGroup,
    defaultTexture,
    preview,
    setPreview,
    shareHash,
    productId,
    colorId,
    rasterizeProject,
    setProjectImage,
    scaleTotal,
    scaleShapeHeight,
    goToURI,
    updateProductCategory,
    setLoading,
    ...props
}) => {
    const ref = useRef(false);
    const [dims, setDims] = useState(false);
    const [productsLoaded, setProductsLoaded] = useState(false);

    useEffect(() => {
        if (!image) goToURI("/new", false);
        let error = true;
        currentShapes.forEach(s => {
            if (typeof s !== "undefined" && s !== null && s.points.length)
                error = false;
        });
        if (error) goToURI("/draw", false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image, currentShapes.length]);

    useEffect(() => {
        if (!groups[currentGroupIndex].texture.isProductSet) {
            updateTexture({
                ...groups[currentGroupIndex].texture,
                isProductSet: true
            });
        }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            if (ref.current) {
                let box = ref.current
                    .querySelector(".artboard-wrapper")
                    .getBoundingClientRect();
                if (
                    !dims ||
                    box.height !== dims.height || box.width !== dims.width
                ) {
                    setDims(box);
                }
            }
        }, 300);
        if (ref.current) {
            let box = ref.current
                .querySelector(".artboard-wrapper")
                .getBoundingClientRect();
            if (
                !dims ||
                box.height !== dims.height || box.width !== dims.width
            ) {
                setDims(box);
            }
        }
        window.addEventListener("resize", debouncedHandleResize);
        return () =>
            window.removeEventListener("resize", debouncedHandleResize);
    });

    let scale = 1;
    let scaleX = 1;
    let scaleY = 1;

    if (dims) {
        scaleX = dims.width / w;
        scaleY = dims.height / h;
        scale = Math.min(scaleX, scaleY);
    }

    const makeImage = uri => {
        const oldHeader = "data:image/svg+xml;charset=utf-8,";
        const svg = uri.replace(oldHeader, "");
        return svg;
    };

    const rasterize = artboard => {
        return new Promise((resolve, reject) => {
            const node = artboard.firstElementChild;
            domtoimage
                .toSvg(node, {
                    height: h,
                    width: w,
                    style: {
                        transform: ``,
                        left: "0",
                        top: "0",
                        margin: 0
                    }
                })
                .then(makeImage)
                .then(resolve);
        });
    };

    const selectGroup = groupIndex => {
        updateCurrentGroupIndex(groupIndex);
    };
    let groupWithError = false;
    let productSelectionCheck = true;
    groups.forEach(group => {
        if (group === null) return;
        const { productName, colorName } = group.texture;
        if (!productName || !colorName) {
            productSelectionCheck = false;
            groupWithError = group.label;
        }
    });

    const productHelp = [
        {
            selector: ".category.selected",
            content: () => (
                <div>
                    <h5>Tutorial / Product Selection</h5>
                    <h3>Profile Category</h3>
                    <hr />
                    <p>
                        Use the following to select between our Profile
                        Categories.
                    </p>
                </div>
            ),
            position: "top",
            action: node => {
                node.focus();
            },

            stepInteraction: false
        },
        {
            selector: ".product-wrapper",
            content: () => (
                <div>
                    <h5>Tutorial / Product Selection</h5>
                    <h3>Products</h3>
                    <hr />
                    <p>
                        Select a Product to have it appear as a pattern on the
                        shapes you've drawn
                    </p>
                </div>
            ),
            position: "top",
            action: node => {
                node.focus();
                //node.click();
            },

            stepInteraction: false
        },
        {
            selector: ".selected-product i.fa.fa-external-link",
            content: () => (
                <div>
                    <h5>Tutorial / Product Selection</h5>
                    <h3>Products</h3>
                    <hr />
                    <p>
                        Click here to view more details about the selected
                        product.
                    </p>
                </div>
            ),
            position: "top",
            action: node => {
                node.focus();
                //node.click();
            },

            stepInteraction: false
        },
        {
            selector: ".colour-container",
            content: () => (
                <div>
                    <h5>Tutorial / Product Selection</h5>
                    <h3>Choose a Colour</h3>
                    <hr />
                    <p>
                        You can use the colour selectors to browse through a
                        number of our different colours.
                    </p>
                </div>
            ),
            position: "top",
            action: node => {
                node.focus();
            },
            stepInteraction: false
        },
        {
            selector: ".group-list",
            content: () => (
                <div>
                    <h5>Tutorial / Product Selection</h5>
                    <h3>Groups</h3>
                    <hr />
                    <p>
                        You need to select a product for each group in your
                        project.
                    </p>
                </div>
            ),
            position: "top",
            action: node => {
                node.focus();
            },
            stepInteraction: false
        },

        {
            selector: ".back",
            content: () => (
                <div>
                    <h5>Tutorial / Product Selection</h5>
                    <h3>Perspective</h3>
                    <hr />
                    <p>
                        If your selected product doesn't look quite right you
                        can go click <b>Back</b> to adjust the shape's
                        perspective.
                    </p>
                </div>
            ),
            position: "top",
            action: node => {
                node.focus();
            },
            stepInteraction: false
        },
        {
            selector: ".next",
            content: c => (
                <div>
                    <h5>Tutorial / Product Selection</h5>
                    <h3>Finished</h3>
                    <hr />
                    <p>
                        Once you've selected products and colour click{" "}
                        <b>Next</b>
                    </p>
                    <div className="center">
                        <Button
                            className="green small"
                            label="Let's Start!"
                            onClick={c.close}
                        />
                    </div>
                </div>
            ),
            position: "top",
            action: node => {
                node.focus();
            },
            stepInteraction: false
        }
    ];


    const { productName, colorName } = groups[currentGroupIndex].texture;

    return (
        <>
            <section id="draw" ref={ref}>
                <Fade delay={300} duration={1200}>
                    <div className="artboard-wrapper">
                        <div
                            className="artboard"
                            style={{
                                backgroundImage: "url(" + image + ")",
                                transform: `translateX(-50%) translateY(-50%) scale(${scale})`
                            }}
                        >
                            <div id="shape-wrapper">
                                {currentShapes.map((shape, index) => {
                                    if (
                                        typeof shape === "undefined" ||
                                        shape === null
                                    )
                                        return null;
                                    const { texture } = groups[shape.group];
                                    const {
                                        categoryId,
                                        textureColor,
                                        size,
                                        url,
                                        rotate = 0
                                    } = texture;
                                    return (
                                        <RenderedShape
                                            key={`rendered-shape-${index}`}
                                            w={w}
                                            h={h}
                                            image={image}
                                            shape={shape}
                                            textureURL={url}
                                            textureSize={size}
                                            textureColor={textureColor}
                                            categoryId={categoryId}
                                            rotate={rotate}
                                            ppi={ppi}
                                            preview={preview}
                                            scaleShapeHeight={scaleShapeHeight}
                                            scaleTotal={scaleTotal}
                                            goToURI={goToURI}
                                            //		setDims={setDims}
                                            //		dims={dims}
                                        />
                                    );
                                })}
                                <div style={{
                                        position: "absolute",
                                        left: "30px",
                                        bottom: "30px",
                                        fill: "rgba(238,238,238,.7)",
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        fontSize: "16px",
                                        color: "rgba(238, 238, 238, 0.7)",
                                        display: "inline-block",
                                        margin: 0,
                                        width: "100%",
                                        textAlign: "left"
                                    }}>
                                        {groups.map((group, i)=>{
                                            if (group === null) return null;
                                        return (
                                                <div key={i}>
                                                    <h6>{group.label}</h6>
                                                    {productName.replace(/[^A-Za-z\s']/g, "")} - {colorName.replace(/[^A-Za-z\s']/g, "")}
                                                </div>
                                        )})}
                                    </div>
                                    <Logo style={{
                                        width: "80px",
                                        position: "absolute",
                                        right: "10px",
                                        bottom: "5px",
                                        fill: "rgba(238,238,238,.7)",
                                    }}/>
                            </div>
                        </div>
                    </div>
                </Fade>
                <div className="sidebar">
                    {dims && (
                        <>
                            <ProductControls
                                key="product-controls"
                                w={w}
                                h={h}
                                image={image}
                                shape={currentShapes}
                                updateTextureColor={updateTextureColor}
                                updateTexture={updateTexture}
                                saveProjects={saveProjects}
                                isSaving={isSaving}
                                currentProject={currentProject}
                                projectName={projectName}
                                setProjectName={setProjectName}
                                loggedIn={loggedIn}
                                groups={groups}
                                artBoardRef={ref}
                                currentGroupIndex={currentGroupIndex}
                                selectGroup={selectGroup}
                                renameGroup={renameGroup}
                                deleteGroup={deleteGroup}
                                defaultTexture={defaultTexture}
                                preview={preview}
                                setPreview={setPreview}
                                shareHash={shareHash}
                                productId={productId}
                                colorId={colorId}
                                rasterize={rasterize}
                                rasterizeProject={rasterizeProject}
                                setProjectImage={setProjectImage}
                                groupWithError={groupWithError}
                                productSelectionCheck={productSelectionCheck}
                                goToURI={goToURI}
                                setProductsLoaded={setProductsLoaded}
                                updateProductCategory={updateProductCategory}
                                setLoading={setLoading}
                            />
                            <Tour
                                key="scale"
                                closeWithMask={true}
                                steps={productHelp}
                                disableInteraction={true}
                                isOpen={productsLoaded && props.isHelpOpen}
                                onRequestClose={props.toggleHelp}
                                onAfterOpen={disableBody}
                                onBeforeClose={enableBody}
                                badgeContent={(curr, tot) =>
                                    `${curr} of ${tot}`
                                }
                                accentColor="#0a4436"
                                highlightedMaskClassName="tour-active"
                                showNavigation={false}
                                showNavigationNumber={false}
                            />
                        </>
                    )}
                    <HelpButton
                        helpOnly={true}
                        toggleHelp={props.toggleHelp}
                        setIsHelpOpen={props.setIsHelpOpen}
                        defaultHelpState={props.defaultHelpState}
                        setDefaultHelpState={props.setDefaultHelpState}
                    />
                </div>
            </section>
        </>
    );
};

export default Products;