import React from "react";
import { generateStaticPath } from "../utils";

const Mask = ({ currentShapes, zoom }) => {
	let width = Math.round(zoom.scaleX > 1 ? zoom.scaleX * 100 : (1 - zoom.scaleX + 1.05) * 100);
	let height = Math.round(zoom.scaleY > 1 ? zoom.scaleY * 100 : (1 - zoom.scaleY + 1.05) * 100);
	return (
		<mask id="mask">
			<rect x="0" y="0" width={width + "%"} height={height + "%"} fill="white" />
			{currentShapes.map((shape, shapeIndex) => {
				if (shape === null) return null;
				return shape.cutShapes.map(({ points }, i) => <path d={generateStaticPath(points)} key={`mask_${shapeIndex}_${i}`} />);
			})}
		</mask>
	);
};

export default Mask;
