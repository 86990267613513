import React, { useState } from "react";
import "../styles/controls.scss";
import Button from "../components/Button";
import SweetAlert from "sweetalert-react";
//import { Twitter, Pinterest, Facebook } from "react-social-sharing";

import "react-dropdown/style.css";

const ShareControls = ({
	handleDownload,
	artboardRef,
	saveProjects,
	currentProject,
	projectName,
	setProjectName,
	loggedIn,
	goToURI,
	shareHash,
    setPreview,
    isLoading,
    downloadError,
    setDownloadError,
    isSaving,
	groups
}) => {
    const [showProjectName, setShowProjectName] = useState(false);
   
    const resetDownloadError = () => {
        setDownloadError(false);
    }

	const handleSaveClick = () => {
		setShowProjectName(true);
	};
	const cancel = () => {
		setShowProjectName(false);
    };
    
	/* let shareURL = "";
	const shareURLBase = window.location.href.includes("local")
		? "http://local.havelockmetal.com/share/?project="
		: "https://hmc.theyinteractive.com/share/?project=";
	if (shareHash) {
		shareURL = shareURLBase + shareHash;
	}
 */
	const productChoices = groups.map(group => {
		if (group === null) return null;
		const { productName, colorName } = group.texture;
		return { name: group.label, texture: { productName, colorName } };
    });
    
	return (
		<section id="controls">
			<h1 className="center">Share</h1>
			<div className="product-details">
				{productChoices.map(choice => {
					if (choice == null) return null;
					return (
						<div key={choice.name}>
							<h4>{choice.name}</h4>
							{choice.texture.productName && choice.texture.colorName && (
								<p>
									<b>Product:</b> {choice.texture.productName}
									<br />
									<b>Color:</b> {choice.texture.colorName}
								</p>
							)}
						</div>
					);
				})}
			</div>
			<div className="nav">
				<Button
					label="Back"
					icon="fa-chevron-left"
					className="outline-green back"
					onClick={() => {
						setPreview(false);
						goToURI("/products");
					}}
				/>
				<Button label="Download" icon="fa-download" className="outline-green download" onClick={handleDownload} />
				{loggedIn && <Button label="Save" icon="fa-save" className="green save" loading={isSaving} loadingText="" onClick={handleSaveClick} />}
			</div>
			<SweetAlert
				show={showProjectName}
				title="Save Project"
				type="input"
				inputType="text"
				confirmButtonColor="#0A4438"
				inputPlaceholder="Project Name"
				showCancelButton
				inputValue={projectName}
				onConfirm={projectName => {
					setProjectName(projectName);
					setShowProjectName(false);
					saveProjects(artboardRef.current, currentProject, projectName);
				}}
				onOutsideClick={cancel}
				onEscapeKey={cancel}
				onCancel={cancel}
			/>
            <SweetAlert
                type="warning"
                show={downloadError}
                title="Error"
                showCancelButton
                confirmButtonColor="#0A4438"
                onEscapeKey={resetDownloadError}
				onOutsideClick={resetDownloadError}
				onCancel={resetDownloadError}
                text="There was an issue generating download. Try again?"
                onConfirm={() => {resetDownloadError(); handleDownload();} }
            />
			
		</section>
	);
};

export default ShareControls;
