import React from "react";
import Spinner from "../icons/Spinner";

const Loader = () => {
	const loaderStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "160px",
		width: "160px"
	};
	return (
		<div style={loaderStyle}>
			<Spinner className="spinner"/>
		</div>
	);
};

export default Loader;