import React from "react";
import Logo from "../icons/Logo";
import House from "../icons/House";
import "../styles/header.scss";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import Steps, { Step } from "rc-steps";
import ReactTooltip from "react-tooltip";

//import { Link } from "react-router-dom";

function Header({ goToURI, location, setLoginOpen, userToken, logout, userTokenCookie }) {
	let currentStep;

	switch (location.pathname) {
		case "/new":
			currentStep = 1;
			break;
		case "/scale":
			currentStep = 2;
			break;
		case "/draw":
			currentStep = 3;
			break;
		case "/products":
			currentStep = 4;
			break;
		case "/share":
			currentStep = 5;
			break;
		default:
			currentStep = false;
	}

	return (
		<header id="header">
			<div className="brand" onClick={() => goToURI(`/`)} style={{ cursor: "pointer" }}>
				<Logo className="logo green" />
				<h3 className="title">Visualizer</h3>
			</div>

			<div className="steps">
				{currentStep && (
					<Steps labelPlacement="vertical" current={currentStep}>
						<Step title="Start" onClick={() => goToURI("/")} />
						<Step title="Crop" onClick={currentStep > 1 ? () => goToURI("/new") : null} />
						<Step title="Scale" onClick={currentStep > 2 ? () => goToURI("/scale") : null} />
						<Step title="Draw" onClick={currentStep > 3 ? () => goToURI("/draw") : null} />
						<Step title="Products" onClick={currentStep > 4 ? () => goToURI("/products") : null} />
						<Step title="Share" onClick={currentStep > 5 ? () => goToURI("/products") : null} />
					</Steps>
				)}
			</div>
			{userToken === false && (
				<div
					className="sign-in"
					onClick={() => {
						if (location.pathname.startsWith("/forgot-password")) {
							goToURI("/");
						}
						setLoginOpen(true);
					}}
					style={{ cursor: "pointer" }}
				>
					<div className="cta">
						<span>Login / Sign Up</span>
					</div>
					<div className="icon-wrapper">
						<House className="icon" />
					</div>
				</div>
			)}
			{userToken === null && userTokenCookie && (
				<div className="sign-in">
					<div className="cta">
						<span>Loading...</span>
					</div>
					<div className="icon-wrapper">
						<House className="icon" />
					</div>
				</div>
			)}
			{userToken !== null && userToken.username && (
				<div className="sign-in">
					<ReactTooltip effect="solid" className="small-tip" place="bottom" id="account" />
					<div className="cta">
						<span>{userToken.firstname}</span>
						<span style={{ cursor: "pointer" }} onClick={() => logout()} data-tip="logout" data-for="account">
							<i className="fa fa-sign-out" />
						</span>
					</div>
					<div className="icon-wrapper" style={{ cursor: "pointer" }} onClick={() => goToURI("/open")} data-tip="My Projects" data-for="account">
						<House className="icon" />
					</div>
				</div>
			)}
		</header>
	);
}

export default Header;
