import React, { useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import useInput from "@rooks/use-input";
import Cookies from "universal-cookie";
import SweetAlert from "sweetalert-react";

const Login = props => {
  const [state, setState] = useState({
    signup: false,
    forgotPassword: false,
    loading: false,
    error: false
  });

  const username = useInput("");
  const password = useInput("");
  const confirmPassword = useInput("");
  const firstname = useInput("");
  const lastname = useInput("");

  const toggleSignup = e => {
    e.preventDefault();
    const { signup } = state;
    setState({ ...state, signup: !signup, forgotPassword: false });
  };

  const loginCallBack = async e => {
    e.preventDefault();
    if (signup) {
      if (confirmPassword.value !== password.value) {
        return setState({ ...state, error: true, loading: false });
      }
    }

    setState({
      ...state,
      loading: true
    });

    const apiBase = window.location.href.includes("local")
      ? "http://local.havelockmetal.com/"
      : "https://hmc.theyinteractive.com/";
    let url = apiBase + "visualizer-api?action=";

    url += signup ? "signup" : "login";

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        firstname: firstname.value,
        lastname: lastname.value,
        username: username.value,
        password: password.value
      })
    })
      .then(response => response.json())
      .then(data => {
        if (!data.user) {
          setState({ ...state, error: true, loading: false });
        } else {
          const cookies = new Cookies();
          cookies.set("userToken", data.user, { path: "/" });
          props.setUserToken(data.user);
          setState({ ...state, loading: false });
        }
      })
      .catch(function(err) {
        setState({ ...state, error: true, loading: false });
        console.error(err);
      });
  };

  const { signup, forgotPassword, loading, error } = state;
  const { forgotPasswordSent } = props;
  const tabs = (
    <div className="tab">
      <button
        className={`tablinks ${!signup ? "active" : ""}`}
        onClick={signup ? toggleSignup : null}
      >
        Login
      </button>
      <button
        className={`tablinks ${signup ? "active" : ""}`}
        onClick={!signup ? toggleSignup : null}
      >
        Sign Up
      </button>
    </div>
  );
  if (signup && !forgotPassword) {
    return (
      <>
        {tabs}
        <div className="tabcontent">
          <form onSubmit={loginCallBack}>
            <Input label="First Name" name="firstname" required={true} {...firstname} />
            <Input label="Last Name" name="lastname" required={true} {...lastname} />
            <Input
              label="Email"
              name="username"
              type="email"
              required={true}
              autoComplete="current-password"
              {...username}
            />
            <Input
              label="Password"
              name="password"
              type="password"
              required={true}
              autoComplete="current-password"
              {...password}
            />
            <Input
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              required={true}
              autoComplete="confirm-password"
              {...confirmPassword}
            />
            <div className="center" style={{ margin: "1em 0" }}>
              <Button label={"Sign Up"} loading={loading} className={"green"} type="submit" />
            </div>
          </form>
        </div>
      </>
    );
  } else if (forgotPassword && !forgotPasswordSent) {
    return (
      <>
        {tabs}
        <div className="tabcontent">
          <form
            onSubmit={e => {
              props.handleForgotPassword(e, username.value);
            }}
          >
            <p className="center">Reset Your Password</p>
            <Input label="Email" name="username" type="email" required={true} {...username} />
            <div className="center" style={{ margin: "1em" }}>
              <Button label="Reset" className={"green"} type="submit" />
            </div>
          </form>
        </div>
      </>
    );
  } else if (forgotPasswordSent) {
    return (
      <>
        {tabs}
        <div className="tabcontent">
          <p>
            We have sent a password reset email to <b>{username.value}</b>. Please click the link to
            reset your password.
          </p>
        </div>
      </>
    );
  } else if (!signup && !forgotPassword) {
    return (
      <>
        {tabs}
        <div className="tabcontent">
          <form onSubmit={loginCallBack}>
            <SweetAlert
              show={error}
              title="Error"
              type="warning"
              text="Email and/or Password is incorrect"
              confirmButtonColor="#0A4438"
              onConfirm={() => setState({ error: false })}
            />
            <Input
              label="Email"
              name="username"
              type="email"
              required={true}
              autoComplete="current-password"
              {...username}
            />
            <Input
              label="Password"
              name="password"
              type="password"
              required={true}
              autoComplete="current-password"
              {...password}
            />
            <div className="center" style={{ marginTop: "1em" }}>
              <Button
                label={"Login"}
                loading={loading}
                loadingText="Loading..."
                className={"green"}
                type="submit"
              />

              <p
                onClick={() => {
                  setState({ forgotPassword: true });
                }}
                style={{ cursor: "pointer", marginBottom: 0 }}
              >
                Forgot your password?
              </p>
            </div>
          </form>
        </div>
      </>
    );
  }
};

export default Login;
