import React, { useLayoutEffect, useState } from "react";
import { useFetch } from "@bjornagh/use-fetch";
import { withRouter } from "react-router-dom";
import Input from "../components/Input";
import Button from "../components/Button";
import SweetAlert from "sweetalert-react";
import Spinner from "../icons/Spinner";

const ForgotPassword = ({ match, history }) => {
	const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(false);
	const [resetError, setResetError] = useState(false);
	const [resetDone, setResetDone] = useState(false);
	const [state, setState] = useState({
		username: "",
		password: "",
		confirmPassword: "",
		error: false
	});

	const { resetToken } = match.params;
	const apiBase = window.location.href.includes("local") ? "http://local.havelockmetal.com/" : "https://hmc.theyinteractive.com/";
	const checkToken = useFetch({
		url: apiBase + "visualizer-api?action=checkResetToken",
		method: "POST",
		onSuccess: res => {
			setLoading(false);
			if (res.data.username) {
				setState({ ...state, username: res.data.username });
				setToken(res.data.token);
			}
        },
        onError: (e) => {
            setState({ ...state, error: true });
            setToken(false);
        },
		init: {
			headers: {
				"Content-type": "application/json"
			}
		}
	});

	const handleChange = event => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	useLayoutEffect(() => {
		if (resetToken && token === null && !loading && !state.error) {
			setLoading(true);
			checkToken.doFetch({
				body: {
					resetToken: resetToken
				}
			});
        }
        // eslint-disable-next-line
	}, [resetToken]);

	const resetCallBack = async e => {
		e.preventDefault();
		setLoading(true);
		setState({
			...state,
			error: false
		});

		const { username, password } = state;

		const apiBase = window.location.href.includes("local") ? "http://local.havelockmetal.com/" : "https://hmc.theyinteractive.com/";
		let url = apiBase + "visualizer-api?action=changePassword";

		fetch(url, {
			method: "POST",
			body: JSON.stringify({ username, password, token })
		})
			.then(response => response.json())
			.then(data => {
				if (data.error) {
					setResetError(true);
				} else {
					setResetDone(true);
				}
			})
			.catch(function(err) {
				setState({ ...state, error: true, loading: false });
				console.error(err);
			});
	};

	if (token) {
		return (
			<section id="forgot-password">
				<div>
					<h3>Reset Password</h3>
					<form onSubmit={resetCallBack}>
						<Input
							label="Email"
							name="username"
							type="email"
							value={state.username}
							autoComplete="current-password"
							onChange={e => handleChange(e)}
							required={true}
						/>
						<Input
							label="New Password"
							name="password"
							type="password"
							value={state.password}
							autoComplete="current-password"
							onChange={e => handleChange(e)}
							required={true}
						/>
						<Input
							label="Confirm Password"
							name="confirmPassword"
							type="password"
							autoComplete="confirm-password"
							value={state.confirmPassword}
							onChange={e => handleChange(e)}
							required={true}
						/>
						<Button label={"Reset"} loading={loading} loadingText="Loading..." className={"green"} type="submit" />
					</form>
				</div>
				<SweetAlert
					show={resetError}
					title="Error"
					type="warning"
					text="Something went wrong updating password"
					confirmButtonColor="#0A4438"
					onConfirm={() => setResetError(false)}
				/>
				<SweetAlert
					show={resetDone}
					title="Done"
					type="success"
					text="Your password has been updated"
					confirmButtonColor="#0A4438"
					onConfirm={() => {
						setResetDone(false);
						history.push("/");
					}}
				/>
			</section>
		);
	} else if (loading) {
		return (
			<section id="forgot-password">
				<div className="center" style={{ marginTop: "6em" }}>
					<Spinner className="spinner" />
				</div>
			</section>
		);
	} else {
		return (
			<section id="forgot-password">
				<h3>Reset Password</h3>
				<p>The password resent link may be invaild. Try resending a new password reset.</p>
			</section>
		);
	}
};

export default withRouter(ForgotPassword);
