import React from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";

const Range = ({title, value, className, ...props}) => {
	return (
        <>
        <span className="center">{title}</span>
		<div className={`slider ${className || ""}`}>
			<Slider value={parseFloat(value)} {...props} />
		</div>
        </>
	);
};

export default Range;
