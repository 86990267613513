import React from "react";
import { NavLink } from "react-router-dom";

import Hammers from "../icons/Hammers";
import "../styles/home.scss";

function Home({ userToken, setLoginOpen, startNew }) {
	return (
		<section id="home">
			<div className="ad-brick new-project" onClick={startNew} style={{ cursor: "pointer" }}>
				<h1>Start a New Project</h1>
				<Hammers className="icon" />
			</div>
			<div className="ad-brick open-project">
                {userToken && userToken.username && (
					<NavLink to="/open">
						<h1>Open a Saved Project</h1>
						<Hammers className="icon" />
					</NavLink>
				)}
                {!userToken && (
					<span className="clickable" style={{ cursor: "pointer" }} onClick={() => setLoginOpen(true)}>
						<h1>Open a Saved Project</h1>
						<Hammers className="icon" />
					</span>
				)}
			</div>
		</section>
	);
}

export default Home;
