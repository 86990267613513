import React from "react";
import ReactTooltip from "react-tooltip";

//credits https://materialdesignicons.com/icon/cursor-default-outline

export default function IconFit() {
	return (
		<>
			<ReactTooltip effect="solid" className="small-tip" id="icon-fit" />
			<svg width={24} height={24} stroke="none" fill="currentColor" data-for="icon-fit" data-tip="Click to Auto Fit Canvas">
				<path d="M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6z" />
			</svg>
		</>
	);
}
