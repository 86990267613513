import { FetchProvider } from "@bjornagh/use-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import Bowser from "bowser";
import "normalize.css";
import "./styles/index.scss";

import App from "./App";
import DeviceNotSupported from "./DeviceNotSupported";

let defaultCache = new Map();

if (localStorage.fetchCacheTimeStamp && localStorage.fetchCache) {
	const now = Date.now();
	if (now - localStorage.fetchCacheTimeStamp < 86400000) {
		defaultCache = new Map(JSON.parse(localStorage.fetchCache));
	}
}

const supportsHistory = "pushState" in window.history;

const browser = Bowser.getParser(window.navigator.userAgent);

const isValidBrowser = browser.satisfies({
	safari: ">12",
	chrome: ">74",
	firefox: ">70",
	"Microsoft Edge": ">74"
});

const updateCache = () => {
	localStorage.fetchCache = JSON.stringify(Array.from(defaultCache.entries()));
	localStorage.fetchCacheTimeStamp = Date.now();
};

ReactDOM.render(
	isValidBrowser ? (
		<Router forceRefresh={!supportsHistory} hashType="slash">
			<FetchProvider cache={defaultCache}>
				<App cache={defaultCache} updateCache={updateCache} />
			</FetchProvider>
		</Router>
	) : (
		<DeviceNotSupported />
	),
	document.getElementById("root")
);
