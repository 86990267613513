import React from "react";
import Spinner from "../icons/Spinner";
import { withRouter } from "react-router-dom";
import Button from "../components/Button";
import Fade from "react-reveal/Fade";
import ProjectItem from "../components/ProjectItem";
import "../styles/projects.scss";

function Project({
  projects,
  loadProject,
  isSaving,
  setCopying,
  isCopying,
  copyProject,
  deleteProject,
  userToken,
  setShareHash,
  projectListLoading,
  history,
  setProjectListLoading
}) {
  if (projectListLoading) {
    return (
      <div className="spinner-wrapper">
        <div style={{ display: "flex", alignItems: "center", height: "calc(100vh - 104px)" }}>
          <Spinner className="spinner-large" />
        </div>
      </div>
    );
  } else if (!projects.length) {
    return (
      <div className="spinner-wrapper">
        <div style={{ display: "flex", alignItems: "center", height: "calc(100vh - 104px)" }}>
          <div>
            <h2 style={{ display: "block" }}>No Projects created</h2>
            <Button
              label="Back"
              icon="fa-chevron-left"
              className="outline-green"
              onClick={() => history.push("/")}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <section id="project">
      <Fade cascade duration={600}>
        <div className="project-wrapper">
          {projects.map(({ id, project_name, data, sharehash }) => (
            <ProjectItem
              key={id}
              projectId={id}
              loadProject={loadProject}
              projectName={project_name}
              data={data}
              isSaving={isSaving}
              copyProject={copyProject}
              deleteProject={deleteProject}
              userToken={userToken}
              setCopying={setCopying}
              isCopying={isCopying}
              shareHash={sharehash}
              setShareHash={setShareHash}
              setProjectListLoading={setProjectListLoading}
            />
          ))}
        </div>
      </Fade>
    </section>
  );
}

export default withRouter(Project);
