import React, { Component } from "react";
import Measure from "react-measure";
import { INITIAL_VALUE, ReactSVGPanZoom, TOOL_NONE } from "../utils/react-svg-pan-zoom/src";
//import { hasChanged } from "../utils";
import Grid from "./Grid";
import Mask from "./Mask";
import CutMask from "./CutMask";
import Path from "./Path";
import Handles from "./Handles";
export default class Canvas extends Component {
	state = {
		tool: TOOL_NONE,
		value: INITIAL_VALUE,
		dimensions: false,
		autoSized: false
	};
	Viewer = null;

	constructor() {
		super();
		this.scalePath = React.createRef();
		this.handleClick = this.handleClick.bind(this);
		this.handleZoom = this.handleZoom.bind(this);
		this.handlePan = this.handlePan.bind(this);
		this.handleChangeValue = this.handleChangeValue.bind(this);
		this.handleChangeTool = this.handleChangeTool.bind(this);
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		document.addEventListener("click", this.handleClick, true);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleClick);
	}

	/* 	shouldComponentUpdate(nextProps, nextState) {
		return (
			hasChanged(this.state, nextState) || hasChanged(this.props, nextProps)
		);
	} */

	componentDidUpdate(prevProps, prevState) {
		if (this.props.canvasLoaded && !this.state.autoSized) {
			this.fitToViewer();
		}
	}

	deltaTransformPoint(matrix, point) {
		const dx = point.x * matrix.a + point.y * matrix.c + 0;
		const dy = point.x * matrix.b + point.y * matrix.d + 0;
		return { x: dx, y: dy };
	}

	decomposeMatrix(matrix) {
		// @see https://gist.github.com/2052247

		// calculate delta transform point
		const px = this.deltaTransformPoint(matrix, { x: 0, y: 1 });
		const py = this.deltaTransformPoint(matrix, { x: 1, y: 0 });

		// calculate skew
		const skewX = (180 / Math.PI) * Math.atan2(px.y, px.x) - 90;
		const skewY = (180 / Math.PI) * Math.atan2(py.y, py.x);

		return {
			translateX: matrix.e,
			translateY: matrix.f,
			scaleX: Math.sqrt(matrix.a * matrix.a + matrix.b * matrix.b),
			scaleY: Math.sqrt(matrix.c * matrix.c + matrix.d * matrix.d),
			skewX: skewX,
			skewY: skewY,
			rotation: skewX // rotation is the same as skew x
		};
	}

	setViewer = Viewer => {
		this.Viewer = Viewer;
	};

	handleZoom = e => {
		this.setState({ tool: this.state.tool });
		this.props.setZoom(this.decomposeMatrix(e));
	};

	handlePan = e => {
		this.props.setZoom(this.decomposeMatrix(e));
	};

	handleResize = contentRect => {
		if (contentRect.bounds === this.state.dimensions) return;
		this.setState({ dimensions: contentRect.bounds }, () => {
			this.props.setCanvasLoaded(true);
		});
		this.fitToViewer();
	}

	handleChangeValue(nextValue) {
		this.setState({ value: nextValue });
	}
	handleChangeTool(nextTool) {
		this.setState({ tool: nextTool });
	}

	fitToViewer() {
		if (!this.Viewer) return;
		this.Viewer.fitToViewer();
		this.setState({ autoSized: true });
	}
	handleClick(e) {
		if (this.props.isAddingPoint && e.button === 0) {
			this.props.addPoint(e);
		}
	}

	render() {
		const { width, height } = this.state.dimensions;

		const {
			isAddingPoint,
			moveFailed,
			w,
			h,
			gridOpacity,
			gridSize,
			gridShow,
			cutMode,
			currentShapes,
			currentShapeIndex,
			currentCutShapeIndex,
			selectShape,
			activePoint,
			setDraggedPoint,
			setDraggedQuadratic,
			isScaleStep,
			setRef,
			setMoving,
			setCurrentCutShapeIndex
		} = this.props;

		let canvasHeight;
		canvasHeight = height || 0;
		//canvasWidth = width || 0;

		const miniatureProps = {
			position: "none",
			width: (w / 10) * 2,
			height: (h / 10) * 2
		};
		let mask = false;
		if (currentShapeIndex > -1 && currentShapes[currentShapeIndex] && currentShapes[currentShapeIndex].cutShapes.length) {
			currentShapes[currentShapeIndex].cutShapes.forEach(shape => {
				if (shape.points.length) mask = true;
			});
		}
		return (
			<Measure bounds onResize={this.handleResize}>
				{({ measureRef }) => (
					<div key="canvasWrap" ref={measureRef} style={{ width: "100%", height: "calc(100vh - 105px)" }}>
						{canvasHeight > 0 && (
							<ReactSVGPanZoom
								key="ReactSVGPanZoom"
								width={width}
								height={height}
								ref={this.setViewer}
								style={{ margin: "0 auto" }}
								tool={this.state.tool}
								onChangeTool={this.handleChangeTool}
								value={this.state.value}
								onChangeValue={this.handleChangeValue}
								detectAutoPan={false}
								miniatureProps={miniatureProps}
								onPan={this.handlePan}
								onZoom={this.handleZoom}
								detectWheel={false}
							>
								<svg key="project" id="project" ref={setRef} width={w} height={h} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
									{!cutMode && mask && <Mask currentShapes={currentShapes} zoom={this.props.zoom} />}

									<image href={this.props.image} width={w} height={h} alt="background" />
									<Grid w={w} h={h} gridShow={gridShow} gridSize={gridSize} gridOpacity={gridOpacity} />
									<Path
										isAddingPoint={isAddingPoint}
										moveFailed={moveFailed}
										currentShapes={currentShapes}
										selectShape={selectShape}
										cutMode={cutMode}
										setMoving={setMoving}
										isCutPath={false}
										currentShapeIndex={currentShapeIndex}
										mask={mask}
										isScaleStep={isScaleStep}
									/>
									{cutMode && (
										<Path
											isAddingPoint={isAddingPoint}
											moveFailed={moveFailed}
											cutMode={cutMode}
											isCutPath={true}
											currentCutShapeIndex={currentCutShapeIndex}
											setMoving={setMoving}
											setCurrentCutShapeIndex={setCurrentCutShapeIndex}
											currentShapes={currentShapes[currentShapeIndex].cutShapes}
											selectShape={selectShape}
											currentShapeIndex={currentShapeIndex}
										/>
									)}
									{currentShapes[currentShapeIndex] && (
										<Handles
											key={
												cutMode
													? "cut-handles-" + currentShapes[currentShapeIndex].id + "-" + currentCutShapeIndex
													: "handles-" + currentShapes[currentShapeIndex].id
											}
											currentShape={cutMode ? currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex] : currentShapes[currentShapeIndex]}
											zoom={this.props.zoom}
											activePoint={activePoint}
											currentShapeIndex={currentShapeIndex}
											currentCutShapeIndex={currentCutShapeIndex}
											cutMode={cutMode}
											setDraggedPoint={setDraggedPoint}
											setDraggedQuadratic={setDraggedQuadratic}
											isScaleStep={isScaleStep}
											isAddingPoint={isAddingPoint}
										/>
									)}
									{cutMode && (
										<CutMask cutShapes={currentShapes[currentShapeIndex].cutShapes} currentCutShapeIndex={currentCutShapeIndex} zoom={this.props.zoom} />
									)}
								</svg>
							</ReactSVGPanZoom>
						)}
					</div>
				)}
			</Measure>
		);
	}
}
