import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import TransformShape from "./TransformShape";
import { generateStaticPath } from "../utils";
import { pathDataToPolys } from "../utils/path-to-polygon";
//import { transform, fromObject, translate, scale, toString } from "transformation-matrix";

const ArtBoard = ({
	image,
	w,
	h,
	shape,
	currentGroup,
	scaleTotal,
	scaleShapeHeight
}) => {            
    const [dims, setDims] = useState(false);

    let scale = 1;
	let scaleX = 1;
	let scaleY = 1;

	if (dims) {
        scaleX = dims.width / w;
        scaleY = dims.height / h;
        scale = Math.min(scaleX, scaleY);
    }

	let polygon = "";
	const cutElements = shape.hasOwnProperty("cutShapes")
		? shape.cutShapes.map((shape, i) => {
                let polygon = "";
                if (!shape.points.length) return null;
                const pathData = generateStaticPath(shape.points);
				const points = pathDataToPolys(pathData);
				for (let point of points[0]) {
					polygon += ` ${(point[0] / w) * 100}% ${(point[1] / h) * 100}%, `;
				}
                polygon = polygon.replace(/,\s*$/, "");

				return (
					<div
						key={`cut_path_${i}`}
						className="cut-path"
						style={{
							clipPath: `polygon(${polygon})`,
							backgroundImage: "url(" + image + ")",
							backgroundPosition: "center",
							backgroundSize: "contain",
							backgroundRepeat: "no-repeat",
							top: 0,
							left: "auto"
						}}
					/>
				);
		  })
		: null;

	const pathData = generateStaticPath(shape.points);
 
	const points = pathDataToPolys(pathData);

	for (let point of points[0]) {
		polygon += ` ${(point[0] / w) * 100}% ${(point[1] / h) * 100}%, `;
	}
	polygon = polygon.replace(/,\s*$/, "");

	const { texture } = currentGroup;
	const { rotate = 0 } = texture;

	return (
		<Fade duration={600}>
			<div className="artboard-wrapper">
				<div
					className="artboard"
					style={{
                        transform: `translateX(-50%) translateY(-50%) scale(${scale})`,

					}}
				>
					<TransformShape
						w={w}
						h={h}
                        currentShape={shape}
						rotate={rotate}
						polygon={polygon}
						texture={texture}
						image={image}
						scaleTotal={scaleTotal}
						scaleShapeHeight={scaleShapeHeight}
						setDims={setDims}
						scale={scale}
                        dims={dims}
					/>
					{cutElements}
				</div>
			</div>
		</Fade>
	);
};

export default ArtBoard;
