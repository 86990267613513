import React from "react";

export default function Hammers(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M8.43 18.924h6.624v-4.729H8.43v4.729zm.948-2.363h4.729v1.418H9.378v-1.418zm4.729-.947H9.378v-.474h4.729v.474zM8.43 26.492h6.624v-5.676H8.43v5.676zm2.84-3.31H9.378v-1.418h1.892v1.418zm-1.892.945h1.892v1.42H9.378v-1.42zm2.838 0h1.892v1.42h-1.892v-1.42zm1.891-.945h-1.892v-1.418h1.892v1.418zm2.838-4.258h6.623v-4.729h-6.623v4.729zm.948-1.418h4.729v.473h-4.729v-.473zm4.729-.945h-4.729v-1.419h4.729v1.419z" />
      <path fill="none" d="M1.809 2.197h28.382v27.606H1.809z" />
      <path d="M29.717 24.6a.475.475 0 0 0-.473.476v.473h-.474v-.473a.472.472 0 1 0-.947 0v.473h-.472V15.99c.246.111.506.172.769.172a1.895 1.895 0 0 0 1.164-3.385L17.286 3.392l-1.287-1.195-1.256 1.169-12.03 9.411a1.893 1.893 0 0 0-.22 2.778 1.878 1.878 0 0 0 2.154.435v9.557h-.473v-.471a.475.475 0 0 0-.473-.477.474.474 0 0 0-.473.477v.471h-.474v-.471a.473.473 0 1 0-.946 0v4.256c0 .26.211.471.474.471a.47.47 0 0 0 .472-.471v-.473h.474v.473a.472.472 0 1 0 .946 0v-.473h.473v.942h22.706v-.942h.472v.473a.473.473 0 0 0 .947 0v-.473h.475v.473a.473.473 0 1 0 .948 0v-4.256a.481.481 0 0 0-.478-.476M3.186 14.911a.939.939 0 0 1-.25-.718.935.935 0 0 1 .36-.67l12.06-9.437.643-.598.674.623 12.031 9.412a.936.936 0 0 1 .358.67.935.935 0 0 1-.25.718.94.94 0 0 1-1.271.104l-.19-.146-11.352-8.751-11.168 8.61-.375.288a.94.94 0 0 1-1.27-.105m-.432 11.581h.474v1.42h-.474v-1.42zm1.42 0h.473v1.42h-.473v-1.42zm13.719-4.728h4.729v7.096h-4.729v-7.096zm8.513 7.095h-2.838v-8.043h-6.623v8.043H5.592V15.334l10.407-8.022 10.407 8.022v13.525zm.948-2.367h.472v1.42h-.472v-1.42zm1.417 0h.474v1.42h-.474v-1.42z" />
    </svg>
  );
}
