import { useState, useLayoutEffect, useEffect, useRef, useCallback } from "react";
import { useFetch } from "@bjornagh/use-fetch";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { diff } from "deep-object-diff";

const apiBase = "https://www.havelockmetal.com/";

export function generateStaticPath(points) {
	if (!points || points.length < 1) return "M 0 0 Z";
	let d = "";

	points.forEach((p, i) => {
		if (i === 0) {
			// first point
			d += "M ";
		} else if (p.q) {
			// quadratic
			d += `Q ${p.q.x} ${p.q.y} `;
		} else if (p.c) {
			// cubic
			d += `C ${p.c[0].x} ${p.c[0].y} ${p.c[1].x} ${p.c[1].y} `;
		} else if (p.a) {
			// arc
			d += `A ${p.a.rx} ${p.a.ry} ${p.a.rot} ${p.a.laf} ${p.a.sf} `;
		} else {
			d += "L ";
		}

		d += `${p.x} ${p.y} `;
	});

	d += "Z";

	return d;
}

export const useCheckToken = (token, callback) => {
	const url = apiBase + "visualizer-api?action=checkToken";
	const [data, setData] = useState(null);

	async function fetchData() {
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify({ token })
		});
		const text = await response.text();
		setData(text);
	}

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line
	}, [url]);

	if (data !== null) callback(data);

	return data;
};

export const getPolygonArea = points => {
	var $area = 0.0;

	let X = points[0].map(points => {
		return points[0];
	});

	let Y = points[0].map(points => {
		return points[1];
	});

	let $n = X.length;

	var $j = $n - 1;
	for (let $i = 0; $i < $n; $i++) {
		$area += (X[$j] + X[$i]) * (Y[$j] - Y[$i]);
		$j = $i;
	}

	return Math.abs($area / 2.0);
};

export const encodeSVG = data => {
	const double = `"`;
	const single = `'`;
	const symbols = /[\r\n%#()<>?[\\\]^`{|}]/g;
	if (data.indexOf("http://www.w3.org/2000/svg") < 0) {
		data = data.replace(/<svg/g, `<svg xmlns=${single}http://www.w3.org/2000/svg${single}`);
	}

	data = data.replace(/"/g, "'");
	data = data.replace(/>\s{1,}</g, "><");
	data = data.replace(/\s{2,}/g, " ");
	data = data.replace(symbols, encodeURIComponent);
	return `url(${double}data:image/svg+xml,${data}${double})`;
};

export function useVisualizerAPI({
	url,
	init = {
		headers: {
			"Content-type": "application/json"
		}
	},
	...rest
}) {
	const apiRoot = window.location.href.includes("local") ? "http://local.havelockmetal.com/" : "https://hmc.theyinteractive.com/";

	const finalUrl = `${apiRoot}${url}`;

	// Set a default header
	const finalHeaders = { ...init.headers };
	finalHeaders["Content-type"] = "application/json";

	// Ensure headers are sent to fetch
	init.headers = finalHeaders;

	return useFetch({ url: finalUrl, init, ...rest });
}

export const useInputValue = initialValue => {
	const [value, setValue] = useState(initialValue);
	return {
		value,
		onChange: e => {
			setValue(e.target.value || e.target.innerText);
		}
	};
};
export const clone = obj => {
	let copy;

	// Handle the 3 simple types, and null or undefined
	if (null == obj || "object" != typeof obj) return obj;

	// Handle Date
	if (obj instanceof Date) {
		copy = new Date();
		copy.setTime(obj.getTime());
		return copy;
	}

	// Handle Array
	if (obj instanceof Array) {
		copy = [];
		for (var i = 0, len = obj.length; i < len; i++) {
			copy[i] = clone(obj[i]);
		}
		return copy;
	}

	// Handle Object
	if (obj instanceof Object) {
		copy = {};
		for (var attr in obj) {
			if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
		}
		return copy;
	}

	throw new Error("Unable to copy obj! Its type isn't supported.");
};

/**
 * Wraps `setTimeout`. Triggers the function after a given delay.
 * @param {Function} fn function to call
 * @param {number} delay in milliseconds
 * @return {void}
 */
export function useTimeout(fn, delay) {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = fn;
	});

	useEffect(() => {
		function cb() {
			savedCallback.current();
		}
		if (delay !== null) {
			const id = setTimeout(cb, delay);
			return () => clearTimeout(id);
		}
	}, [delay]);
}

export function useEventListener(eventName, handler, element = global) {
	const savedHandler = useRef();

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(
		() => {
			const isSupported = element && element.addEventListener;
			if (!isSupported) return;
			const eventListener = event => savedHandler.current(event);
			element.addEventListener(eventName, eventListener);
			return () => {
				element.removeEventListener(eventName, eventListener);
			};
		},
		[eventName, element] // Re-run if eventName or element changes
	);
}

export const disableBody = target => disableBodyScroll(target);
export const enableBody = target => enableBodyScroll(target);

export const hasChanged = (a, b) => {
	const abDiff = diff(a, b);
	return Object.entries(abDiff).length > 0 && abDiff.constructor === Object;
};

export const debounce = (callback, wait = true) => {
	let timeout = null;
	return (...args) => {
		const next = () => callback(...args);
		clearTimeout(timeout);
		timeout = setTimeout(next, wait);
	};
};

export const throttle = (fn, wait) => {
	let previouslyRun,
		queuedToRun = [];

	return function invokeFn(...args) {
		const now = Date.now();

		if (!previouslyRun || now - previouslyRun >= wait) {
			if (queuedToRun.length) {
				queuedToRun.forEach(pid => {
					window.cancelAnimationFrame(pid);
				});
				queuedToRun = [];
			}
			fn.apply(null, args);
			previouslyRun = now;
		} else {
			queuedToRun.push(window.requestAnimationFrame(invokeFn.bind(null, ...args)));
		}
	};
};

export function useWhyDidYouUpdate(name, props) {
	// Get a mutable ref object where we can store props ...
	// ... for comparison next time this hook runs.
	const previousProps = useRef();

	useEffect(() => {
		if (previousProps.current) {
			// Get all keys from previous and current props
			const allKeys = Object.keys({ ...previousProps.current, ...props });
			// Use this object to keep track of changed props
			const changesObj = {};
			// Iterate through keys
			allKeys.forEach(key => {
				// If previous is different from current
				if (previousProps.current[key] !== props[key]) {
					// Add to changesObj
					changesObj[key] = {
						from: previousProps.current[key],
						to: props[key]
					};
				}
			});

			// If changesObj not empty then output to console
			if (Object.keys(changesObj).length) {
				console.log("[why-did-you-update]", name, changesObj);
			}
		}

		// Finally update previousProps with current props for next hook call
		previousProps.current = props;
	});
}

function getDimensionObject(node) {
	const rect = node.getBoundingClientRect();

	if (rect.toJSON) {
		return rect.toJSON();
	} else {
		return {
			width: rect.width,
			height: rect.height,
			top: rect.top || rect.y,
			left: rect.left || rect.x,
			x: rect.x || rect.left,
			y: rect.y || rect.top,
			right: rect.right,
			bottom: rect.bottom
		};
	}
}

export function useDimensions() {
	const [dimensions, setDimensions] = useState({});
	const [node, setNode] = useState(null);

	const ref = useCallback(node => {
		setNode(node);
	}, []);

	useLayoutEffect(() => {
		if (node) {
			const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));
			measure();

			window.addEventListener("resize", measure);
			window.addEventListener("scroll", measure);

			return () => {
				window.removeEventListener("resize", measure);
				window.removeEventListener("scroll", measure);
			};
		}
	}, [node]);

	return [ref, dimensions, node];
}

export function useBbox() {
	const ref = useRef();
	const [bbox, setBbox] = useState({});

	const set = () => setBbox(ref && ref.current ? ref.current.getBoundingClientRect() : {});

	useEffect(() => {
		set();
		window.addEventListener("resize", set);
		return () => window.removeEventListener("resize", set);
	}, []);

	return [bbox, ref];
}

export function useLocalStorage(key, initialValue) {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key);
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			// If error also return initialValue
			console.log(error);
			return initialValue;
		}
	});

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = value => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			// Save state
			setStoredValue(valueToStore);
			// Save to local storage
			window.localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error);
		}
	};

	return [storedValue, setValue];
}

export const truncateString = (str, num) => {
	if (str.length <= num) {
		return str;
	}

	return str.slice(0, num);
};

export const numeric = {};

// Get Array dimensions
numeric.dim = function dim(x) {
	var y, z;
	if (typeof x === "object") {
		y = x[0];
		if (typeof y === "object") {
			z = y[0];
			if (typeof z === "object") {
				return numeric._dim(x);
			}
			return [x.length, y.length];
		}
		return [x.length];
	}
	return [];
};

numeric._foreach2 = function _foreach2(x, s, k, f) {
	if (k === s.length - 1) {
		return f(x);
	}
	var i,
		n = s[k],
		ret = Array(n);
	for (i = n - 1; i >= 0; i--) {
		ret[i] = _foreach2(x[i], s, k + 1, f);
	}
	return ret;
};

// Deep Clone Vector
numeric.cloneV = function(x) {
	var _n = x.length;
	var i,
		ret = Array(_n);

	for (i = _n - 1; i !== -1; --i) {
		ret[i] = x[i];
	}
	return ret;
};

// Deep copy of Array
numeric.clone = function(x) {
	if (typeof x !== "object") return x;
	var V = numeric.cloneV;
	var s = numeric.dim(x);
	return numeric._foreach2(x, s, 0, V);
};

// Create diagonal matrix
numeric.diag = function diag(d) {
	var i,
		i1,
		j,
		n = d.length,
		A = Array(n),
		Ai;
	for (i = n - 1; i >= 0; i--) {
		Ai = Array(n);
		i1 = i + 2;
		for (j = n - 1; j >= i1; j -= 2) {
			Ai[j] = 0;
			Ai[j - 1] = 0;
		}
		if (j > i) {
			Ai[j] = 0;
		}
		Ai[i] = d[i];
		for (j = i - 1; j >= 1; j -= 2) {
			Ai[j] = 0;
			Ai[j - 1] = 0;
		}
		if (j === 0) {
			Ai[0] = 0;
		}
		A[i] = Ai;
	}
	return A;
};

// Create an Array by duplicating values
numeric.rep = function rep(s, v, k) {
	if (typeof k === "undefined") {
		k = 0;
	}
	var n = s[k],
		ret = Array(n),
		i;
	if (k === s.length - 1) {
		for (i = n - 2; i >= 0; i -= 2) {
			ret[i + 1] = v;
			ret[i] = v;
		}
		if (i === -1) {
			ret[0] = v;
		}
		return ret;
	}
	for (i = n - 1; i >= 0; i--) {
		ret[i] = numeric.rep(s, v, k + 1);
	}
	return ret;
};

// Identity matrix
numeric.identity = function(n) {
	return numeric.diag(numeric.rep([n], 1));
};

// Matrix inverse
numeric.inv = function inv(a) {
	var s = numeric.dim(a),
		abs = Math.abs,
		m = s[0],
		n = s[1];
	var A = numeric.clone(a),
		Ai,
		Aj;
	var I = numeric.identity(m),
		Ii,
		Ij;
	var i, j, k, x;
	for (j = 0; j < n; ++j) {
		var i0 = -1;
		var v0 = -1;
		for (i = j; i !== m; ++i) {
			k = abs(A[i][j]);
			if (k > v0) {
				i0 = i;
				v0 = k;
			}
		}
		Aj = A[i0];
		A[i0] = A[j];
		A[j] = Aj;
		Ij = I[i0];
		I[i0] = I[j];
		I[j] = Ij;
		x = Aj[j];
		for (k = j; k !== n; ++k) Aj[k] /= x;
		for (k = n - 1; k !== -1; --k) Ij[k] /= x;
		for (i = m - 1; i !== -1; --i) {
			if (i !== j) {
				Ai = A[i];
				Ii = I[i];
				x = Ai[j];
				for (k = j + 1; k !== n; ++k) Ai[k] -= Aj[k] * x;
				for (k = n - 1; k > 0; --k) {
					Ii[k] -= Ij[k] * x;
					--k;
					Ii[k] -= Ij[k] * x;
				}
				if (k === 0) Ii[0] -= Ij[0] * x;
			}
		}
	}
	return I;
};

// Coordinate Matrix-Matrix Product
numeric.dotMMsmall = function dotMMsmall(x, y) {
	var i, j, k, p, q, r, ret, foo, bar, woo, i0;
	p = x.length;
	q = y.length;
	r = y[0].length;
	ret = Array(p);
	for (i = p - 1; i >= 0; i--) {
		foo = Array(r);
		bar = x[i];
		for (k = r - 1; k >= 0; k--) {
			woo = bar[q - 1] * y[q - 1][k];
			for (j = q - 2; j >= 1; j -= 2) {
				i0 = j - 1;
				woo += bar[j] * y[j][k] + bar[i0] * y[i0][k];
			}
			if (j === 0) {
				woo += bar[0] * y[0][k];
			}
			foo[k] = woo;
		}
		ret[i] = foo;
	}
	return ret;
};

// Coordinate matrix-vector product
numeric.dotMV = function dotMV(x, y) {
	var p = x.length,
		i;
	var ret = Array(p),
		dotVV = numeric.dotVV;
	for (i = p - 1; i >= 0; i--) {
		ret[i] = dotVV(x[i], y);
	}
	return ret;
};

// Coordinate vector-vector product
numeric.dotVV = function dotVV(x, y) {
	var i,
		n = x.length,
		i1,
		ret = x[n - 1] * y[n - 1];
	for (i = n - 2; i >= 1; i -= 2) {
		i1 = i - 1;
		ret += x[i] * y[i] + x[i1] * y[i1];
	}
	if (i === 0) {
		ret += x[0] * y[0];
	}
	return ret;
};

// Matrix transpose
numeric.transpose = function transpose(x) {
	return x[0].map((col, i) => x.map(row => row[i]));
};
