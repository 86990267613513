import React, { useState, useLayoutEffect, useRef } from "react";
import BlockImage from "react-block-image";
import { useEventListener } from "../utils";
import "../styles/beforeafter.scss";

const BeforeAfterSlider = props => {
	const {
		before,
		after,
		className,
		defaultProgress,
		beforeClassName,
		afterClassName,
		beforeProps,
		afterProps,
		width,
		height,
		beforeImageLabel,
		afterImageLabel,
        scale,
		...rest
	} = props;

	const [isScrolling, setScrolling] = useState(false);
	const [relativeMousePosX, setRelativeMousePosX] = useState(width / 2);
	const [offsetWidth, setOffsetWidth] = useState(null);

	const ref = useRef(false);

	useLayoutEffect(() => {
		if (ref.current) {
			setOffsetWidth(ref.current.firstElementChild.offsetWidth);
		}
    }, [ref]);
    
	const setScrollingOn = () => {
		setScrolling(true);
	};

	const setScrollingOff = () => {
		setScrolling(false);
	};

	const handleMove = e => {
        if (!isScrolling || !ref.current) return;
        if (e.type === 'touchmove') {
            const touches = e.changedTouches;
            if (touches[0]) {
                let x = touches[0].pageX;
		        x -= ref.current.firstElementChild.getBoundingClientRect().left;
		        setRelativeMousePosX(x / scale);
            }
        } else {
            let x = e.pageX;
		    x -= ref.current.firstElementChild.getBoundingClientRect().left;
		    setRelativeMousePosX(x / scale);
        }
		
	};

	useEventListener("mouseleave", setScrollingOff);
	useEventListener("mousemove", handleMove);
    useEventListener("mouseup", setScrollingOff);
    useEventListener("touchmove", handleMove);
    useEventListener("touchend", setScrollingOff);

	const transform = Math.max(0, Math.min(relativeMousePosX, offsetWidth));

	return (
		<section id="slider" ref={ref} {...rest}>
			<div
				className="wrapper"
				style={{
					width,
					height
				}}
			>
				<div className="before">
					<BlockImage
						src={before}
						className="content-image"
						draggable="false"
						{...beforeProps}
					/>
				</div>
				<div className="after" style={{ width: transform + "px" }} dangerouslySetInnerHTML={{__html:after}} />
				<div
					className={isScrolling ? "scroller scrolling" : "scroller"}
					style={{
						left: transform - 25 + "px",
						boxShadow:
							"0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
					}}
					onMouseUp={setScrollingOff}
					onMouseDown={setScrollingOn}
                    onTouchStart={setScrollingOn}
				>
					<svg
						className="scroller__thumb"
						xmlns="http://www.w3.org/2000/svg"
						width="100"
						height="100"
						viewBox="0 0 100 100"
					>
						<polygon points="0 50 37 68 37 32 0 50" style={{ fill: "#fff" }} />
						<polygon
							points="100 50 64 32 64 68 100 50"
							style={{ fill: "#fff" }}
						/>
					</svg>
				</div>
				{beforeImageLabel && (
					<h3 className="beforeLabel" style={{ opacity: isScrolling ? 0 : 1 }}>
						{beforeImageLabel}
					</h3>
				)}
				{afterImageLabel && (
					<h3 className="afterLabel" style={{ opacity: isScrolling ? 0 : 1 }}>
						{afterImageLabel}
					</h3>
				)}
			</div>
		</section>
	);
};

export default BeforeAfterSlider;
