import React from "react";

const Input = props => {
	return (
		<div className="input-group">
			<div className="input-group-label">{props.label}</div>
			<div className="input-group-area">
				<input name={props.label} {...props} />
			</div>
		</div>
	);
};

export default Input;
