import * as React from "react";

const InteractionEvents = {
	MouseDown: "mousedown",
	MouseMove: "mousemove",
	MouseUp: "mouseup",
	TouchStart: "touchstart",
	TouchMove: "touchmove",
	TouchEnd: "touchend"
};

class Joystick extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            dragging: false,
            coordinates: this.props.coordinates
		};
		this._stickRef = React.createRef();
		this._baseRef = React.createRef();

		this._throttleMoveCallback = (() => {
			let lastCall = 0;
			return event => {
				const now = new Date().getTime();
				const throttleAmount = this.props.throttle || 0;
				if (now - lastCall < throttleAmount) {
					return;
				}
				lastCall = now;
				if (this.props.move) {
					return this.props.move(event);
				}
			};
		})();

		this._boundMouseUp = () => {
			this._mouseUp();
		};
		this._boundMouseMove = event => {
			this._mouseMove(event);
		};
	}

	_updatePos(coordinates) {
		window.requestAnimationFrame(() => {
			this.setState({
				coordinates
			});
		});
		this._throttleMoveCallback({
			//	angle: coordinates.angle,
			axisX: coordinates.axisX,
            axisY: coordinates.axisY,
            coords: coordinates,
			//	type: "move",
			x: coordinates.relativeX,
			y: coordinates.relativeY
			//direction: coordinates.direction
		});
	}

	_mouseDown(e) {
		if (this.props.disabled !== true) {
			this._parentRect = this._baseRef.current.getBoundingClientRect();

			this.setState({
				dragging: true
			});

			if (e.type === InteractionEvents.MouseDown) {
				window.addEventListener(InteractionEvents.MouseUp, this._boundMouseUp);
				window.addEventListener(InteractionEvents.MouseMove, this._boundMouseMove);
			} else {
				window.addEventListener(InteractionEvents.TouchEnd, this._boundMouseUp);
				window.addEventListener(InteractionEvents.TouchMove, this._boundMouseMove);
			}

			if (this.props.start) {
				this.props.start({
					type: "start",
					x: null,
					y: null,
					direction: null
				});
			}
		}
	}

	_getDirection(rad) {
		if (rad > 2.35619449 || rad < -2.35619449) {
			return "FORWARD";
		} else if (rad < 2.35619449 && rad > 0.785398163) {
			return "RIGHT";
		} else if (rad < -0.785398163) {
			return "LEFT";
		}
		return "BACKWARD";
	}
	_getWithinBounds(value) {
		const halfBaseSize = this._baseSize / 2;
		if (value > halfBaseSize) {
			return halfBaseSize;
		}
		if (value < -halfBaseSize) {
			return halfBaseSize * -1;
		}
		return value;
	}

	_mouseMove(event) {
		if (this.state.dragging) {
			let absoluteX = null;
			let absoluteY = null;
			if (event.type === InteractionEvents.MouseMove) {
				absoluteX = event.clientX;
				absoluteY = event.clientY;
			} else {
				absoluteX = event.touches[0].clientX;
				absoluteY = event.touches[0].clientY;
			}

			const relativeX = this._getWithinBounds(absoluteX - this._parentRect.left - this._baseSize / 2);
			const relativeY = this._getWithinBounds(absoluteY - this._parentRect.top - this._baseSize / 2);
			//const rad = Math.atan2(relativeX, relativeY);
			//const deg = rad * (180 / Math.PI);

			this._updatePos({
				relativeX,
				relativeY,
				//	angle: deg,
				//	direction: this._getDirection(rad),
				axisX: absoluteX - this._parentRect.left,
				axisY: absoluteY - this._parentRect.top
			});
		}
	}

	_mouseUp() {
		this.setState({
			...this.state,
			dragging: false
			//	coordinates: undefined
		});
		window.removeEventListener("mouseup", this._boundMouseUp);
		window.removeEventListener("mousemove", this._boundMouseMove);
		if (this.props.stop) {
			this.props.stop({
				type: "stop",
				x: null,
				y: null,
				direction: null
			});
		}
	}
	_getBaseStyle() {
		const baseColor = this.props.baseColor !== undefined ? this.props.baseColor : "#d3d3d3";

		const baseSizeString = `${this._baseSize}px`;
		return {
			height: baseSizeString,
			width: baseSizeString,
			background: baseColor,
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		};
	}
	_getStickStyle() {
		const stickColor = this.props.stickColor !== undefined ? this.props.stickColor : "#0a4436";
		const stickSize = `${this._baseSize / 4}px`;

		let stickStyle = {
			background: stickColor,
			cursor: "move",
			height: stickSize,
			width: stickSize,
			borderRadius: this._baseSize,
			flexShrink: 0,
			boxShadow: this.state.dragging ? "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)" : ""
		};

		if (this.state.coordinates !== undefined) {
			stickStyle = Object.assign({}, stickStyle, {
				position: "relative",
				transform: `translate3d(${this.state.coordinates.relativeX}px, ${this.state.coordinates.relativeY}px, 0)`
			});
		}
		return stickStyle;
	}
	render() {
		this._baseSize = 200;
		const baseStyle = this._getBaseStyle();
		const stickStyle = this._getStickStyle();
		return (
			<div
				className={this.props.disabled ? "joystick-base-disabled" : "joystick-base"}
				onMouseDown={this._mouseDown.bind(this)}
				onTouchStart={this._mouseDown.bind(this)}
				ref={this._baseRef}
				style={baseStyle}
			>
				<div ref={this._stickRef} className={this.props.disabled ? "joystick-disabled" : ""} style={stickStyle}></div>
			</div>
		);
	}
}

export default Joystick;
