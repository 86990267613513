
import React from "react";

export default function Skew(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
            <path data-name="layer2" fill="none" stroke="#202020" strokeWidth="4" d="M27 25.5H2.8v34h1.9m32.1 0v-34"/>
            <path data-name="layer2" fill="none" stroke="#202020" strokeDasharray="5" strokeWidth="2" d="M38.9 59.5H4.7l22.3-34h34.2l-22.3 34z" style={{transform: "translatey(1px)"}}/>
            <path data-name="layer1" fill="none" stroke="#202020" strokeWidth="4" d="M14.8 11.5h36m-7-7l7 7-7 7.1"/>
        </svg>
	);
}

