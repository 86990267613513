import React, { useState, useEffect } from "react";
import ProductChoices from "./ProductChoices";
import ContentLoader from "react-content-loader";
import { useFetch } from "@bjornagh/use-fetch";
import "../styles/products.scss";

const ProfileChoices = ({ updateTexture, currentTexture, updateProductCategory, setProductsLoaded }) => {
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (!mounted) {
			setMounted(true);
		}
	}, [mounted]);

	const { data } = useFetch({
		url: "https://www.havelockmetal.com/get-visualizer-data?",
		cachePolicy: "cache-and-network",
		cacheResponse: true,
		lazy: mounted
	});

	useEffect(() => {
		if (mounted && data) {
			setProductsLoaded(true);
		} else {
			setProductsLoaded(false);
		}
	}, [mounted, data, setProductsLoaded]);

	if (data === null) {
		return (
			<div className="center">
				<Loader />
			</div>
		);
	} else {
		const includeCategories = [43, 145];
		const { product_taxonomy, product_posts } = data;
		const { hmc_product_cat } = product_taxonomy;
		const product_categories = hmc_product_cat.filter(({ term_id }) => includeCategories.includes(term_id));
		let categoryName = "";

		const { productId, colorId, categoryId } = currentTexture;

		const ProfileChoices = product_categories.map(({ term_id, term_name }) => {
			if (term_id === categoryId) categoryName = term_name;
			return (
				<li
					onClick={() => updateProductCategory(term_id)}
					className={`category ${categoryId === term_id ? "selected" : ""}`}
					key={term_id}
					dangerouslySetInnerHTML={{ __html: term_name }}
				/>
			);
		});

		const products = product_posts.filter(item => {
			return item.post_terms.hmc_product_cat.includes(categoryId);
		});

		return (
			<>
				<h5>Profiles</h5>
				<ul>{ProfileChoices}</ul>
				<ProductChoices
					key={`product-choices-${categoryId}`}
					categoryId={categoryId}
					categoryName={categoryName}
					updateTexture={updateTexture}
					products={products}
					product_taxonomy={product_taxonomy}
					productId={productId}
					colorId={colorId}
					currentTexture={currentTexture}
				/>
			</>
		);
	}
};
export default ProfileChoices;

const Loader = () => (
	<>
		<ContentLoader height={465} width={360} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
			<rect x="10" y="0" width="160" height="18" />

			<rect x="10" y="40" width="220" height="40" />
			<circle cx="340" cy="60" r="8" />
			<rect x="10" y="85" width="240" height="40" />
			<circle cx="340" cy="105" r="8" />
			<rect x="10" y="130" width="220" height="40" />
			<circle cx="340" cy="150" r="8" />

			<rect x="10" y="200" width="160" height="18" />

			<rect x="190" y="240" width="160" height="150" />
			<rect x="190" y="400" width="160" height="28" />
			<rect x="190" y="430" width="120" height="21" />
			<rect x="190" y="451" width="60" height="21" />

			<rect x="10" y="240" width="160" height="150" />
			<rect x="10" y="400" width="160" height="28" />
			<rect x="10" y="430" width="120" height="21" />
			<rect x="10" y="451" width="60" height="21" />
		</ContentLoader>
	</>
);
