import React from "react";
import { Player, ControlBar } from "video-react";

const VideoPlayer = ({ src }) => {
	return (
		<Player playsInline autoPlay loop src={src}>
			<ControlBar disableCompletely={true} />
		</Player>
	);
};

export default VideoPlayer;
