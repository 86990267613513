import React, {useRef, useLayoutEffect} from "react";
import { debounce } from "../utils";

const TransformShape = ({ rotate, currentShape, scaleTotal, scaleShapeHeight, texture, setDims, dims, ...props }) => {
	const ppi = scaleShapeHeight / scaleTotal;
	const texturePPI = 400 / texture.size;
	const scaleTexture = ppi / texturePPI;
    const isRoofing = texture.categoryId && texture.categoryId === 76;

    const { rotateZ = 0, rotateX = 0, rotateY = 0, perspective = 512, skewX = 0, skewY = 0, center } = currentShape;
	const sampleTexure = `"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 12'%3E%3Cpath fill='%23fff' d='M0 0h18v12H0z'/%3E%3Cpath fill='%23ccc' fill-rule='evenodd' d='M0 0h1v12h1V0h1v12h1V0h1v12h1V0h1v12h1V0h1v12h1V0h1v12h1V0h1v12h1V0h1v12h1V0h1v12h1v-1H0v-1h18V9H0V8h18V7H0V6h18V5H0V4h18V3H0V2h18V1H0z'/%3E%3C/svg%3E"`;

    const container = useRef();

	useLayoutEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			if (container.current) {               
                let box = container.current.parentElement.parentElement.parentElement.getBoundingClientRect();
				if (!dims || (box.height !== dims.height || box.width !== dims.width)) {
					setDims(box);
				}
			}
		}, 300);
		if (container.current) {
			let box = container.current.parentElement.parentElement.parentElement.getBoundingClientRect();
			if (!dims || (box.height !== dims.height || box.width !== dims.width)) {
				setDims(box);
			}
		
		}
		window.addEventListener("resize", debouncedHandleResize);
		return () => window.removeEventListener("resize", debouncedHandleResize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
    },[container]);
    
    return (
		<div
			id="shape-wrapper"
			style={{
				backgroundImage: "url(" + props.image + ")"
			}}
		>
			<div
            	ref={container}
				className="shape-path"
				style={{
					perspectiveOrigin: `${center.x}px ${center.y}px`,
					clipPath: `polygon(${props.polygon})`,
					WebkitClipPath: `polygon(${props.polygon})`
				}}
			>
				<div
					className="texture-wrapper"
					style={{
						transform: `translate(-50%, -50%) perspective(${perspective}px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg) skewX(${skewX}deg) skewY(${skewY}deg)`,
						left: `${center.x}%`,
						top: `${center.y}%`
					}}
				>
					<div
						className="product-texture"
						style={{
							transform: !isRoofing ? `rotate(${rotate}deg)` : ``,
							backgroundColor: texture.textureColor,
							backgroundImage: texture.isProductSet ? `url(${texture.url})` : `url(${sampleTexure})`,
							backgroundSize: `${parseInt(texture.isProductSet ? scaleTexture * 400 : 400)}px`
						}}
					/>
				</div>
			</div>
		</div>
	);
};
export default TransformShape;
