import React from "react";

const Quadratic = function(props) {
	return (
		<g
			className="anchor"
			onMouseDown={e => {
				if (e.button === 0) props.setDraggedQuadratic(props.index, props.shapeIndex);
			}}
            onTouchStart={e => {
				props.setDraggedQuadratic(props.index, props.shapeIndex);
			}}
		>
			<line className="anchor-line" x1={props.p1x} y1={props.p1y} x2={props.x} y2={props.y} />
			<line className="anchor-line" x1={props.x} y1={props.y} x2={props.p2x} y2={props.p2y} />
			<circle className="point" cx={props.x} cy={props.y} r={6} />
			<text x={props.x - 6} y={props.y - 10} fill="white" style={{ fontFamily: "FontAwesome" }}>
				&#xf021;
			</text>
		</g>
	);
};

export default Quadratic;
