import React, { useRef, useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router-dom";
import Tour from "reactour";
import { clone, hasChanged, useEventListener, disableBody, enableBody, throttle } from "../utils";
import Button from "../components/Button";
import Canvas from "../components/Canvas";
import ArtBoard from "../components/ArtBoard";
import HelpButton from "../components/HelpButton";
import Controls from "../components/Controls";
import ProductControls from "../components/ProductControls";
import TransformControls from "../components/TransformControls";
import ScaleControls from "../components/ScaleControls";
import VideoPlayer from "../components/VideoPlayer";
import curveDemo from "../videos/curve-demo.mp4";
import moveDemo from "../videos/move-demo.mp4";
import resizeDemo from "../videos/resize-demo.mp4";
import maskDemo from "../videos/mask-demo.mp4";
import scaleDemo from "../videos/scale-demo.mp4";
import addPointDemo from "../videos/addpoint-demo.mp4";

import "../styles/draw.scss";

const Draw = props => {
	const canvas = useRef();
	const [isMoving, setMoving] = useState(false);
	const [isMovingPoint, setMovingPoint] = useState(false);
	const [isMovingQuadraticPoint, setMovingQuadraticPoint] = useState(false);
	const [isAddingPoint, setAddingPoint] = useState(false);
	const [isMasking, setMasking] = useState(false);
	const [canvasLoaded, setCanvasLoaded] = useState(false);
	const [userAddedMask, setUserAddedMask] = useState(false);
	const [userSetPerspective, setUserSetPerspective] = useState(false);
	const [shownNewPointHelp, setShownNewPointHelp] = useState(false);
	const [shownNewShapeHelp, setShownNewShapeHelp] = useState(false);
	const [shownMaskHelp, setShownMaskHelp] = useState(false);
	const [shownPerspectiveHelp, setShownPerspectiveHelp] = useState(false);
	const defaultTextureGroup = {
        url: "https://www.havelockmetal.com/uploads/2018/10/HMC_VisualizerProfilesV2-DELTA-1.svg",
		size: "36",
		textureColor: "#9fa1a4",
		productId: 147,
		colorId: 15,
		productName: "Delta",
		colorName: "Regent Grey",
		categoryId: 105,
		isProductSet: false
	};

	const { userAddedFirstPoint, userAddedFirstShape, isHelpOpen, defaultHelpState } = props;

	const throttledMouseMoveHandler = throttle(e => {
		mouseMoveHandler(e);
	}, 30);

	useEffect(() => {
		if (!isAddingPoint && (isMovingPoint || isMoving || isMovingQuadraticPoint)) {
            window.addEventListener("mousemove", throttledMouseMoveHandler);
            window.addEventListener("touchmove", throttledMouseMoveHandler);
		} else {
            window.removeEventListener("mousemove", throttledMouseMoveHandler);
            window.removeEventListener("touchmove", throttledMouseMoveHandler);
		}
		return () => {
            window.removeEventListener("mousemove", throttledMouseMoveHandler);
            window.removeEventListener("touchmove", throttledMouseMoveHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAddingPoint, isMovingPoint, isMoving, isMovingQuadraticPoint, throttledMouseMoveHandler]);

	useEffect(() => {
		if (!props.image) {
			props.goToURI("/new");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.image]);

	useEffect(() => {
		if (props.userAddedFirstPoint && !props.isHelpOpen && !shownNewPointHelp) {
			if (defaultHelpState) {
				setShownNewPointHelp(true);
				props.toggleHelp();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAddedFirstPoint, isHelpOpen]);

	useEffect(() => {
		if (userAddedMask && !props.isHelpOpen && !shownMaskHelp) {
			if (defaultHelpState) {
				setShownMaskHelp(true);
				props.toggleHelp();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAddedMask]);

	useEffect(() => {
		if (props.userAddedFirstPoint && !props.isHelpOpen && !shownNewShapeHelp) {
			if (defaultHelpState) {
				setShownNewShapeHelp(true);
				props.toggleHelp();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAddedFirstShape]);

	useEffect(() => {
		if (userSetPerspective && !props.isHelpOpen && !shownPerspectiveHelp) {
			if (defaultHelpState) {
				setShownPerspectiveHelp(true);
				props.toggleHelp();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSetPerspective]);

	const gridSize = 10;
	const gridOpacity = 75;
	//used to be controlled
	//const [gridSize, setGridSize] = useState(10);
	//const [gridOpacity, setOpacity] = useState(75);

	const [isChangingPerspective, updateChangingPerspective] = useState(false);
	const [transformStart, setTransformStart] = useState(false);
	const [activePoint, setActivePoint] = useState(0);
	const [currentCutShapeIndex, setCurrentCutShapeIndex] = useState(0);

	const setChangingPerspective = bool => {
        setUserSetPerspective(true);
        updateCurrentShapeProp('hasPersective', true);
        updateChangingPerspective(bool);
	};

	const handleCloseScaleHelp = () => {
		enableBody();
		document.querySelector("[name=unselect-tools]").style.color = "white";
		document.querySelector("[name=select-tool-pan]").style.color = "rgb(102, 102, 102)";
		document.querySelector("[name=select-tool-zoom-in]").style.color = "rgb(102, 102, 102)";
		document.querySelector("[name=select-tool-zoom-out]").style.color = "rgb(102, 102, 102)";
		document.querySelector("[name=fit-to-viewer").style.color = "rgb(102, 102, 102)";
	};

	const handleCloseDrawHelp = () => {
		const cancelBtn = document.querySelector("button.mm-popup__btn.mm-popup__btn--cancel");
		if (cancelBtn) {
			cancelBtn.click();
		}
		enableBody();
	};

	const mouseMoveHandler = (e) => {
        let clientX, clientY;
        switch (e.type) {            
            case "touchstart":
            case "touchmove":
            case "touchend":
            case "touchcancel":
                const touches = e.changedTouches;
                if (touches[0]) {
                    clientX = touches[0].clientX;
                    clientY = touches[0].clientY;
                } else {
                    return;
                }
                break;
            default:
                clientX = e.clientX;
                clientY = e.clientY;
        }
		if (!isAddingPoint && (isMovingPoint || isMoving || isMovingQuadraticPoint)) {
			const { scaleX, scaleY, translateX, translateY } = props.zoom;
			const rect = ReactDOM.findDOMNode(canvas.current)
				.querySelector("svg")
				.getBoundingClientRect();

			const { left, top } = rect;

			let x = Math.round(clientX - left - translateX);
			let y = Math.round(clientY - top - translateY);
			if (props.gridSnap) {
				x = gridSize * Math.round(x / gridSize / scaleX);
				y = gridSize * Math.round(y / gridSize / scaleY);
			} else {
				x = Math.round(x / scaleX);
				y = Math.round(y / scaleY);
			}

			if (isMoving) {
				movePoints({ x, y });
			} else if (isMovingPoint) {
				if (x < 10 || y < 10 || x > props.w - 10 || y > props.h - 10) {
					return;
				}
				setPointCoords({ x, y });
			} else if (isMovingQuadraticPoint) {
				if (x < 10 || y < 10 || x > props.w - 10 || y > props.h - 10) {
					return;
				}
				setQuadraticCoords({ x, y });
			}
		}
	};

	const mouseUpHandler = e => {
		setMovingPoint(false);
		setTransformStart(false);
		setMovingQuadraticPoint(false);
		setMoving(false);
	};

	const keyDownHandler = e => {
		if (props.isHelpOpen) return;
		if (e.target.tagName !== "BODY") return;
		if (e.stopPropagation) e.stopPropagation();
		if (e.preventDefault) e.preventDefault();
		e.cancelBubble = true;
		e.returnValue = false;

		if (e.shiftKey) {
			return setAddingPoint(true);
		}

		const isArrowKey = e.key.includes("Arrow");

		if (isArrowKey && activePoint > -1) {
			const { currentShapeIndex, currentShapes, updateCurrentShape } = props;
			e.preventDefault();
			let newPoints = currentShapes[currentShapeIndex].points.map(function(point, i) {
				if (activePoint === i) {
					const step = props.gridSnap ? gridSize : 1;
					if (e.key === "ArrowUp" || e.key === "ArrowDown") {
						point.y = e.key === "ArrowUp" ? point.y - step : point.y + step;
						if (props.gridSnap) {
							point.y = gridSize * Math.round(point.y / gridSize);
						}
					}
					if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
						point.x = e.key === "ArrowLeft" ? point.x - step : point.x + step;
						if (props.gridSnap) {
							point.y = gridSize * Math.round(point.y / gridSize);
						}
					}
				}
				return point;
			});
			currentShapes[currentShapeIndex].points = newPoints;
			updateCurrentShape(currentShapes[currentShapeIndex]);
		}

		if (e.key === "Escape") {
			setActivePoint(-1);
			setCurrentCutShapeIndex(-1);
			setMasking(false);
		}
	};

	const keyUpHandler = ({ shiftKey }) => {
		if (!shiftKey) setAddingPoint(false);
	};

    useEventListener("touchend", mouseUpHandler);
	useEventListener("mouseup", mouseUpHandler);
	useEventListener("keydown", keyDownHandler);
	useEventListener("keyup", keyUpHandler);

	const updateCurrentShapeProp = (prop, val) => {
        const { currentShapeIndex, currentShapes, updateCurrentShape } = props;
		updateCurrentShape({
            ...currentShapes[currentShapeIndex],
			[prop]: val
		});
	};

	const updateShapeTransform = ({ x, y, coords }) => {
		const { currentShapeIndex, currentShapes, updateCurrentShape } = props;

        const maxTilt = 140;
        const percentageX = x / 200;
        const percentageY = y / 200;
        const rotateX = -(percentageY * maxTilt - maxTilt / 2 + maxTilt / 2).toFixed(2);
        const rotateY = -(maxTilt / 2 - percentageX * maxTilt - maxTilt / 2).toFixed(2);

        if (currentShapes[currentShapeIndex].rotateX === rotateX && currentShapes[currentShapeIndex].rotateY === rotateY) {
            return;
        }

        updateCurrentShape({
            ...currentShapes[currentShapeIndex],
            rotateX,
            rotateY,
            rotateZ: currentShapes[currentShapeIndex].rotateZ,
            transformCoords: coords
        });

	};

	const updateProductRotate = angle => {
		const { groups, currentGroupIndex, updateTexture } = props;
		updateTexture({
			...groups[currentGroupIndex].texture,
			rotate: angle
		});
	};

	const resetTransformMatrix = () => {
		const { currentShapeIndex, currentShapes, updateCurrentShape } = props;
		updateCurrentShape({
			...currentShapes[currentShapeIndex],
			rotateX: 0,
			rotateY: 0,
            rotateZ: 0,
            skewX: 0,
            skewY: 0,
            perspective: 512
		});
	};

	const copyShape = index => {
		const { currentShapeIndex, currentShapes, updateCurrentShape, copyShape } = props;
		if (isMasking) {
			const newShape = clone(currentShapes[currentShapeIndex]);
			const newCutShape = clone(currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex]);

			newCutShape.points.forEach((p, i) => {
				newCutShape.points[i].x = p.x + 25;
				newCutShape.points[i].y = p.y + 25;
			});
			const newCutShapeIndex = newShape.cutShapes.push(newCutShape);
			setActivePoint(0);
			updateCurrentShape(newShape);
			setCurrentCutShapeIndex(newCutShapeIndex - 1);
		} else {
			copyShape(index);
		}
	};

	const setPointType = v => {
		const { currentShapeIndex, currentShapes, updateCurrentShape } = props;
		const pointsArr = isMasking ? currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex].points : currentShapes[currentShapeIndex].points;
		// not the first point
		let update = false;
		if (activePoint !== 0) {
			const oldPoint = pointsArr[activePoint];
			switch (v) {
				case "l":
					pointsArr[activePoint] = {
						x: pointsArr[activePoint].x,
						y: pointsArr[activePoint].y
					};
					break;
				case "q":
					pointsArr[activePoint] = {
						x: pointsArr[activePoint].x,
						y: pointsArr[activePoint].y,
						q: {
							x: (pointsArr[activePoint].x + pointsArr[activePoint - 1].x) / 2,
							y: (pointsArr[activePoint].y + pointsArr[activePoint - 1].y) / 2
						}
					};
					break;
				default:
					break;
			}
			update = hasChanged(pointsArr, oldPoint);
			if (isMasking) {
				currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex].points = pointsArr;
			} else {
				currentShapes[currentShapeIndex].points = pointsArr;
			}

			if (update) {
				updateCurrentShape(currentShapes[currentShapeIndex]);
			}
		}
	};

	const selectShape = (index, e) => {
        e.stopPropagation();
		const { x, y } = getMousePosition(e);
		if (isMasking) {
			setTransformStart({ x, y });
			setMoving(true);
			setCurrentCutShapeIndex(index);
		} else {
			setTransformStart({ x, y });
			setMoving(true);
			if (props.currentShapeIndex !== index) {
				props.updateCurrentShapeIndex(index);
			}
		}
	};

	const resetShape = () => {
		const { w, h, currentGroup, updateCurrentShape } = props;
		updateCurrentShape({
			points: [{ x: w / 2, y: h / 2 }],
			cutShapes: [{ points: [{ x: w / 2, y: h / 2 }] }],
			rotate: 0,
			scale: 1,
			group: currentGroup
		});
	};

	const addNewCutShape = () => {
		const { w, h, currentShapeIndex, currentShapes, updateCurrentShape } = props;
		let newIndex = 1;
		let userAddedMask = false;

		if (currentShapes[currentShapeIndex].cutShapes.length === 0) {
			currentShapes[currentShapeIndex].cutShapes[0] = {
				points: [{ x: w / 2, y: h / 2 }]
			};
			userAddedMask = true;
		} else {
			newIndex = currentShapes[currentShapeIndex].cutShapes.push({
				points: [{ x: w / 2, y: h / 2 }]
			});
		}

		if (userAddedMask) setUserAddedMask(true);
		updateCurrentShape(currentShapes[currentShapeIndex]);
		setActivePoint(0);
		setCurrentCutShapeIndex(newIndex - 1);
		setMasking(true);
	};

	const addShape = ({ selectedGroup, newGroupName }) => {
		const { w, h, groups, addGroup, addShape } = props;
		if (newGroupName !== "") {
			const groupIndex = groups.length;
			addShape({
				points: [{ x: w / 2, y: h / 2 }],
				cutShapes: [{ points: [] }],
				group: groupIndex
			});
			addGroup({
				value: groupIndex,
				label: newGroupName,
				texture: defaultTextureGroup
			});
		} else {
            addShape({
                ...props.defaultShape,
                points: [{ x: w / 2, y: h / 2 }],
                cutShapes: [{ points: [] }],
                group: selectedGroup.value
            });
		}
		setActivePoint(0);
	};

	const selectGroup = groupIndex => {
		setActivePoint(-1);
		setMasking(false);
		props.updateCurrentGroupIndex(groupIndex);
	};

	const addGroup = groupName => {
		const { groups, addGroup } = props;
		addGroup({
			value: groups.length,
			label: groupName
		});
	};

	const setCutMode = bool => {
		setActivePoint(-1);

		if (bool) {
			setMasking(true);
		} else {
			setAddingPoint(false);
			setMasking(false);
		}
	};

	const addPoint = e => {
		const { updateCurrentShape, currentShapes, currentShapeIndex, currentGroupIndex, location } = props;
		const isScaleStep = location.pathname === "/scale";

		if (isScaleStep || !isAddingPoint) {
			return;
		}
		const { scaleX, scaleY, translateX, translateY } = props.zoom;
		const rect = ReactDOM.findDOMNode(canvas.current)
			.querySelector("svg")
			.getBoundingClientRect();
		const { left, top } = rect;

		let x = Math.round((e.clientX - left - translateX) / scaleX);
		let y = Math.round((e.clientY - top - translateY) / scaleY);

		if (props.gridSnap) {
			x = gridSize * Math.round(x / gridSize);
			y = gridSize * Math.round(y / gridSize);
		}
		const coords = { x, y };

		if (x < 10 || y < 10 || x > props.w - 10 || y > props.h - 10) {
			return;
		}

		let newCurrentShape = clone(currentShapes[currentShapeIndex]);

		if (isMasking) {
			if (newCurrentShape.cutShapes[currentCutShapeIndex] && newCurrentShape.cutShapes[currentCutShapeIndex].points.length) {
				newCurrentShape.cutShapes[currentCutShapeIndex].points.splice(activePoint, 0, coords);
			} else {
				newCurrentShape.cutShapes[currentCutShapeIndex] = {
					points: [coords]
				};
			}
		} else {
			if (!newCurrentShape) {
                newCurrentShape = {
                    ...props.defaultShape,
                    group: currentGroupIndex
                };
				newCurrentShape.points[0] = coords;
			} else {
                newCurrentShape.points.push(coords);
			}
		}
		updateCurrentShape(newCurrentShape);
	};

	const removeActivePoint = e => {
		const { currentShapes, currentShapeIndex, updateCurrentShape } = props;

		if (isMasking) {
			if (currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex].points.length > 1 && activePoint !== -1) {
				currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex].points.splice(activePoint, 1);
				setActivePoint(currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex].points.length - 1);
				updateCurrentShape(currentShapes[currentShapeIndex]);
			}
		} else {
			if (currentShapes[currentShapeIndex].points.length > 1 && activePoint !== -1) {
				currentShapes[currentShapeIndex].points.splice(activePoint, 1);
				setActivePoint(currentShapes[currentShapeIndex].points.length - 1);
				updateCurrentShape(currentShapes[currentShapeIndex]);
			}
		}
	};

	const setPointCoords = coords => {
		const { currentShapeIndex, currentShapes, updateCurrentShape } = props;
		let newShape = clone(currentShapes[currentShapeIndex]);
		let oldPoint;
		if (isMasking) {
			oldPoint = currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex].points[activePoint];
			if (!hasChanged(coords, oldPoint)) {
				return;
			}
			if (oldPoint.q) {
				newShape.cutShapes[currentCutShapeIndex].points[activePoint] = {
					...coords,
					q: oldPoint.q
				};
			} else {
				newShape.cutShapes[currentCutShapeIndex].points[activePoint] = {
					...coords
				};
			}
		} else {
			oldPoint = currentShapes[currentShapeIndex].points[activePoint];
			if (!hasChanged(coords, oldPoint)) {
				return;
			}
			if (oldPoint.q) {
				newShape.points[activePoint] = { ...coords, q: oldPoint.q };
			} else {
				newShape.points[activePoint] = { ...coords };
			}
		}
		if (isScaleStep) {
			if (activePoint === 0) {
				newShape.points[1].x = newShape.points[activePoint].x;
			} else {
				newShape.points[0].x = newShape.points[activePoint].x;
			}
		}
		updateCurrentShape(newShape);
	};
	const deleteShape = () => {
		const { currentShapeIndex, currentShapes, updateCurrentShape, deleteShape } = props;

		if (isMasking) {
			let newShape = clone(currentShapes[currentShapeIndex]);
			if (!newShape.cutShapes[currentCutShapeIndex]) return;
			else if (newShape.cutShapes.length === 1) {
				newShape.cutShapes[currentCutShapeIndex].points = [];
			} else {
				newShape.cutShapes.splice(currentCutShapeIndex, 1);
			}
			setCurrentCutShapeIndex(0);
			updateCurrentShape(newShape);
		} else {
			deleteShape();
		}
	};
	const setQuadraticCoords = coords => {
		const { currentShapeIndex, currentShapes, updateCurrentShape } = props;
		let oldPoint;
		if (isMasking) {
			oldPoint = currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex].points[activePoint];
			currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex].points[activePoint].q = {
				...coords
			};
		} else {
			oldPoint = currentShapes[currentShapeIndex].points[activePoint];
			currentShapes[currentShapeIndex].points[activePoint].q = { ...coords };
		}
		//
		if (hasChanged(coords, oldPoint)) {
			updateCurrentShape(currentShapes[currentShapeIndex]);
		}
	};

	const setDraggedPoint = (pointIndex, shapeIndex) => {
		const { currentShapeIndex, updateCurrentShapeIndex } = props;
		if (currentShapeIndex !== shapeIndex) {
			updateCurrentShapeIndex(shapeIndex);
		}
		if (!isAddingPoint) {
			setMovingPoint(true);
			setActivePoint(pointIndex);
		}
	};

	const setDraggedQuadratic = (pointIndex, shapeIndex) => {
		const { currentShapeIndex, updateCurrentShapeIndex } = props;
		if (isMasking && currentCutShapeIndex !== shapeIndex) {
			setCurrentCutShapeIndex(currentCutShapeIndex);
		} else if (currentShapeIndex !== shapeIndex) {
			updateCurrentShapeIndex(shapeIndex);
		}
		if (!isAddingPoint) {
			setMovingQuadraticPoint(true);
			setActivePoint(pointIndex);
		}
	};

	const movePoints = coords => {
		const { currentShapeIndex, currentShapes, w, h } = props;
		if (currentShapeIndex === -1) return;

		const xDiff = coords.x - transformStart.x;
		const yDiff = coords.y - transformStart.y;

		if (!transformStart || !yDiff || !xDiff) return;

		let newShape = {};
		let newShapes = clone(currentShapes[currentShapeIndex]);
		let moveFailed = false;

		if (isMasking) {
			if (!currentShapes[currentShapeIndex] || !currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex]) return;

			newShape = clone(currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex]);
			let newPoints = currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex].points.map(function(point) {
				point.x = point.x + xDiff;
				point.y = point.y + yDiff;
				if (point.q) {
					point.q.y = point.q.y + yDiff;
					point.q.x = point.q.x + xDiff;
				}
				return point;
			});
			for (let point of newPoints) {
				if (point.y < 10 || point.x < 10 || point.x > w || point.y > h) {
					moveFailed = true;
				}
			}
			if (!moveFailed) {
				newShape.points = newPoints;
			}
			if (hasChanged(currentShapes[currentShapeIndex].cutShapes[currentCutShapeIndex], newShape)) {
				newShapes.cutPoints[currentCutShapeIndex] = newShape;
				props.updateCurrentShape(newShapes);
			}
			setTransformStart({ x: coords.x, y: coords.y });
		} else {
			if (!currentShapes[currentShapeIndex] || !currentShapes[currentShapeIndex].points) return;

			let newShape = clone(currentShapes[currentShapeIndex]);
			let newPoints = clone(currentShapes[currentShapeIndex].points);
			let newCutShapes = clone(currentShapes[currentShapeIndex].cutShapes);
			newPoints.forEach((point, pointIndex) => {
				point.x = point.x + xDiff;
				point.y = point.y + yDiff;
				if (point.q) {
					point.q.y = point.q.y + yDiff;
					point.q.x = point.q.x + xDiff;
				}
				newPoints[pointIndex] = point;
			});
			for (let point of newPoints) {
				if (point.y < 10 || point.x < 10 || point.x > w || point.y > h) {
					moveFailed = true;
				}
			}

			newCutShapes.forEach((shape, shapeIndex) => {
				shape.points.forEach((point, pointIndex) => {
					point.x = point.x + xDiff;
					point.y = point.y + yDiff;
					if (point.q) {
						point.q.y = point.q.y + yDiff;
						point.q.x = point.q.x + xDiff;
					}
					newCutShapes[shapeIndex].points[pointIndex] = point;
				});
			});
			if (!moveFailed) {
				newShape.points = newPoints;
				newShape.cutShapes = newCutShapes;
			}
			props.updateCurrentShape(newShape);
			//throttledUpdateCurrentShape(newShape);
			setTransformStart({ x: coords.x, y: coords.y });
		}
	};

	const getMousePosition = e => {
		const { scaleX, scaleY, translateX, translateY } = props.zoom;
   
        let clientX, clientY;

        switch (e.nativeEvent.type) {            
            case "touchstart":
            case "touchmove":
            case "touchend":
            case "touchcancel":
                const touches = e.nativeEvent.changedTouches;
                if (touches[0]) {
                    clientX = touches[0].clientX;
                    clientY = touches[0].clientY;
                } else {
                    return;
                }
                break;
            default:
                clientX = e.clientX;
                clientY = e.clientY;
        }

		const rect = ReactDOM.findDOMNode(canvas.current)
			.querySelector("svg")
			.getBoundingClientRect();
		const { left, top } = rect;

		let x = Math.round((clientX - left - translateX) / scaleX);
		let y = Math.round((clientY - top - translateY) / scaleY);

		if (props.gridSnap) {
			x = gridSize * Math.round(x / gridSize);
			y = gridSize * Math.round(y / gridSize);
		}
		return { x, y };
	};

	const currentShape = props.currentShapes[props.currentShapeIndex];
	const isScaleStep = props.location.pathname === "/scale";

	const afterShapeTutorial = () => {
		enableBody();
		/* const HelpButton = document.querySelector(".rtf.open");
		if (HelpButton) {
			HelpButton.classList = "rtf closed";
		} */
	};

	const scaleHelp = [
		/* {
      selector: "div[role=toolbar]",
      content: () => (
        <div>
          <h5>Tutorial / Canvas Controls</h5>
          <h3>Adjusting Your View</h3>
          <hr />
          <p>
            The following controls can be used to add & remove elements from the
            canvas, move the canvas around, zoom in/out and reset the canvas.
            Only one item can be active at any given time.
          </p>
        </div>
      ),
      position: "top",
      action: node => {
        node.click();
        node.focus();
      },

      stepInteraction: false
    },
    {
      selector: "[name=unselect-tools]",
      content: () => (
        <div>
          <h5>Tutorial / Canvas Controls</h5>
          <h3>Adjusting Your View</h3>
          <hr />
          <p>
            The following ‘pointer’ button will need to be active when
            attempting to draw and cut shapes. By default it is set to active.
          </p>
        </div>
      ),
      position: "top",
      action: node => {
        node.click();
        node.focus();
      },

      stepInteraction: false
    },
    {
      selector: "[name=select-tool-pan]",
      content: () => (
        <div>
          <h5>Tutorial / Canvas Controls</h5>
          <h3>Adjusting Your View</h3>
          <hr />
          <p>
            The following ‘canvas’ button will allow you to select and move the
            entire frame.
          </p>
        </div>
      ),
      position: "top",
      action: node => {
        node.previousSibling.style.color = "rgb(102, 102, 102)";
        node.style.color = "white";
        node.focus();
      },

      stepInteraction: false
    },
    {
      selector: "div[role=toolbar]",
      content: () => (
        <div>
          <h5>Tutorial / Canvas Controls</h5>
          <h3>Adjusting Your View</h3>
          <hr />
          <p>
            The following ‘zoom’ buttons will allow you to zoom in/out on a
            particular spot on the canvas. When active you can click within the
            canvas to get zoom in/out. Please note that only one of these
            buttons can be active at a time.
          </p>
        </div>
      ),
      position: "top",
      action: node => {
        node.firstElementChild.nextSibling.style.color = "rgb(102, 102, 102)";
        node.firstElementChild.nextSibling.nextElementSibling.style.color =
          "white";
        node.firstElementChild.nextSibling.nextElementSibling.nextElementSibling.style.color =
          "white";
        node.focus();
      },

      stepInteraction: false
    },
    {
      selector: "[name^=fit-to-viewer]",
      content: () => (
        <div>
          <h5>Tutorial / Canvas Controls</h5>
          <h3>Adjusting Your View</h3>
          <hr />
          <p>
            The following ‘reset’ button will reset the canvas to its original
            position.
          </p>
        </div>
      ),
      position: "top",
      action: node => {
        node.style.color = "white";
        node.previousSibling.style.color = "rgb(102, 102, 102)";
        node.previousSibling.previousSibling.style.color = "rgb(102, 102, 102)";
        node.focus();
      },

      stepInteraction: false
    }, */
		{
			selector: "#draw > div.canvas svg",
			content: () => (
				<div>
					<h5>Tutorial / Scale</h5>
					<h3>Let's Set the Scale</h3>
					<hr />
					<p>We need to know the scale of your project in order to give an accurate representation when applying our products.</p>
					<p>
						The yellow line appearing on your image will be used to determine the approximate height of a common reference point (i.e. a standard door is 6 ft 8
						inches)
					</p>
				</div>
			),
			position: "top",
			action: node => {
				document.querySelector("[name^=fit-to-viewer]").style.color = "rgb(102, 102, 102)";
				document.querySelector("[name=unselect-tools]").style.color = "white";
				node.focus();
			},
			stepInteraction: false
		},
		{
			selector: ".scale-path-group",
			content: () => (
				<div>
					<h5>Tutorial / Scale</h5>
					<h3>Reference Shape</h3>
					<hr />
					<p>Click and drag the yellow line so that it covers the reference point you are using (i.e. window, garage door, front door)</p>
					<VideoPlayer src={scaleDemo} />
				</div>
			),
			action: node => {
				if (node) {
					node.focus();
				}
			},
			stepInteraction: false
		},
		{
			selector: ".scale-path-group line",
			content: () => (
				<div>
					<h5>Tutorial / Scale</h5>
					<h3>Reference Shape</h3>
					<hr />
					<p>Click and drag each end of the line so that the top and bottom of the line match up with the top and bottom of your reference point.</p>
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		},
		{
			selector: ".fields",
			content: () => (
				<div>
					<h5>Tutorial / Scale</h5>
					<h3>Set Height</h3>
					<hr />
					<p>Once the line is in an ideal position you can use the following fields to input the height.</p>
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		},
		{
			selector: "button.green",
			content: c => (
				<div>
					<h5>Tutorial / Scale</h5>
					<h3>Finished</h3>
					<hr />
					<p>
						Click on the <b>Next</b> button to set the scale for your project.
					</p>
					<div className="center">
						<Button className="green small" label="Let's Start!" onClick={c.close} />
					</div>
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		}
	];
	const drawStepsPart1 = [
		{
			selector: ".canvas",
			content: () => (
				<div>
					<h5>Tutorial / Drawing</h5>
					<h3>Draw</h3>
					<hr />
					<p>The next step is to draw shapes outlining your extisting roof and siding.</p>
					<p>For each shape you will be able to draw a mask which allows you to isolate parts of your image where the product shouldn’t be visible, great for doors or windows.</p>
                    <p>You can also transfrom persective before browsing and can return after selecting products for more fine tuning.</p>
                    <p>Tip – Draw a shape for each section of your project which runs in one direction.</p>
                </div>
			),
			action: () => {
				let cancelBtn = document.querySelector("button.mm-popup__btn.mm-popup__btn--cancel");
				if (cancelBtn) {
					cancelBtn.click();
				}
			},
			stepInteraction: false
		},
		{
			selector: ".add-shape",
			content: () => (
				<div>
					<h5>Tutorial / Adding New Shapes</h5>
					<h3>Draw</h3>
					<hr />
					<p>
						To get started click <b>Add New Shape</b>.
					</p>
				</div>
			),
			action: node => {
				node.focus();
				setTimeout(function() {
					node.click();
				}, 600);
			},
			stepInteraction: false
		},
		{
			selector: ".Dropdown-root",
			content: () => (
				<div>
					<h5>Tutorial / Adding New Shapes</h5>
					<h3>Groups</h3>
					<hr />
					<p>Each shape must be assigned to a group (i.e Roofing / Siding / Custom).</p>
					<p>This will allow you to select different products and colours for each area of your project.</p>
				</div>
			),
			action: node => {
				if (node) {
					node.focus();
				}
			},
			stepInteraction: false
		},
		{
			selector: ".mm-popup__box__footer__right-space button",
			content: () => (
				<div>
					<h5>Tutorial / Adding New Shapes</h5>
					<h3>Adding Shapes</h3>
					<hr />
					<p>
						Once you have selected a group click <b>Add</b> or click below to create a new group.
					</p>
				</div>
			),
			action: node => {
				if (node) {
					node.focus();
				}
			},
			stepInteraction: false
		},
		{
			selector: ".group-list-item",
			content: () => (
				<div>
					<h5>Tutorial / Adding New Shapes</h5>
					<h3>Switching Groups</h3>
					<hr />
					<p>Click here to switch between groups. You can also double-click to rename a group.</p>
				</div>
			),
			action: node => {
				let cancelBtn = document.querySelector("button.mm-popup__btn.mm-popup__btn--cancel");
				if (!cancelBtn) {
					document.querySelector(".add-shape").click();
				}
				node.focus();
			},
			stepInteraction: false
		},
		{
			selector: ".group-list-item button",
			content: c => (
				<div>
					<h5>Tutorial / Adding New Shapes</h5>
					<h3>Deleting Groups</h3>
					<hr />
					<p>You can remove groups by clicking the trashcan icon. Becareful as this will also remove any shapes you have created in that group.</p>
					<div className="center">
						<Button className="green small" label="Let's Start!" onClick={c.close} />
					</div>
				</div>
			),
			action: node => {
				if (node) node.focus();
				let cancelBtn = document.querySelector("button.mm-popup__btn.mm-popup__btn--cancel");
				if (cancelBtn) {
					cancelBtn.click();
				}
			},
			stepInteraction: false
		}
	];

	const drawStepsPart2 = [
		{
			selector: ".point-group",
			content: () => (
				<div>
					<h5>Tutorial / Points</h5>
					<h3>Working with Points</h3>
					<hr />
					<p>Great! You've added your first point.</p>
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		},
		{
			selector: ".point-group",
			content: c => (
				<div>
					<h5>Tutorial / Points</h5>
					<h3>Adding a Point</h3>
					<hr />
					<p>
						Hold <kbd>Shift</kbd> + Click to add another new point to this shape.
					</p>
					<VideoPlayer src={addPointDemo} />
					<div className="center">
						<Button className="green small" label="Let's Start!" onClick={c.close} />
					</div>
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		}
	];
	const drawStepsPart3 = [
		{
			selector: ".path",
			content: () => (
				<div>
					<h5>Tutorial / Shapes</h5>
					<h3>Working with Shapes</h3>
					<hr />
					<p>Great! You've added your first shape.</p>
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		},
		{
			selector: ".point-group",
			content: () => (
				<div>
					<h5>Tutorial / Shapes</h5>
					<h3>Resizing</h3>
					<hr />
					<p>To resize any of the sides of your shape drag any of the points.</p>
					<VideoPlayer src={resizeDemo} />
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		},

		{
			selector: ".path",
			content: () => (
				<div>
					<h5>Tutorial / Shapes</h5>
					<h3>Moving</h3>
					<hr />
					<p>To move your shape click and drag the inside of your shape.</p>
					<VideoPlayer src={moveDemo} />
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		},
		{
			selector: ".curve",
			content: () => (
				<div>
					<h5>Tutorial / Shapes</h5>
					<h3>Add a Curve</h3>
					<hr />
					<p>
						To add a curved side to your shape, select a point and click <b>Curve Point</b>. This will add another point, draging this point changes the angle
						of the curve.
					</p>
					<VideoPlayer src={curveDemo} />
				</div>
			),
			action: node => {
				if (node) {
					node.focus();
				}
			},
			stepInteraction: false
		},
		{
			selector: ".mask",
			content: () => (
				<div>
					<h5>Tutorial / Shapes</h5>
					<h3>Mask</h3>
					<hr />
					<p>
						To mask or mark an area where our products shouldn't be visbile select a shape and click <b>Add Mask</b>.
					</p>
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		},
		{
			selector: ".delete-point",
			content: () => (
				<div>
					<h5>Tutorial / Shape</h5>
					<h3>Deleting Points</h3>
					<hr />
					<p>
						To remove your selected point click <b>Delete Point</b>.
					</p>
				</div>
			),
			action: node => {
				node.focus();
			}
		},
		/*
    {
      selector: "li.rtf--mb__c button",
      content: () => (
        <div>
          <h5>Tutorial / Shapes</h5>
          <h3>Settings</h3>
          <hr />
          <p>You can access some quick settings here.</p>
        </div>
      ),
      action: node => {
        node.parentElement.parentElement.classList = "rtf open";
        setTimeout(function() {
          node.focus();
        }, 1000);
      },
      stepInteraction: false
    },
    {
      selector: "button[text='Mask Mode']",
      content: () => (
        <div>
          <h5>Tutorial / Shapes</h5>
          <h3>Mask Mode</h3>
          <hr />
          <p>
            To toggle masking mode and view masking shapes you added click here.
          </p>
        </div>
      ),
      action: node => {
        if (
          node.parentElement.parentElement.parentElement.parentElement
            .classList[1] !== "open"
        ) {
          node.parentElement.parentElement.parentElement.parentElement.classList =
            "rtf open";
          setTimeout(function() {
            node.focus();
          }, 1000);
        } else {
          node.focus();
        }
      },
      stepInteraction: false
    },
    {
      selector: "button[text='Toggle Grid']",
      content: () => (
        <div>
          <h5>Tutorial / Shapes</h5>
          <h3>Grid Lines</h3>
          <hr />
          <p>To toggle the helper grid lines click here.</p>
        </div>
      ),
      action: node => {
        if (
          node.parentElement.parentElement.parentElement.parentElement
            .classList[1] !== "open"
        ) {
          node.parentElement.parentElement.parentElement.parentElement.classList =
            "rtf open";
          setTimeout(function() {
            node.focus();
          }, 1000);
        } else {
          node.focus();
        }
      },
      stepInteraction: false
    },
    {
      selector: "button[text='Snap to Grid']",
      content: () => (
        <div>
          <h5>Tutorial / Shapes</h5>
          <h3>Snap to Grid</h3>
          <hr />
          <p>
            To toggle snap to grid and allow more free transform movement click
            here.
          </p>
        </div>
      ),
      action: node => {
        if (
          node.parentElement.parentElement.parentElement.parentElement
            .classList[1] !== "open"
        ) {
          node.parentElement.parentElement.parentElement.parentElement.classList =
            "rtf open";
          setTimeout(function() {
            node.focus();
          }, 1000);
        } else {
          node.focus();
        }
      },
      stepInteraction: false
    },
    */
		{
			selector: ".path",
			content: c => (
				<div>
					<h5>Tutorial / Shapes</h5>
					<h3>Adding Points</h3>
					<hr />
					<p>
						You can keep adding point using <kbd>Shift</kbd> + Click.
					</p>
					<VideoPlayer src={addPointDemo} />
					<div className="center">
						<Button className="green small" label="Let's Start!" onClick={c.close} />
					</div>
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		}
	];



	const maskingHelp = [
		{
			selector: ".point-group",
			content: () => (
				<div>
					<h5>Tutorial / Shapes</h5>
					<h3>Masking</h3>
					<hr />
					<p>Great! You've started drawing a mask.</p>
					<p>Working with masking shapes is the same as normal working with shapes.</p>
					<VideoPlayer src={maskDemo} />
					<p>
						Use <kbd>Shift</kbd> + Click to add points.
					</p>
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		},
		{
			selector: ".green.mask",
			content: () => (
				<div>
					<h5>Tutorial / Shapes</h5>
					<h3>Masking</h3>
					<hr />
					<p>
						Click <b>Add Mask</b> to add another masking shape.
					</p>
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		},
		{
			selector: ".done-masking",
			content: c => (
				<div>
					<h5>Tutorial / Shapes</h5>
					<h3>Masking</h3>
					<hr />
					<p>
						Once your happy with your mask you can click <b>Done Masking</b> to return to your shape.
					</p>
					<div className="center">
						<Button className="green small" label="Let's Start!" onClick={c.close} />
					</div>
				</div>
			),
			action: node => {
				node.focus();
			},
			stepInteraction: false
		}
	];

	return (
		<section id="draw">
			<Switch>
				{(!isChangingPerspective || isScaleStep) && (
					<Route
						render={() => (
							<div className="canvas" style={isAddingPoint ? { cursor: "crosshair" } : {}}>
								<Fade duration={300}>
									<Canvas
										w={props.w}
										h={props.h}
										isAddingPoint={isAddingPoint}
										gridOpacity={gridOpacity}
										gridSize={gridSize}
										gridShow={props.gridShow}
										cutMode={isMasking}
										image={props.image}
										ref={canvas}
										setDraggedPoint={setDraggedPoint}
										setDraggedQuadratic={setDraggedQuadratic}
										selectShape={selectShape}
										addPoint={addPoint}
										currentShapeIndex={props.currentShapeIndex}
										currentShapes={props.currentShapes}
										scalePoints={props.scalePoints}
										currentCutShapeIndex={currentCutShapeIndex}
										activePoint={isScaleStep && activePoint > 1 ? 0 : activePoint}
										isScaleStep={isScaleStep}
										setZoom={props.setZoom}
										zoom={props.zoom}
										canvasLoaded={canvasLoaded}
										setCanvasLoaded={setCanvasLoaded}
										isFirstPoint={props.isFirstPoint}
										setMoving={setMoving}
										setCurrentCutShapeIndex={setCurrentCutShapeIndex}
										defaultHelpState={defaultHelpState}
										setDefaultHelpState={props.setDefaultHelpState}
									/>
								</Fade>
							</div>
						)}
					/>
				)}
				{isChangingPerspective && !isScaleStep && (
					<ArtBoard
						w={props.w}
						h={props.h}
						image={props.image}
						shape={currentShape}
						currentGroup={props.groups[props.currentGroupIndex]}
						scaleTotal={props.scaleTotal}
						scaleShapeHeight={props.scaleShapeHeight}
					/>
				)}
			</Switch>

			<div className="sidebar">
				<Switch>
					<Route
						path="/scale"
						render={() => (
							<>
								<ScaleControls
									isScaleStep={props.isScaleStep}
									scaleInches={props.scaleInches}
									scaleFeet={props.scaleFeet}
									updateScaleInches={props.updateScaleInches}
									updateScaleFeet={props.updateScaleFeet}
									goToURI={props.goToURI}
								/>
								{canvasLoaded && (
									<Tour
										key="scale"
										closeWithMask={true}
										steps={scaleHelp}
										disableInteraction={true}
										isOpen={props.isHelpOpen}
										onRequestClose={props.toggleHelp}
										onAfterOpen={disableBody}
										onBeforeClose={handleCloseScaleHelp}
										badgeContent={(curr, tot) => `${curr} of ${tot}`}
										accentColor="#0a4436"
										highlightedMaskClassName="tour-active"
										showNavigation={false}
										showNavigationNumber={false}
									/>
								)}
							</>
						)}
					/>
					{!isChangingPerspective && (
						<Route
							path="/draw"
							render={() => (
								<>
									<Controls
										addShape={addShape}
										deleteShape={deleteShape}
										addGroup={addGroup}
										addNewCutShape={addNewCutShape}
										removeActivePoint={removeActivePoint}
										setMasking={setMasking}
										resetShape={resetShape}
										setPointType={setPointType}
										selectGroup={selectGroup}
										isScaleStep={props.isScaleStep}
										setChangingPerspective={setChangingPerspective}
										groups={props.groups}
										updateCurrentGroupIndex={props.updateCurrentGroupIndex}
										currentShapes={props.currentShapes}
										activePoint={activePoint}
										cutMode={isMasking}
										currentGroupIndex={props.currentGroupIndex}
										currentShapeIndex={props.currentShapeIndex}
										currentCutShapeIndex={currentCutShapeIndex}
										setActivePoint={setActivePoint}
										renameGroup={props.renameGroup}
										deleteGroup={props.deleteGroup}
										shapeCount={props.shapeCount}
										copyShape={copyShape}
										isFirstPoint={props.isFirstPoint}
										goToURI={props.goToURI}
										getEmptyGroupNames={props.getEmptyGroupNames}
										deleteGroups={props.deleteGroups}
										hasMaskShape={props.hasMaskShape}
										setCutMode={setCutMode}
									/>
									{canvasLoaded && !props.userAddedFirstPoint && (
										<Tour
											key="draw"
											closeWithMask={true}
											steps={drawStepsPart1}
											disableInteraction={true}
											showNavigation={false}
											showNavigationNumber={false}
											isOpen={props.isHelpOpen}
											onRequestClose={props.toggleHelp}
											onAfterOpen={disableBody}
											onBeforeClose={handleCloseDrawHelp}
											badgeContent={(curr, tot) => `${curr} of ${tot}`}
											accentColor="#0a4436"
											highlightedMaskClassName="tour-active"
										/>
									)}
									{canvasLoaded && props.userAddedFirstPoint && !props.userAddedFirstShape && !userAddedMask && (
										<Tour
											key="addedFirstPoint"
											closeWithMask={true}
											steps={drawStepsPart2}
											disableInteraction={true}
											showNavigation={false}
											showNavigationNumber={false}
											isOpen={props.isHelpOpen}
											onRequestClose={props.toggleHelp}
											onAfterOpen={disableBody}
											onBeforeClose={enableBody}
											badgeContent={(curr, tot) => `${curr} of ${tot}`}
											accentColor="#0a4436"
											highlightedMaskClassName="tour-active"
										/>
									)}
									{canvasLoaded && props.userAddedFirstShape && !userAddedMask && (
										<Tour
											key="addedFirstShape"
											closeWithMask={true}
											steps={drawStepsPart3}
											disableInteraction={true}
											showNavigation={false}
											showNavigationNumber={false}
											isOpen={props.isHelpOpen}
											onRequestClose={props.toggleHelp}
											onAfterOpen={() => {
												setActivePoint(1);
												disableBody();
											}}
											onBeforeClose={afterShapeTutorial}
											badgeContent={(curr, tot) => `${curr} of ${tot}`}
											accentColor="#0a4436"
											highlightedMaskClassName="tour-active"
										/>
									)}
									{userAddedMask && (
										<Tour
											key="mask"
											closeWithMask={true}
											steps={maskingHelp}
											disableInteraction={true}
											showNavigation={false}
											showNavigationNumber={false}
											isOpen={props.isHelpOpen}
											onRequestClose={props.toggleHelp}
											onAfterOpen={disableBody}
											onBeforeClose={afterShapeTutorial}
											badgeContent={(curr, tot) => `${curr} of ${tot}`}
											accentColor="#0a4436"
											highlightedMaskClassName="tour-active"
										/>
									)}
								</>
							)}
						/>
					)}
					{isChangingPerspective && (
						<Route
							path="/draw"
							render={() => (
								<>
									<TransformControls
										resetTransformMatrix={resetTransformMatrix}
										setChangingPerspective={setChangingPerspective}
										currentShape={currentShape}
										updateShapeTransform={updateShapeTransform}
										updateProductRotate={updateProductRotate}
										currentGroup={props.groups[props.currentGroupIndex]}
										updateCurrentShapeProp={updateCurrentShapeProp}
                                        isHelpOpen={props.isHelpOpen}
                                        canvasLoaded={canvasLoaded}
                                        toggleHelp={props.toggleHelp}
                                        defaultHelpState={defaultHelpState}
									/>
								</>
							)}
						/>
					)}
					<Route
						path="/products"
						render={() => (
							<ProductControls
								updateTexture={props.updateTexture}
								selectGroup={selectGroup}
								selectShape={selectShape}
								groups={props.groups}
								currentGroupIndex={props.currentGroupIndex}
								renameGroup={props.renameGroup}
								deleteGroup={props.deleteGroup}
							/>
						)}
					/>
				</Switch>
			</div>
			<HelpButton
				setIsHelpOpen={props.setIsHelpOpen}
				defaultHelpState={props.defaultHelpState}
				setDefaultHelpState={props.setDefaultHelpState}
				helpOnly={true}
				isHelpOpen={props.isHelpOpen}
			/>
		</section>
	);
};

export default Draw;
