import React, { useState } from "react";
import Button from "./Button";
import SweetAlert from "sweetalert-react";

const ProjectItem = ({
  projectName,
  projectId,
  data,
  loadProject,
  copyProject,
  isCopying,
  deleteProject,
  setShareHash,
  setCopying,
  userToken,
  shareHash,
  setProjectListLoading
}) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { projectThumb } = data;

  const handleDeleteProject = () => {
    setShowConfirmDelete(true);
  };

  const handleCopyProject = () => {
    setCopying(projectId);
    copyProject.doFetch({
      body: {
        token: userToken.token,
        projectId: projectId
      }
    });
  };

  return (
    <div className="project-item" key={projectId}>
      <h3>{projectName}</h3>
      <div className="container">
        <img className="image" src={projectThumb} alt={projectName} />
        <div
          className="overlay"
          onClick={() => handleDeleteProject(projectId)}
          style={{ cursor: "pointer" }}
        >
          <div className="icon">
            <i className="fa fa-trash" />
          </div>
        </div>
      </div>
      <Button
        label="Open Project"
        className="green"
        onClick={() => {
          loadProject(projectId, data, shareHash);
        }}
      />
      <Button
        label="Copy Project"
        className="outline-green"
        onClick={handleCopyProject}
        loading={isCopying === projectId}
        style={{ float: "right" }}
      />
      <SweetAlert
        show={showConfirmDelete}
        title="Are you sure?"
        text="Deleting a saved project cannot be undone."
        showCancelButton
        type="warning"
        confirmButtonColor="#0A4438"
        onConfirm={() => {
          setShowConfirmDelete(false);
          setProjectListLoading(true);
          deleteProject.doFetch({
            body: {
              projectId: projectId,
              token: userToken.token
            }
          });
        }}
        onCancel={() => {
          setShowConfirmDelete(false);
        }}
      />
    </div>
  );
};

export default ProjectItem;
