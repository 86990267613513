import React, { useRef, useEffect } from "react";
import Collapse from "./Collapse";
import Checkmark from "../icons/Checkmark";
//import easyScroll from "easy-scroll";
import "../styles/products.scss";

if (!Element.prototype.scrollIntoViewIfNeeded) {
	Element.prototype.scrollIntoViewIfNeeded = function(centerIfNeeded) {
		//centerIfNeeded = arguments.length === 0 ? true : !!centerIfNeeded;

		var parent = this.parentNode,
			parentComputedStyle = getComputedStyle(parent, null),
			parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue("border-top-width")),
			parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue("border-left-width")),
			overTop = this.offsetTop - parent.offsetTop < parent.scrollTop,
			overBottom = this.offsetTop - parent.offsetTop + this.clientHeight - parentBorderTopWidth > parent.scrollTop + parent.clientHeight,
			overLeft = this.offsetLeft - parent.offsetLeft < parent.scrollLeft,
			overRight = this.offsetLeft - parent.offsetLeft + this.clientWidth - parentBorderLeftWidth > parent.scrollLeft + parent.clientWidt;

		if (overTop || overBottom || overLeft || overRight) {
			parent.scrollTop = this.offsetTop - parent.offsetTop;
		}
	};
}

const ColorChoices = ({ post_id, colorId, open, hmc_product_colour, post_terms, updateTextureColor }) => {
	const colorSwatch = useRef(null);
	const { hmc_product_colour: availableColors } = post_terms;
	const product_colors = hmc_product_colour.filter(color => availableColors.includes(color.term_id));

	useEffect(() => {
		Math.easeInOutQuad = function(t, b, c, d) {
			t /= d / 2;
			if (t < 1) return (c / 2) * t * t + b;
			t--;
			return (-c / 2) * (t * (t - 2) - 1) + b;
		};

		if (colorSwatch.current && open && colorId > 0) {
			const scrollingElement = colorSwatch.current.parentElement;
			let scrollRequest = null;
			const scrollToColor = () => {
				const target = scrollingElement.querySelector(".colour-container.active");
				if (!target) return;
				target.scrollIntoViewIfNeeded(false);
			};
			if (scrollRequest) clearTimeout(scrollRequest);
			scrollRequest = setTimeout(scrollToColor, 150);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colorSwatch, open, colorId]);

	const colorOptions = product_colors.map(({ term_id, term_name, term_fields }) => {
		const { colour_code } = term_fields;
		if (!colour_code) return null;
		let selectedColor = colorId === term_id;
		return (
			<div
				key={`color-${post_id}-${term_id}`}
				style={{ position: "relative" }}
				className={selectedColor ? "colour-container active" : "colour-container"}
				ref={colorSwatch}
				id={"color-" + term_id}
				onClick={
					!selectedColor
						? e => {
								e.stopPropagation();
								updateTextureColor(colour_code, term_id, term_name);
						  }
						: null
				}
			>
				<div className="swatch" style={{ backgroundColor: colour_code }}>
					{selectedColor && <Checkmark className="animated fadeIn icon icon-check" />}
					<span className="colour-name">{term_name}</span>
				</div>
			</div>
		);
	});

	return (
		<Collapse open={open} id={"color-options-" + post_id} style={{ maxHeight: 150, overflowY: "auto", overflowX: "hidden" }} transitionDuration="0.2s">
			{colorOptions}
		</Collapse>
	);
};
export default ColorChoices;
