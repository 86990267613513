import React from "react";
import ReactTooltip from "react-tooltip";

//https://material.io/icons/#ic_zoom_out

export default function IconZoomOut() {
	return (
		<>
			<ReactTooltip effect="solid" className="small-tip" id="icon-zoom-out" />
			<svg width={24} height={24} stroke="none" fill="currentColor" data-tip="Click to Zoom Out" data-for="icon-zoom-out">
				<path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z" />
			</svg>
		</>
	);
}
