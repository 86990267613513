import React from "react";

const Point = function({ active, setDraggedPoint, index, shapeIndex, x, y, zoom, isScaleStep, isAddingPoint }) {
	if (!x || !y) return null;
	let scale;
	if (!zoom) scale = 1;
	else {
		scale = zoom.scaleX;
	}
	let className = "";
	let pointRadius = 0;
	if (isScaleStep) {
		className = active ? "scale-point active" : "scale-point";
	} else {
		className = active ? "point active" : "point";
		pointRadius = active ? 10 / scale : 8 / scale;
	}
	if (isAddingPoint) {
		pointRadius = active ? 6 / scale : 4 / scale;
	}
	return (
		<g>
			{isScaleStep && (
				<line
					className={className}
					onMouseDown={e => {
						e.preventDefault();
						if (e.button === 0) setDraggedPoint(index, shapeIndex);
					}}
                    onTouchStart={e => {
						e.preventDefault();
						setDraggedPoint(index, shapeIndex);
					}}
					style={{ strokeWidth: "10px" }}
					x1={x - 10}
					y1={y}
					x2={x + 10}
					y2={y}
				/>
			)}
			{!isScaleStep && (
				<circle
					className={className}
					onMouseDown={e => {
						e.preventDefault();
						if (e.button === 0) setDraggedPoint(index, shapeIndex);
					}}
                    onTouchStart={e => {
						e.preventDefault();
						setDraggedPoint(index, shapeIndex);
					}}
					cx={x}
					cy={y}
					autoFocus={active ? true : false}
					tabIndex={active ? -1 : index}
					r={pointRadius}
					vectorEffect="non-scaling-stroke"
				/>
			)}
		</g>
	);
};

export default Point;
