import React from "react";
import { generateStaticPath } from "../utils";

const Path = ({
	isAddingPoint,
	cutMode,
	currentShapes,
	selectShape,
	currentShapeIndex,
	currentCutShapeIndex,
	isCutPath,
	setRef,
	isScaleStep,
	mask,
	setMoving
}) => {
	return (
		<>
			{currentShapes.map((s, i) => {
				if (s === null) return null;
				const points = s.points;
				let shapeId = s.id;
				if (!shapeId) {
					shapeId = "cut-" + i;
				}
				const styles = {
					path: {
						cursor: isAddingPoint ? "crosshair" : "",
						vectorEffect: "non-scaling-stroke",
						fillOpacity: 0.5,
						strokeWidth: isScaleStep ? 10 : 3
					},
					cutPath: {
						cursor: isAddingPoint ? "crosshair" : "",
						vectorEffect: "non-scaling-stroke"
					}
				};
				if (points.length === 0) return null;
				let pathClassName = isCutPath ? "cut-path" : "path";
				if (cutMode && i !== currentCutShapeIndex && isCutPath) {
					pathClassName = "inactive-cut-path";
				}
				let activeShape = isCutPath ? i === currentCutShapeIndex : i === currentShapeIndex;
				if (activeShape) pathClassName += " active";
				if (isScaleStep) pathClassName += " scale";
				return (
					<g id={shapeId} key={`shape-group-${shapeId}`} className="shape-group">
						{!cutMode && (
							<path
								key={`path-${shapeId}`}
								onTouchStart={e => {
									selectShape(i, e);
								}}
								onMouseDown={e => {
									if (e.button === 0) {
										selectShape(i, e);
									}
								}}
								onMouseUp={e => {
									if (e.button === 0) {
										setMoving(false);
									}
								}}
								className={pathClassName}
								d={generateStaticPath(points)}
								ref={setRef}
								mask={mask ? "url(#mask)" : ""}
								style={styles.path}
							/>
						)}
						{cutMode && (
							<path
								key={`cut-path-${shapeId}`}
								onMouseDown={
									isCutPath
										? e => {
												if (e.button === 0) selectShape(i, e);
										  }
										: null
								}
								onTouchStart={isCutPath ? e => selectShape(i, e) : null}
								onMouseUp={e => {
									if (e.button === 0) {
										setMoving(false);
									}
								}}
								className={`${pathClassName} cutmode`}
								d={generateStaticPath(points)}
								mask={mask ? "url(#mask)" : ""}
								style={styles.path}
							/>
						)}
					</g>
				);
			})}
		</>
	);
};

export default Path;
